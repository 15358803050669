import menuHelper from 'helpers/menu-helper';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const viewRouteNames = [
  'market-preferences-view',
  'market-preferences-2-brokerage-users',
  'market-preferences-2-brokerage-users-create',
  'market-preferences-contracts',
  'market-preferences-contracts-selection',
  'market-preferences-email-templates',
  'market-preferences-email-templates-selection',
  'market-preferences-documents',
  'market-preferences-documents-edit',
  'market-preferences-documents-create',
  'market-preferences-documents-view',
  'market-preferences-change-log',
  'market-preferences-2-brokerage-users-terminate',
  'market-preferences-2-brokerage-users-replace',
  'market-preferences-2-brokerage-users-view',
  'market-preferences-view-market-offer-defaults',
  'market-preferences-email-templates-edit',
  'market-preferences-contracts-edit',
  'market-preferences-closings'
];

const sideManuHelper = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.marketPreference);
    if (
      routeName === 'market-preferences' ||
      routeName === 'market-preferences-create'
    ) {
      return modelMenu[appPermission.list];
    }

    if (viewRouteNames.includes(routeName)) {
      return modelMenu[appPermission.view];
    }

    if (routeName === 'market-preferences-edit') {
      return modelMenu[appPermission.edit];
    }

    return [];
  }
};

export default sideManuHelper;
