import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
  List,
  ListSubheader,
  Typography,
  Skeleton
} from '@mui/material';
import { RoleAssociatedUserListEntity, RoleEntity } from 'types/role-types';
import initialRoleAssociatedUserList from 'state/role/initial-role-associated-users-list';
import { toastError, toastSuccess } from 'event/toast-event';
import rolePermissionService from 'services/role-permission-service';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

interface Props {
  role: RoleEntity;
}

const UserList: React.FC<Props> = ({ role }) => {
  const [users, setUsers] = useState<RoleAssociatedUserListEntity[]>(
    initialRoleAssociatedUserList
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getAssociatedUsers = async () => {
    if (!role.id) return;
    setIsLoading(true);

    const result = await rolePermissionService.getAssociatedUserList(role.id);

    setIsLoading(false);
    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }
    if (result.isSuccess) {
      const sortedUsers = result.data.data.sort(
        (a, b) => Number(b.has_role) - Number(a.has_role)
      );
      setErrorMessage('');
      setUsers(sortedUsers);
    }
  };

  const handleToggle = async (userId: string) => {
    const previousUsers = [...users];

    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        const newHasRole = !user.has_role;
        return { ...user, has_role: newHasRole };
      }
      return user;
    });

    const sortedUpdatedUsers = updatedUsers.sort(
      (a, b) => Number(b.has_role) - Number(a.has_role)
    );

    setUsers(sortedUpdatedUsers);

    try {
      const response = await rolePermissionService.updateUserAssociation(
        role.id,
        userId
      );

      if (response.isError) {
        toastError(response.errorMessage.message);
        throw new Error('Error while updating user role');
      }

      if (response.isSuccess) {
        const user = updatedUsers.find((u) => u.id === userId);
        const actionMsg = user?.has_role ? 'added to' : 'removed from';
        const message = `User ${user?.first_name ?? ''} ${
          user?.last_name ?? ''
        } has been ${actionMsg} the role of ${role.name} successfully.`;
        toastSuccess(message);
      }
    } catch (error) {
      setUsers(previousUsers);
      console.error('Error updating user role:', error);
    }
  };

  useEffect(() => {
    if (role.id) {
      getAssociatedUsers();
    }
  }, [role.id]);

  return (
    <>
      {role.id &&
        (!isEmpty(errorMessage) ? (
          <ErrorComponent message={errorMessage} />
        ) : (
          <>
            <List
              dense
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                wordWrap: 'break-word',
                maxHeight: '84vh',
                overflow: 'auto'
              }}
              subheader={
                <ListSubheader
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    color: 'black'
                  }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Users under {role.name}
                  </Typography>
                </ListSubheader>
              }
            >
              {isLoading
                ? Array.from(new Array(10)).map((_, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton>
                        <ListItemText
                          primary={<Skeleton variant="text" width="80%" />}
                          secondary={<Skeleton variant="text" width="60%" />}
                        />
                        <Checkbox edge="end" checked={false} disabled />
                      </ListItemButton>
                    </ListItem>
                  ))
                : users.map((user) => (
                    <ListItem key={user.id} disablePadding>
                      <ListItemButton>
                        <ListItemText
                          primary={`${user.first_name || ''} ${
                            user.last_name || ''
                          }`}
                          secondary={`${user.user_name}`}
                        />
                        <Checkbox
                          edge="end"
                          checked={user.has_role ?? false}
                          onChange={() => handleToggle(user.id)}
                          disabled={user.id === '1'}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </>
        ))}
    </>
  );
};

export default UserList;
