import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { EnterClosedPurchasedEntity } from 'types/brokerage-action-types';

const initialEnterClosedPurchased: EnterClosedPurchasedEntity = {
  id: '',
  opportunity_status_c: oppurtunityStatusList.closed_purchased,
  buyer_contract_name_c: '',
  market: {
    id: '',
    name: ''
  },
  msa_id_1: '',
  account_buyer_id: '',
  account_id_1: '',
  entera_market_id: 0,
  entera_customer_id: 0,
  buyer_contract_name_at_diligence_start_id: '',
  buyer_name_prelim_hud_id: '',
  buyer_contract_name_at_closing_id: '',
  option_days_type_c: '',
  close_date_c: '',
  purchase_price_c: 0,
  initial_commission_percentage: 0,
  initial_commission_amount: 0,
  commision_pending: 0,
  buyer_commission_c: '',
  special_instructions: '',
  cda_pay_to_2: '',
  cda_pay_to_3: '',
  cda_amount_2: '',
  cda_amount_3: '',
  reduction_type: '',
  lease_type: '',
  deposit_amount: 0,
  rental_amount: '',
  final_commission: 0,
  final_commission_percentage: 0,
  hud_commission: '',
  commision_reduction: ''
};

export default initialEnterClosedPurchased;
