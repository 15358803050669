import extensionRequest from 'assets/constants/extension-request';
import fieldLabel from 'assets/constants/fieldLabel';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitDateTimePicker from 'components/form/unit-date-time';
import UnitItem from 'components/form/unit-item';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import { brokerageActionEvent } from 'event/brokerage-action-event';
import { updateOpportunityEvent } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';
import FieldChooser from 'features/dashboards/field-chooser/field-chooser';
import emptyFunction from 'helpers/empty-function-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { getInitialExtensionRequest } from 'state/brokerage-actions/extension-request';
import { ObjectType } from 'types';
import {
  ActionRecordViewPropTypes,
  ExtensionRequestPaylodEntity,
  ExtentionRequestEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

import { fieldOptions } from './field-chooser/fields';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ExtentionRequestEntity>(
    getInitialExtensionRequest(opportunity)
  );

  const [fields, setFields] = useState<ObjectType>(fieldOptions);

  const handleChange = (e: InputChangeEvent): void => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const fieldTovalidate = ['extension_request_reason', 'close_date_c'];

    if (fields.forecasted_dd_end_date.checked) {
      fieldTovalidate.push('forecasted_dd_end_date');
    }
    if (fields.forecasted_close_date.checked) {
      fieldTovalidate.push('forecasted_close_date');
    }
    if (fields.both.checked) {
      fieldTovalidate.push('forecasted_dd_end_date');
      fieldTovalidate.push('forecasted_close_date');
    }

    const { isValid, errors } = validateBrokerageAction(data, fieldTovalidate);
    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    let reqBody: ExtensionRequestPaylodEntity;
    reqBody = {
      addendum_notes: data.addendum_notes,
      addendum_no: data.addendum_no,
      extension_request_reason: data.extension_request_reason
    };

    if (fields.forecasted_dd_end_date.checked) {
      reqBody = {
        ...reqBody,
        forecasted_dd_end_date: data.forecasted_dd_end_date
      };
    }

    if (fields.forecasted_close_date.checked) {
      reqBody = {
        ...reqBody,
        forecasted_close_date: data.forecasted_close_date
      };
    }

    if (fields.both.checked) {
      reqBody = {
        ...reqBody,
        forecasted_dd_end_date: data.forecasted_dd_end_date,
        forecasted_close_date: data.forecasted_close_date
      };
    }

    setIsLoading(true);

    const result =
      await brokerageActionService.update<ExtensionRequestPaylodEntity>(
        opportunity.id,
        reqBody,
        'extension_request'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.extension_request();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        updateOpportunityEvent({});
      }
    }
  };

  useEffect(() => {
    setData((prevData) => {
      return {
        ...prevData,
        ...getInitialExtensionRequest(opportunity)
      };
    });
  }, [loadingOpportunity]);

  const handleCheck = (e: InputChangeEvent): void => {
    setFields(() => {
      for (const field of Object.values(fields)) {
        if (field.name.includes(e.target.id)) {
          field.checked = e.target.checked;
        }
      }
      return { ...fields };
    });
  };

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2,
            pt: 3
          }}
        >
          <FormContainer>
            <UnitItem grid={{ xs: 12, sm: 12 }} p={3}>
              <FieldChooser data={fields} setFields={handleCheck} />
            </UnitItem>
          </FormContainer>

          <FormContainer id="extension_request_form">
            <UnitDateTimePicker
              label={fieldLabel.ddEndDate}
              name="due_diligence_end_c"
              value={data.due_diligence_end_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'due_diligence_end_c', value: e }
                })
              }
              error={validation['due_diligence_end_c'] ?? ''}
              disabled
            />

            <UnitDate
              label={fieldLabel.closeDate}
              name="close_date_c"
              value={data.close_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'close_date_c', value: e }
                })
              }
              error={validation['close_date_c'] ?? ''}
              disabled
            />

            <UnitDate
              label={fieldLabel.forecastedDDEndDate}
              name="forecasted_dd_end_date"
              value={data.forecasted_dd_end_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_dd_end_date', value: e }
                })
              }
              error={validation['forecasted_dd_end_date'] ?? ''}
              disabled={
                !fields.forecasted_dd_end_date?.checked && !fields.both?.checked
              }
              required
            />

            <UnitDate
              label={fieldLabel.forecastedCloseDate}
              name="forecasted_close_date"
              value={data.forecasted_close_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_close_date', value: e }
                })
              }
              error={validation['forecasted_close_date'] ?? ''}
              disabled={
                !fields.forecasted_close_date.checked && !fields.both?.checked
              }
              required
            />

            <UnitSelect
              name="extension_request_reason"
              label={fieldLabel.extensionRequestReason}
              records={getObjectEntriesAsArray(extensionRequest)}
              value={data.extension_request_reason ?? ''}
              onChange={handleChange}
              error={validation['extension_request_reason'] ?? ''}
              required
            />

            <UnitText
              name={'addendum_no'}
              label={fieldLabel.addendumNumber}
              value={data.addendum_no ?? ''}
              onChange={handleChange}
            />

            <UnitText
              name={'addendum_notes'}
              label={fieldLabel.addendumNotes}
              value={data.addendum_notes ?? ''}
              onChange={handleChange}
            />

            <UnitDate
              label={fieldLabel.originalCloseOfEscrow}
              name="original_close_of_escrow_date"
              value={data.original_close_of_escrow_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: {
                    name: 'original_close_of_escrow_date',
                    value: e
                  }
                })
              }
              error={validation['original_close_of_escrow_date'] ?? ''}
              disabled
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
