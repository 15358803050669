import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import fieldLabel from 'assets/constants/fieldLabel';
import appModel from 'assets/constants/app-models';
/** @TODO add Types */

const msaList = {
  label: fieldLabel.list,
  url: () => `/msa`,
  labelIcon: ListAltIcon,
  model: appModel.market,
  nodeId: uuid()
};

const createMSA = {
  label: fieldLabel.createMSA,
  url: () => `/msa/create`,
  labelIcon: CreateIcon,
  model: appModel.market,
  nodeId: 'create_msa'
};

const editMSA = {
  label: fieldLabel.edit,
  url: (id: string) => `/msa/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.market,
  nodeId: uuid()
};

const viewMSA = {
  label: fieldLabel.view,
  url: (id: string) => `/msa/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.market,
  nodeId: uuid()
};

const viewChangeLogMSA = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/msa/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.market,
  nodeId: uuid()
};

const accountList = {
  label: fieldLabel.list,
  url: (id: string) => `/msa/${id}/accounts`,
  labelIcon: ListAltIcon,
  model: appModel.account,
  nodeId: uuid()
};

const account = {
  label: fieldLabel.account,
  labelIcon: AddBusinessIcon,
  subMenuItems: [accountList],
  nodeId: uuid()
};

const msaMenuItems = {
  list: [msaList, createMSA],
  edit: [msaList, viewMSA, account, viewChangeLogMSA],
  create: [msaList, createMSA],
  view: [msaList, editMSA, account, viewChangeLogMSA]
};

export default msaMenuItems;
