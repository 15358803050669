import { v4 as uuid } from 'uuid';
import ListAltIcon from '@mui/icons-material/ListAlt';
import appModel from 'assets/constants/app-models';
import fieldLabel from 'assets/constants/fieldLabel';

const listKnowledgeBaseCategory = {
  label: fieldLabel.knowledgeBaseCategory,
  url: () => `/knowledge-base-categories`,
  labelIcon: ListAltIcon,
  model: appModel.knowledgeBaseCategory,
  nodeId: uuid()
};

const listKnowledgeBase = {
  label: fieldLabel.knowledgeBase,
  url: () => `/knowledge-bases`,
  labelIcon: ListAltIcon,
  model: appModel.knowledgeBase,
  nodeId: uuid()
};

const kbCategoriesMenuItems = {
  list: [listKnowledgeBase, listKnowledgeBaseCategory],
  edit: [listKnowledgeBase, listKnowledgeBaseCategory],
  create: [listKnowledgeBase, listKnowledgeBaseCategory],
  view: [listKnowledgeBase, listKnowledgeBaseCategory]
};

export default kbCategoriesMenuItems;
