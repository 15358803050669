import marketPreferenceService from 'services/market-preference-service';

const removeAssociatedMarketPreference = async (
  market_preference_id: string,
  email_template_id: string
) => {
  const response = await marketPreferenceService.deleteEmailTemplateAssociation(
    market_preference_id,
    email_template_id
  );
  return response;
};

export { removeAssociatedMarketPreference };
