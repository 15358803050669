import { brokerageTransactionRoleList } from 'assets/constants/brokerage-transaction-roles';
import ErrorComponent from 'components/errors/error-component';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import { isChecked } from 'components/form/unit-switch';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { updateOpportunityEvent } from 'event/opportunity-event';
import { isEmpty } from 'helpers/misc-helper';
import { validateOpportunityBrokerageUser } from 'helpers/validation/opportunity-brokerage-user-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import opportunityBrokerageUsersService from 'services/opportunity-brokerage-user-service';
import initialOpportunityBrokerageUser from 'state/opportunity-brokerage-user/initial-opportunity-brokerage-user';
import { ObjectType } from 'types';
import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import {
  OpportunityBrokerageUserEntity,
  OpportunityBrokerageUserNegotiatorPayloadEntity,
  OpportunityBrokerageUserPayloadEntity,
  OpportunityBrokerageUserReplaceUserPayloadEntity
} from 'types/opportunity-brokerage-user-types';

import RecordView from '../record-view';
import { toastError } from 'event/toast-event';

const OpportunityBrokerageUserCreate = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { opportunity_brokerage_user_id, opportunity_id } = useParams<{
    opportunity_brokerage_user_id: string;
    opportunity_id: string;
  }>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { oppurtunity, marketPreference } = useContext(OpportunityContext);

  const [opportunityBrokerageUser, setOpportunityBrokerageUser] =
    useState<OpportunityBrokerageUserEntity>({
      ...initialOpportunityBrokerageUser,
      market_preference: {
        id: marketPreference.id,
        name: marketPreference.name
      }
    });

  const { setRouteName } = useRouteName();

  const [validation, setValidation] = useState<ErrorValidation>({});

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setOpportunityBrokerageUser(
        Object.assign({}, opportunityBrokerageUser, {
          [e.target.name]: e.target.value
        })
      );
    } else {
      setOpportunityBrokerageUser(
        Object.assign({}, opportunityBrokerageUser, e)
      );
    }
  };

  const isPrimaryNegotiator = (): boolean => {
    return (
      opportunityBrokerageUser.brokerage_transaction_role.name ===
        brokerageTransactionRoleList.negotiator &&
      isChecked(opportunityBrokerageUser.primary_user)
    );
  };

  const loadOpportunityBrokerageUser = async (
    opportunity_brokerage_user_id: string
  ): Promise<void> => {
    const opportunityBrokerageUser = await opportunityBrokerageUsersService.get(
      opportunity_brokerage_user_id
    );

    if (opportunityBrokerageUser.isError) {
      setErrorMessage(opportunityBrokerageUser.errorMessage.message);
      return;
    }

    let response = opportunityBrokerageUser.data;

    response = {
      ...response,
      brokerage_transaction_role_name:
        opportunityBrokerageUser.data.brokerage_transaction_role.name,
      brokerage_user_name: `${opportunityBrokerageUser.data.brokerage_user.first_name} ${opportunityBrokerageUser.data.brokerage_user.last_name}`,
      opportunity_name: opportunityBrokerageUser.data.opportunity.name
    };

    setOpportunityBrokerageUser(response);
  };

  const handleSubmit = async () => {
    const { status, ...errors } = validateOpportunityBrokerageUser(
      opportunityBrokerageUser,
      routeTag
    );

    setValidation(errors);

    setIsLoading(true);

    if (!status) {
      setIsLoading(false);
      return;
    }

    let response;

    setValidation({});

    let reqBody:
      | OpportunityBrokerageUserPayloadEntity
      | OpportunityBrokerageUserReplaceUserPayloadEntity
      | OpportunityBrokerageUserNegotiatorPayloadEntity = {
      opportunity_id: opportunity_id || opportunityBrokerageUser.opportunity_id,
      brokerage_transaction_role_id:
        opportunityBrokerageUser.brokerage_transaction_role_id,
      brokerage_user_id: opportunityBrokerageUser.brokerage_user_id,
      market_preference_id:
        oppurtunity.market_preference_id ||
        opportunityBrokerageUser.market_preference_id,

      status: opportunityBrokerageUser.status
    };

    if (opportunity_brokerage_user_id) {
      if (
        opportunityBrokerageUser.brokerage_transaction_role.name ===
        'negotiator'
      ) {
        reqBody = {
          ...reqBody,
          primary_user: opportunityBrokerageUser.primary_user
        };
      }
      if (routeTag === 'opportunities-brokerage-users-edit-replace') {
        reqBody = {
          ...reqBody,
          replace_brokerage_user_id:
            opportunityBrokerageUser.replace_brokerage_user_id
        };
        response = await opportunityBrokerageUsersService.replace(
          opportunity_brokerage_user_id,
          reqBody
        );
      } else {
        response = await opportunityBrokerageUsersService.update(
          opportunity_brokerage_user_id,
          reqBody
        );
      }
    } else {
      response = await opportunityBrokerageUsersService.create(reqBody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      if (isPrimaryNegotiator() && !isEmpty(opportunity_id)) {
        updateOpportunityEvent({});
      }
      window.history.back();
    }
  };

  useEffect(() => {
    if (opportunity_brokerage_user_id !== undefined) {
      loadOpportunityBrokerageUser(opportunity_brokerage_user_id);
    }
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              opportunityBrokerageUser={opportunityBrokerageUser}
              validation={validation}
              onChange={handleChange}
              routeTag={routeTag}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default OpportunityBrokerageUserCreate;
