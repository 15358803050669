import React, { useEffect, useReducer } from 'react';
import './app.css';
import Router from './routes';
import { RouterProvider } from 'react-router-dom';
import { ColorModeContext, useMode } from './theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import authReducer from 'hooks/auth/auth-reducer';
import { AuthContext } from 'hooks/auth/auth-context';
import ListenStorageEvent from 'listeners/storage-event';
import type { AuthenticationType } from 'types/index';
import MuiSnackBar from 'components/snackbar';
import moment from 'moment-timezone';
import DateUtility from 'helpers/date-helper';
import { ErrorBoundary, Provider } from '@rollbar/react';
import envConfig from 'config/env';
import eventBus from 'helpers/event-bus-helper';
import adminConfigService from 'services/admin-config';
import userService from 'services/user-service';
import { ObjectType } from 'types';
import { isEmpty } from 'helpers/misc-helper';
import { redirectToLogin } from 'helpers/auth-helper';

declare global {
  interface Window {
    Appcues: {
      identify: () => void; // Assuming identify is a function
    };
  }
}

function App() {
  const { theme, colorMode } = useMode();

  const initialState: AuthenticationType = {
    isAuthenticated: false,
    user: null,
    token: null
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  const rollbarConfig = {
    accessToken: envConfig.REACT_APP_ROLLBAR_TOKEN,
    environment: envConfig.REACT_APP_BROKERAGE_ENV
  };

  moment.tz.setDefault(DateUtility.getCurrentTimeZone());

  const getConfigValue = (data: any, key: string) => {
    return data
      .map((x: any) => {
        if ([key].includes(x.name)) {
          return x;
        }
      })
      .filter((e: any) => e);
  };

  const getAdminConfig = async () => {
    const adminConfig: ObjectType = await adminConfigService.get();
    let loginUser = JSON.parse(localStorage.getItem('user')!);

    if (adminConfig.isSuccess) {
      const statusRestrictionOfferdate = getConfigValue(
        adminConfig?.data?.data,
        'status_restriction_offer_date'
      );

      const leaseTypeRestrictionOfferdate = getConfigValue(
        adminConfig?.data?.data,
        'lease_type_restriction_offerdate'
      );

      loginUser.user.status_restriction_offer_date =
        statusRestrictionOfferdate[0]['value'] || ''
          ? statusRestrictionOfferdate[0]['value']
          : '';

      loginUser.user.lease_type_restriction_offerdate =
        leaseTypeRestrictionOfferdate[0]['value'] || ''
          ? leaseTypeRestrictionOfferdate[0]['value']
          : '';

      if (!isEmpty(loginUser)) {
        dispatch({
          type: 'SET_USER',
          payload: {
            user: loginUser?.user
          }
        });
      }
    }
  };

  const getCurrentUser = async () => {
    const user: ObjectType = await userService.getCurrentUser();

    if (user.isSuccess) {
      (window.Appcues as any).identify(
        user.data.id, // unique, required
        {
          firstName: user.data.first_name, // current user's first name
          lastName: user.data.last_name, // current user's first name
          email: user.data.email // Current user's email
        }
      );

      dispatch({
        type: 'SET_USER',
        payload: {
          user: user.data
        }
      });

      getAdminConfig();
    }

    if (user.isError) {
      redirectToLogin();
    }
  };

  useEffect(() => {
    eventBus.on('LOAD_PERMISSION', () => {
      getCurrentUser();
    });
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthContext.Provider
                  value={{
                    state,
                    dispatch
                  }}
                >
                  <MuiSnackBar />
                  <ListenStorageEvent />
                  <RouterProvider router={Router} />
                </AuthContext.Provider>
              </LocalizationProvider>
            </Box>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
