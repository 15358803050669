import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';

export const occupancy_status_c = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: any = [];

    if (isEmpty(oppurtunity?.occupancy_status_c)) {
      errors.push(missingField('Occupancy Status'));
    }

    return errors;
  }
};
