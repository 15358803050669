import menuHelper from 'helpers/menu-helper';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.account);

    if (routeName === 'accounts') {
      return modelMenu[appPermission.list];
    }

    if (
      [
        'accounts-view',
        'accounts-msa',
        'accounts-msa-selection',
        'accounts-change-log'
      ].includes(routeName)
    ) {
      return modelMenu[appPermission.view];
    }

    if (routeName === 'accounts-edit') {
      return modelMenu[appPermission.edit];
    }

    if (routeName === 'accounts-create') {
      return modelMenu[appPermission.create];
    }

    return [];
  }
};

export default sideManuHelper;
