import partySubtype from 'assets/constants/party-subtype';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { validateParty } from 'helpers/validation/party-helper';
import useRouteName from 'pages/route-outlet-context';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import partyService from 'services/party-service';
import initialParty from 'state/party/initial-party';
import { PartyEntity } from 'types/party-types';
import RecordView from '../record-view';
import { OptionType } from 'types/option-type';
import { ErrorValidation } from 'types/error-types';
import { updateOpportunityEvent } from 'event/opportunity-event';
import { isEmpty } from 'helpers/misc-helper';
import { toastError } from 'event/toast-event';
import ErrorComponent from 'components/errors/error-component';

const CreateParties = ({ routeTag }: { routeTag: string }) => {
  const { routeName, setRouteName } = useRouteName();
  const { opportunity_id, party_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [party, setParty] = useState<PartyEntity>(initialParty);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subtypeOption, setSubtypeOption] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setParty(Object.assign({}, party, { [e.target.name]: e.target.value }));
  };

  const loadParty = async (party_id: string) => {
    const response = await partyService.get(party_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const data = {
      ...response.data,
      is_empty_subtype: isEmpty(response.data.sub_type)
    };

    setParty(data);
  };

  const handleSubmit = async () => {
    const reqBody = {
      ...party,
      opportunity_id: opportunity_id
    };

    const { status, errors } = validateParty(reqBody);

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    let result;
    if (!party_id) {
      result = await partyService.create(reqBody);
    } else {
      result = await partyService.update(party_id, reqBody);
    }

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (opportunity_id) {
        updateOpportunityEvent({});
        navigate(`/opportunities/${opportunity_id}/view`);
      } else {
        navigate(`/parties/${result.data.id}/view`);
      }
    }
  };

  useEffect(() => {
    setSubtypeOption(partySubtype[party.type] || []);
    if (
      ['parties-create', 'opportunities-parties-create'].includes(routeName)
    ) {
      setParty((prev) => ({
        ...prev,
        sub_type: ''
      }));
    }
  }, [party.type]);

  useEffect(() => {
    setParty(initialParty);
  }, [location]);

  useEffect(() => {
    if (party_id !== undefined) loadParty(party_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              party={party}
              validation={validation}
              onChange={handleChange}
              subTypeOption={subtypeOption}
              isEdit={!party_id ? false : true}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default CreateParties;
