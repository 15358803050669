import GroupIcon from '@mui/icons-material/Group';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import FlagIcon from '@mui/icons-material/Flag';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

const users = {
  label: fieldLabel.users,
  url: `/admin/users`,
  labelIcon: GroupIcon,
  model: appModel.admin,
  nodeId: uuid()
};

const roles = {
  label: fieldLabel.roles,
  url: `/admin/roles`,
  labelIcon: AccessibilityIcon,
  model: appModel.admin,
  nodeId: uuid()
};

const flag = {
  label: fieldLabel.featuredFlag,
  url: `/admin/feature_flag`,
  labelIcon: FlagIcon,
  model: appModel.admin,
  nodeId: uuid()
};

const defaultValues = {
  label: fieldLabel.defaultValues,
  url: `/admin/default_values`,
  labelIcon: HealthAndSafetyIcon,
  model: appModel.admin,
  nodeId: uuid()
};

const adminMenuItems = {
  list: [users, roles, flag, defaultValues]
};

export default adminMenuItems;
