import ListAltIcon from '@mui/icons-material/ListAlt';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import fieldLabel from 'assets/constants/fieldLabel';
import appModel from 'assets/constants/app-models';

const emailTemplates = {
  label: fieldLabel.list,
  url: () => `/email-templates`,
  labelIcon: ListAltIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const createEmailTemplate = {
  label: fieldLabel.create,
  url: () => `/email-templates/create`,
  labelIcon: CreateIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const editEmailTemplate = {
  label: fieldLabel.edit,
  url: (id: string) => `/email-templates/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const viewEmailTemplate = {
  label: fieldLabel.view,
  url: (id: string) => `/email-templates/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const marketPreferenceList = {
  label: fieldLabel.marketPreference,
  url: (id: string) => `/email-templates/${id}/market-preferences`,
  labelIcon: ManageAccountsIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const duplicateEmailTemplate = {
  label: fieldLabel.duplicate,
  url: (id: string) => `/email-templates/${id}/duplicate`,
  labelIcon: CopyAllIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const deletePdfTemplate = {
  label: fieldLabel.delete,
  url: (id: string) => `/email-templates/${id}/delete`,
  labelIcon: DeleteIcon,
  model: appModel.emailTemplate,
  nodeId: uuid()
};

const emailTemplateMenuItems = {
  list: [emailTemplates, createEmailTemplate],
  edit: [
    emailTemplates,
    viewEmailTemplate,
    duplicateEmailTemplate,
    marketPreferenceList,
    deletePdfTemplate
  ],
  create: [emailTemplates],
  view: [
    emailTemplates,
    editEmailTemplate,
    duplicateEmailTemplate,
    marketPreferenceList,
    deletePdfTemplate
  ]
};

export default emailTemplateMenuItems;
