import documentSubType from 'assets/constants/document-sub-type';
import { moduleLabels } from 'assets/list/tracker/constant';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import marketPreferenceService from 'services/market-preference-service';
import pdfTemplateService from 'services/pdf-template-service';
import trackerService from 'services/tracker-service';
import initialPdfTemplate from 'state/contracts/initial-pdf-template';
import { PdfTemplateEntity } from 'types/pdf-template-types';

import RecordView from '../record-view';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const PdfTemplateView = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { pdf_template_id, market_preference_id } = useParams<{
    pdf_template_id: string;
    market_preference_id: string;
  }>();
  const { setRouteName } = useRouteName();
  const [pdfTemplate, setPdfTemplate] =
    useState<PdfTemplateEntity>(initialPdfTemplate);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadPdfTemplate = async (pdf_template_id: string): Promise<void> => {
    let response;
    if (market_preference_id) {
      response = await marketPreferenceService.getAssociatedPDFTemplate(
        market_preference_id!,
        pdf_template_id
      );
    } else {
      response = await pdfTemplateService.get(pdf_template_id);
    }

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setPdfTemplate(response.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.PdfTemplates.label,
      item_id: pdf_template_id,
      item_summary: response.data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (pdf_template_id) loadPdfTemplate(pdf_template_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              pdfTemplate={pdfTemplate}
              readOnly={true}
              theme="bubble"
              subTypeOption={documentSubType[pdfTemplate?.category_id] || []}
            />
          </>
        ) : (
          <>
            <ErrorComponent message={errorMessage} />
          </>
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default PdfTemplateView;
