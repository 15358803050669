import { MenuItem, useMediaQuery } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import emailTemplateTypes from 'assets/constants/email-template-types';
import fieldLabel from 'assets/constants/fieldLabel';
import relatedEvent from 'assets/constants/related-event';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import DropdownMenu from 'components/dropdown-menu';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import TooltipModal from 'components/tooltip/tooltip-modal';
import { toastError, toastSuccess } from 'event/toast-event';
import DateUtility from 'helpers/date-helper';
import {
  associateEmailTemplate,
  deleteEmailTemplate,
  removeAssociatedEmailTemplate
} from 'helpers/email-template/email-template-action-helper';
import eventBus from 'helpers/event-bus-helper';
import { isEmpty } from 'helpers/misc-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EmailTemplateListEntity } from 'types/email-template-types';
import { v4 as uuid } from 'uuid';

const Action = ({
  emailTemplate
}: {
  emailTemplate: EmailTemplateListEntity;
}) => {
  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const ddMenuItems = [];

  if (!market_preference_id) {
    ddMenuItems.push(
      <>
        <MenuItem key={uuid()}>
          <RouteLinkBlack
            name={fieldLabel.deleteEmailTemplate}
            url={`#`}
            handleClick={() =>
              setConfirmModal({
                open: true,
                title: fieldLabel.deleteEmailTemplate,
                text: MessageDeleteRecordConfirmation(
                  emailTemplate.name,
                  'Email Template',
                  true
                ),

                proceed: async () => {
                  const result = await deleteEmailTemplate(emailTemplate.id);
                  if (result.isError) {
                    toastError(result.errorMessage.message);
                    return;
                  }

                  if (result.isSuccess) {
                    toastSuccess(fieldLabel.emailTemplateDeleteSuccess);
                    eventBus.dispatch(`email_template_refresh`, {});
                  }
                },
                cancel: () => {
                  setConfirmModal(initialConfirmModalState);
                }
              })
            }
          />
        </MenuItem>
        {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
      </>
    );
  }

  if (market_preference_id) {
    if (!location.pathname.includes('selection-list')) {
      ddMenuItems.push(
        <>
          <MenuItem key={uuid()}>
            <RouteLinkBlack
              name={fieldLabel.removeEmailTemplate}
              url={`/market-preferences/${market_preference_id}/email-templates`}
              handleClick={() =>
                setConfirmModal({
                  open: true,
                  title: fieldLabel.removeEmailTemplate,
                  text: MessageDeleteRecordConfirmation(
                    emailTemplate.name,
                    'Email Template'
                  ),

                  proceed: async () => {
                    const response = await removeAssociatedEmailTemplate(
                      market_preference_id,
                      emailTemplate.id
                    );

                    if (response.isError) {
                      toastError(response.errorMessage.message);
                      return;
                    }

                    eventBus.dispatch(`email_template_refresh`, {});
                  },
                  cancel: () => {
                    setConfirmModal(initialConfirmModalState);
                  }
                })
              }
            />
          </MenuItem>
          {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
        </>
      );
    }
    if (location.pathname.includes('selection-list')) {
      ddMenuItems.push(
        <MenuItem key={uuid()}>
          <RouteLinkBlack
            name={fieldLabel.addEmailTemplate}
            url="#"
            handleClick={async () => {
              const response = await associateEmailTemplate(
                market_preference_id,
                emailTemplate.id
              );

              if (response.isError) {
                toastError(response.errorMessage.message);
                return;
              }

              navigate(
                `/market-preferences/${market_preference_id}/email-templates`
              );
            }}
          />
        </MenuItem>
      );
    }
  }

  return (
    <>
      {ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}
      {!location.pathname.includes('selection-list') && (
        <RouteEditIconLink
          url={
            market_preference_id
              ? `/market-preferences/${market_preference_id}/email-templates/${emailTemplate.id}/edit`
              : `/email-templates/${emailTemplate.id}/edit`
          }
        />
      )}

      {!market_preference_id && (
        <RouteOpenNewIconLink
          url={
            market_preference_id
              ? `/market-preferences/${market_preference_id}/email-templates/${emailTemplate.id}/view`
              : `/email-templates/${emailTemplate.id}/view`
          }
        />
      )}
    </>
  );
};

export const getEmailTemplateColumns = (email_inspaction: boolean) => {
  let column: GridColDef[] = [
    {
      headerName: fieldLabel.action,
      field: 'action',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<EmailTemplateListEntity>) => {
        const mobileView = useMediaQuery('(max-width:599px)');
        const tabletView = useMediaQuery('(max-width:899px)');
        return (
          <>
            <Action emailTemplate={row} />
            <TooltipModal
              data={
                row.template_type === 'mustache'
                  ? row.mustache_body_html
                  : row.body_html
              }
              placement="right"
              height="70vh"
              width={`${mobileView ? '43vw' : tabletView ? '41vw' : '64vw'}`}
            />
          </>
        );
      },
      flex: 0.3,
      minWidth: 160
    },
    {
      headerName: fieldLabel.name,
      field: 'name',
      sortable: true,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<EmailTemplateListEntity>) => {
        return (
          <RouteLink url={`/email-templates/${row.id}/view`} name={row.name!} />
        );
      }
    },
    {
      headerName: 'Related Event',
      field: 'related_event',
      sortable: false,
      flex: 1,
      valueGetter: ({
        row
      }: GridValueGetterParams<EmailTemplateListEntity>) => {
        return getObjectKeyValue(relatedEvent, row.related_event);
      }
    },
    {
      headerName: 'Template Type',
      field: 'template_type',
      sortable: false,
      flex: 1,
      valueGetter: ({
        row
      }: GridValueGetterParams<EmailTemplateListEntity>) => {
        return getObjectKeyValue(emailTemplateTypes, row.template_type);
      }
    },
    {
      headerName: 'Subject',
      field: 'subject',
      sortable: true,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<EmailTemplateListEntity>) => {
        return (
          <>
            {row.template_type === 'mustache'
              ? row.mustache_subject
              : row.subject}
          </>
        );
      }
    }
  ];

  if (email_inspaction) {
    column.push({
      headerName: fieldLabel.inspection,
      field: '',
      sortable: false,
      flex: 1,
      valueGetter: ({
        row
      }: GridValueGetterParams<EmailTemplateListEntity>) => {
        return !isEmpty(
          row?.market_preferences_email_template[0]?.email_inspection
        )
          ? `${row?.market_preferences_email_template[0]?.email_inspection}`
          : '';
      }
    });
  }

  column = [
    ...column,
    {
      headerName: fieldLabel.dateModified,
      field: 'date_modified',
      sortable: true,
      flex: 0.5,
      valueGetter: ({ row }: GridRenderCellParams<EmailTemplateListEntity>) => {
        return DateUtility.getDateTimeString(row.date_modified);
      }
    },
    {
      headerName: fieldLabel.dateCreated,
      field: 'date_entered',
      sortable: true,
      flex: 0.5,
      valueGetter: ({ row }: GridRenderCellParams<EmailTemplateListEntity>) => {
        return DateUtility.getDateTimeString(row.date_entered);
      }
    }
  ];
  return column;
};
