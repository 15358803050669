import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';
import fieldLabel from 'assets/constants/fieldLabel';
/** @TODO add Types */

const accountBuyerNameList = {
  label: fieldLabel.list,
  url: () => `/account-buyer-names`,
  labelIcon: ListAltIcon,
  model: appModel.accountBuyerName,
  nodeId: uuid()
};

const createAccountBuyerName = {
  label: fieldLabel.create,
  url: () => `/account-buyer-names/create`,
  labelIcon: CreateIcon,
  model: appModel.accountBuyerName,
  nodeId: uuid()
};

const editAccountBuyerName = {
  label: fieldLabel.edit,
  url: (id: string) => `/account-buyer-names/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.accountBuyerName,
  nodeId: uuid()
};

const viewAccountBuyerName = {
  label: fieldLabel.view,
  url: (id: string) => `/account-buyer-names/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.accountBuyerName,
  nodeId: uuid()
};

const accountBuyerNameMenuItems = {
  list: [accountBuyerNameList, createAccountBuyerName],
  edit: [accountBuyerNameList, viewAccountBuyerName],
  create: [accountBuyerNameList, createAccountBuyerName],
  view: [accountBuyerNameList, editAccountBuyerName]
};

export default accountBuyerNameMenuItems;
