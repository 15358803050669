import PrimaryButton from 'components/button/button-primary';
import SecondaryButton from 'components/button/button-secondary';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import ModalComponent from 'components/modal';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import StackRow from 'components/stack/stack-row';
import { toastError, toastSuccess } from 'event/toast-event';
import { validateRole } from 'helpers/validation/role-helper';
import React, { useEffect, useState } from 'react';
import rolePermissionService from 'services/role-permission-service';
import initialRole from 'state/role/initial-roles';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { RoleEntity } from 'types/role-types';

const AddRoleComponent = ({
  title,
  setShowModal,
  setRoles,
  roleToEdit
}: {
  title: string;
  setShowModal: DispatchSetState<boolean>;
  setRoles: DispatchSetState<RoleEntity[]>;
  roleToEdit: RoleEntity | undefined;
}) => {
  const [role, setRole] = useState<RoleEntity>(roleToEdit ?? initialRole);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState<ErrorValidation>({});

  const handleChange = (e: ChangeEvent): void => {
    setRole(
      Object.assign({}, role, {
        [e.target.name]: e.target.value
      })
    );
  };

  const handleAddRole = async () => {
    const payload = {
      name: role.name,
      description: role.description
    };
    const { status, errors } = validateRole(payload);

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    setValidation({});

    const response = roleToEdit
      ? await rolePermissionService.update(roleToEdit.id, payload)
      : await rolePermissionService.create(payload);

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      toastError('Error while creating role');
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      const { id, name, description } = response.data;
      toastSuccess(
        `${roleToEdit ? 'Role updated' : 'New Role added'} successfully.`
      );
      setRoles((prevRoles) => {
        const updatedRoles = roleToEdit
          ? prevRoles.map((r) => (r.id === id ? { id, name, description } : r))
          : [...prevRoles, { id, name, description }];

        return updatedRoles.sort((a, b) => a.name.localeCompare(b.name));
      });
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (roleToEdit) {
      setRole(roleToEdit);
    } else {
      setRole(initialRole);
    }
  }, [roleToEdit]);

  return (
    <PaperBox>
      <PaperBoxHeader
        value={
          <ModalHeader title={title} onClose={() => setShowModal(false)} />
        }
        sx={{ border: 'none' }}
      />
      <PaperBoxContent>
        <FormContainer>
          <UnitText
            name="name"
            label="Role Name"
            value={role.name}
            onChange={handleChange}
            grid={{ xs: 12, sm: 12 }}
            error={validation['name'] ?? ''}
            required={true}
          />

          <UnitText
            name="description"
            label="Role Description"
            value={role.description}
            onChange={handleChange}
            grid={{ xs: 12, sm: 12 }}
            error={validation['description'] ?? ''}
            required={true}
          />
        </FormContainer>
      </PaperBoxContent>
      <PaperBoxFooter>
        <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
          <PrimaryButton
            variant="contained"
            onClick={handleAddRole}
            disabled={isLoading}
          >
            {roleToEdit?.id ? 'Update' : 'Add'} Role
          </PrimaryButton>
          <SecondaryButton
            variant="contained"
            onClick={() => setShowModal(false)}
            sx={{ ml: 2 }}
          >
            Cancel
          </SecondaryButton>
        </StackRow>
      </PaperBoxFooter>
    </PaperBox>
  );
};

const AddRole = ({
  title,
  showModal,
  setShowModal,
  setRoles,
  roleToEdit
}: {
  title: string;
  showModal: boolean;
  setShowModal: DispatchSetState<boolean>;
  setRoles: DispatchSetState<RoleEntity[]>;
  roleToEdit: RoleEntity | undefined;
}) => {
  return (
    <React.Fragment>
      {showModal && (
        <ModalComponent
          title={title}
          Component={AddRoleComponent}
          data={{
            title,
            setShowModal,
            setRoles,
            roleToEdit
          }}
          onClose={() => setShowModal(false)}
          loading={false}
          size="md"
          isServiceCreateBox
        />
      )}
    </React.Fragment>
  );
};

export default AddRole;
