import ContainerRight from 'components/container/right';
import Breadcrumbs from 'features/breadcrumbs';
import eventBus from 'helpers/event-bus-helper';
import menuHelper from 'helpers/menu-helper';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ObjectType } from 'types';

const Dashboard: React.FC<{ children?: JSX.Element }> = ({
  children
}: {
  children?: JSX.Element;
}) => {
  const { opportunity_id, action } = useParams<{
    opportunity_id: string;
    action: string;
  }>();

  const location = useLocation();

  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const [routeName, setRouteName] = useState('');

  useEffect(() => {
    if (location.pathname.includes('brokerage-action')) {
      if (opportunity_id !== undefined) {
        eventBus.dispatch('show_action_modal', {});
      }
    }
  }, [location]);

  const getMenus = async () => {
    const menus = await menuHelper.getItems('dashboard', 'list');

    const menusToshow = menus.map(function (item: ObjectType) {
      return { ...item, url: item.url('') };
    });

    setSideNavMenuItems(menusToshow);
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <>
      <Breadcrumbs />
      <ContainerRight p={1}>
        <Outlet context={{ routeName, setRouteName }} />
        {children ?? <></>}
      </ContainerRight>
    </>
  );
};

export default Dashboard;
