import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import ErrorComponent from 'components/errors/error-component';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import DateUtility from 'helpers/date-helper';
import eventBus from 'helpers/event-bus-helper';
import { isEmpty } from 'helpers/misc-helper';
import { serviceFields } from 'helpers/service/service-action-helper';
import userFullName from 'helpers/user-name-helper';
import { validateService } from 'helpers/validation/service-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import vendorService from 'services/vendor-service';
import initialVendorService from 'state/service/initial-vendor-service';
import { ObjectType } from 'types';
import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import {
  VendorServiceEntity,
  VendorServicePayloadEntity
} from 'types/vendor-service-types';
import { getItem } from 'helpers/local-storage-helper';
import RecordView from '../record-view';
import { updateOpportunityEvent } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';

const CreateService = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { opportunity_id, service_id } = useParams<{
    opportunity_id: string;
    service_id: string;
  }>();
  const location = useLocation();
  const [service, setService] =
    useState<VendorServiceEntity>(initialVendorService);
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [field, setField] = useState<string>('');
  const [validation, setValidtion] = useState<ErrorValidation>({});
  const { oppurtunity } = useContext(OpportunityContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { user } = JSON.parse(getItem('user') || '{}');

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setService(
        Object.assign({}, service, { [e.target.name]: e.target.value })
      );
    } else {
      setService(Object.assign({}, service, e));
    }
  };

  const loadService = async (service_id: string): Promise<void> => {
    const response = await vendorService.get(service_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const data: VendorServiceEntity = {
      ...response.data,
      requested_by_name: response.data.requested_by_user
        ? `${userFullName(response.data.requested_by_user)}`
        : ``,
      confirmation_by_name: response.data.confirmation_by_user
        ? `${userFullName(response.data.confirmation_by_user)}`
        : ``
    };
    setService(data);
  };

  const createOrUpdateService = async (
    reqBody: VendorServicePayloadEntity
  ): Promise<void> => {
    let result;

    setIsLoading(true);

    if (!service_id) {
      result = await vendorService.create(reqBody);
    } else {
      result = await vendorService.update(service_id, reqBody);
    }

    setIsLoading(false);
    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (opportunity_id) {
        updateOpportunityEvent({});
      }
      window.history.back();
    }
  };

  const handleSubmit = async (): Promise<void> => {
    service.inspection_date = service.inspection_date
      ? DateUtility.convertTimeZoneToUTC(service.inspection_date)
      : '';

    const requestBody: VendorServicePayloadEntity = {
      inspection_type: service.inspection_type,
      inspection_date: service.inspection_date,
      request_info: service.request_info,
      inspection_scheduled: service.inspection_scheduled,
      requested_at: DateUtility.convertTimeZoneToUTC(service.requested_at),
      requested_by: service.requested_by,
      confirmation_at: DateUtility.convertTimeZoneToUTC(
        service.confirmation_at
      ),
      confirmation_by: service.confirmation_by,
      closing_notes: service.closing_notes,
      inspection_notes: service.inspection_notes,
      opportunity_id_1: opportunity_id!
    };

    const { hasValidationError, ...errors } = validateService(requestBody);

    setValidtion(errors);

    if (hasValidationError) return;

    const due_diligence_end_c: string = service.opportunity.due_diligence_end_c
      ? service.opportunity.due_diligence_end_c
      : oppurtunity.due_diligence_end_c;

    const dueDiligenceEndUnix: number = DateUtility.getDateUnix(
      DateUtility.getDateString(due_diligence_end_c)
    );

    const inspectionDateUnix: number = DateUtility.getDateUnix(
      DateUtility.getDateString(service.inspection_date)
    );

    if (isEmpty(service.inspection_date)) {
      setConfirmModal({
        open: true,
        title: fieldLabel.areYouSure,
        text: fieldLabel.inspectionDateIsEmpty,
        proceed: () => {
          setConfirmModal(initialConfirmModalState);
          createOrUpdateService(requestBody);
        },
        cancel: () => {
          setConfirmModal(initialConfirmModalState);
        }
      });
    } else if (isEmpty(due_diligence_end_c)) {
      setConfirmModal({
        open: true,
        title: fieldLabel.areYouSure,
        text: fieldLabel.endDateOfDueDiligenceIsEmpty,
        proceed: () => {
          setConfirmModal(initialConfirmModalState);
          createOrUpdateService(requestBody);
        },
        cancel: () => {
          setConfirmModal(initialConfirmModalState);
        }
      });
    } else if (inspectionDateUnix > dueDiligenceEndUnix) {
      setConfirmModal({
        open: true,
        text: '',
        title: fieldLabel.inspectionDateAfterScheduledEndOfDueDiligence,
        proceed: () => {
          setConfirmModal(initialConfirmModalState);
          createOrUpdateService(requestBody);
        },
        cancel: () => {
          setConfirmModal(initialConfirmModalState);
        }
      });
    } else {
      createOrUpdateService(requestBody);
    }
  };

  const performAfterEffect = (): void => {
    const result = serviceFields[field](service);
    setService((preServcie: VendorServiceEntity) => ({
      ...preServcie,
      ...result
    }));
    setField('');
  };

  useEffect(() => {
    if (!isEmpty(field)) {
      performAfterEffect();
    }
  }, [field]);

  useEffect(() => {
    handleChange(initialVendorService);
  }, [location]);

  useEffect(() => {
    if (!isEmpty(service_id)) {
      loadService(service_id!);
    } else {
      setService((prevService: VendorServiceEntity) => ({
        ...prevService,
        requested_at: DateUtility.getTodayDateTimeString(),
        requested_by_name: userFullName(user),
        requested_by: user.id
      }));
    }
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <PaperBox>
        <PaperBoxContent>
          {isEmpty(errorMessage) ? (
            <>
              <RecordView
                service={service}
                onChange={handleChange}
                readOnly={false}
                setField={setField}
                validation={validation}
              />

              <StackRowWithDivider>
                <SaveButton onClick={handleSubmit} disabled={isLoading} />
                <CancelButton />
              </StackRowWithDivider>
            </>
          ) : (
            <ErrorComponent message={errorMessage} />
          )}
        </PaperBoxContent>
      </PaperBox>

      {confirmModal.open && (
        <ConfirmBox {...confirmModal} isLoading={isLoading} />
      )}
    </>
  );
};

export default CreateService;
