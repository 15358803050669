import { ObjectType } from 'types';
import fieldLabel from './fieldLabel';

const ActionName: ObjectType = {
  cancel_opportunity: fieldLabel.cancelOffer,
  reject_opportunity: fieldLabel.rejectOffer,
  execute_contract: fieldLabel.acceptOffer,
  opportunity_action_counter_offer: fieldLabel.counterOfferReceipt,
  buyer_counter_offer_confirmation: fieldLabel.buyerCounterOfferConfirmation,
  due_diligence_opportunity: fieldLabel.enterDueDiligence,
  enter_sale_pending: fieldLabel.enterSalePending,
  due_diligence_fees: fieldLabel.dueDiligenceFees,
  option_earnest_fee_status: fieldLabel.optionEarnestFeeStatus,
  extension_request: fieldLabel.extensionRequest,
  extension_confirmation: fieldLabel.extensionConfirmation,
  request_price_adjustment: fieldLabel.retradeRequest,
  retrade_approval: fieldLabel.retradeComplete,
  clear_due_diligence: fieldLabel.clearDueDiligence,
  termination_request: fieldLabel.terminationRequest,
  termination_approval: fieldLabel.terminationComplete,
  enter_closed_purchased: fieldLabel.enterClosedPurchased
};

export default ActionName;
