import {
  ActionRecordViewPropTypes,
  EnterSalePendingEntity,
  EnterSalePendingPayloadEntity
} from 'types/brokerage-action-types';
import {
  prepareRequestBody,
  getValidationFields,
  getInitialState
} from './helper';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { refreshOpportunity } from 'event/opportunity-event';
import { useNavigate } from 'react-router-dom';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import { validation as validationService } from 'services/validation-service';
import brokerageActionService from 'services/brokerage-action-service';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import React, { useContext, useEffect, useState } from 'react';
import SaveButton from 'components/form/button-save';
import StackRow from 'components/stack/stack-row';
import UnitHeading from 'components/form/unit-heading';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import { toastError } from 'event/toast-event';
import initialEnterSalePending from 'state/brokerage-actions/enter-sale-pending';
import UnitPercentageFormatter from 'components/form/unit-percentage-formatter';
import specialInstructions from 'assets/constants/special-instructions';
import yesNo from 'assets/constants/yes-no';
import reductionTypeOnetwo from 'assets/constants/reduction-type-one-two';
import reductionType from 'assets/reduction-type';
import { isEmpty } from 'helpers/misc-helper';
import TooltipQuestionGuide from 'components/tooltip/tooltip-question-guide';
import RequiredFieldAsterisk from 'components/form/required-field-asterisk';
import { OpportunityContext } from 'pages/opportunity/Context';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { opportunity_status_c } from 'services/validation/opportunity_status_c';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity = false
}: ActionRecordViewPropTypes) => {
  //
  const { oppurtunity, oldOppurtunity } = useContext(OpportunityContext);

  const navigate = useNavigate();

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnterSalePendingEntity>(
    initialEnterSalePending
  );

  const calculate = (field: string) => {
    return validationService[field]?.calculate(oppurtunity, oldOppurtunity);
  };

  const [field, setField] = useState<string>('');

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = () => {
    const fieldToValidate = getValidationFields();

    const { isValid, errors } = validateBrokerageAction(
      data,
      fieldToValidate,
      opportunity
    );

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody = prepareRequestBody(data);

    setIsLoading(true);

    const result =
      await brokerageActionService.update<EnterSalePendingPayloadEntity>(
        opportunity.id,
        requestBody,
        'enter_sale_pending'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.enter_sale_pending();
      } else {
        refreshOpportunity();
        navigate(`/opportunities/${opportunity.id}/view`);
      }
    }
  };

  const performAfterAffect = async () => {
    if (validationService[field]?.handleChange) {
      const result = await validationService[field]?.handleChange(data);

      setData((prevData) => ({
        ...prevData,
        ...result
      }));
    }

    setField('none');
  };

  useEffect(() => {
    if (field !== '') {
      performAfterAffect();
    }
  }, [field]);

  useEffect(() => {
    if (!loadingOpportunity) {
      setData(getInitialState(opportunity));
      setField('opportunity_status_c');
    }
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 48vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitHeading title="Contract" />

            <UnitPriceFormatter
              label={`${fieldLabel.contractPrice}`}
              name="contract_price_c"
              value={data.contract_price_c}
              onChange={handleChange}
              onBlur={() => {
                setField('contract_price_c');
              }}
            />

            <UnitPriceFormatter
              label={`${fieldLabel.purchasePrice}`}
              name="purchase_price_c"
              value={data.purchase_price_c}
              onChange={handleChange}
              onBlur={() => {
                setField('purchase_price_c');
              }}
            />

            <UnitPercentageFormatter
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.initialCommissionPercentage}
                    <TooltipQuestionGuide
                      data={fieldLabel.initialcommissionAmountPercentageFormula}
                      width="30vw"
                      height="7vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              name="initial_commission_percentage"
              value={data.initial_commission_percentage}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  initial_commission_percentage: e.target.value,
                  initial_commission_percentage_changed: true
                }));
              }}
              onBlur={() => {
                setField('initial_commission_percentage');
              }}
            />

            <UnitPriceFormatter
              name="initial_commission_amount"
              value={data.initial_commission_amount}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  initial_commission_amount: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  initial_commission_amount_changed: true
                }));
              }}
              onBlur={() => {
                setField('initial_commission_amount');
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.initialCommissionDollar}
                    <RequiredFieldAsterisk />
                    <TooltipQuestionGuide
                      data={fieldLabel.initialcommissionAmountFormula}
                      width="30vw"
                      height="7vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              error={validation['initial_commission_amount']}
            />

            <UnitPriceFormatter
              label={fieldLabel.commissionPending}
              name="commision_pending"
              value={data.commision_pending}
              onChange={handleChange}
              required
              error={validation['commision_pending']}
              onBlur={() => {
                setField('commision_pending');
              }}
            />

            <UnitText
              label={`${fieldLabel.pendingCommission} %`}
              name="buyer_commission_c"
              value={data.buyer_commission_c}
              onChange={handleChange}
              required
              error={validation['buyer_commission_c'] ?? ''}
            />

            <UnitPriceFormatter
              name="buyer_bonus_c"
              value={data?.buyer_bonus_c ?? ''}
              onChange={handleChange}
              onBlur={() => {
                setField('buyer_bonus_c');
              }}
              label={fieldLabel.buyerBonus}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="has_leasing_restrictions"
              label={fieldLabel.leasingRestrictions}
              records={getObjectEntriesAsArray(yesNo)}
              value={data.has_leasing_restrictions ?? ''}
              onChange={handleChange}
              required
              error={validation['has_leasing_restrictions']}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.restrictionsNotes}
              name="restrictions_notes"
              value={data?.restrictions_notes ?? ''}
              onChange={handleChange}
              required={data.has_leasing_restrictions === 'yes'}
              multiline={true}
              error={validation['restrictions_notes'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="special_instructions"
              label={fieldLabel.specialInstructions}
              records={getObjectEntriesAsArray(specialInstructions)}
              value={data.special_instructions ?? ''}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={fieldLabel.enteraContributionAmount}
              name="entera_contribution_amount"
              value={data.entera_contribution_amount ?? ''}
              onChange={handleChange}
              required={data.entera_contribution === 'yes'}
              error={validation['entera_contribution_amount']}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.CdaPayTo2}
              name="cda_pay_to_2"
              value={data.cda_pay_to_2}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.CdaPayTo3}
              name="cda_pay_to_3"
              value={data.cda_pay_to_3}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              name="cda_amount_2"
              value={data.cda_amount_2}
              onChange={handleChange}
              label={fieldLabel.CdaAmount2}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              name="cda_amount_3"
              value={data.cda_amount_3}
              onChange={handleChange}
              label={fieldLabel.CsaAmount3}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="reduction_type"
              label={fieldLabel.reductionType}
              records={getObjectEntriesAsArray(reductionType)}
              value={data.reduction_type ?? ''}
              onChange={(e) => {
                handleChange(e);
                setField('reduction_type');
              }}
              required={!isEmpty(data.commision_reduction)}
              error={validation['reduction_type']}
            />

            <UnitPriceFormatter
              name="commision_reduction"
              value={data.commision_reduction}
              onChange={handleChange}
              label={fieldLabel.commissionReduction}
              onBlur={() => {
                setField('commision_reduction');
              }}
            />

            <UnitSelect
              name="commission_reduction_type2"
              label={fieldLabel.reductionType2}
              records={getObjectEntriesAsArray(reductionTypeOnetwo)}
              value={data.commission_reduction_type2 ?? ''}
              onChange={(e) => {
                handleChange(e);
                setField('commission_reduction_type2');
              }}
              required={!isEmpty(data.commision_reduction2)}
              error={validation['commission_reduction_type2']}
            />

            <UnitPriceFormatter
              name="commision_reduction2"
              value={data.commision_reduction2}
              onChange={handleChange}
              label={fieldLabel.commissionReduction2}
              onBlur={() => {
                setField('commision_reduction2');
              }}
            />

            <UnitPercentageFormatter
              name="final_commission_percentage"
              value={data?.final_commission_percentage}
              readOnly
              label={`${fieldLabel.actualCommissionPercentage} %`}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  final_commission_percentage: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  final_commission_percentage_changed: true
                }));
              }}
            />

            <UnitPriceFormatter
              name="total_commission_reductions"
              value={data?.total_commission_reductions}
              onChange={handleChange}
              label={fieldLabel.totalReductions}
            />

            <UnitPriceFormatter
              name="final_commission"
              value={data?.final_commission}
              readOnly
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  final_commission: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  final_commission_changed: true
                }));
              }}
              onBlur={() => {
                setField('final_commission');
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.actualCommission} ${' '}
                    <TooltipQuestionGuide
                      data={fieldLabel.finalCommissionFormula}
                      width="30vw"
                      height="4vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              error={validation['final_commission']}
            />

            <UnitPriceFormatter
              name="hud_commission"
              value={data?.hud_commission}
              readOnly
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  hud_commission: e.target.value,
                  hud_commission_changed: true
                }));
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.hudCommission} ${' '}
                    <TooltipQuestionGuide
                      data={fieldLabel.hudCommissionFormula}
                      width="30vw"
                      height="13vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <UnitItem grid={{ xs: 12, sm: 2 }}>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
            </UnitItem>
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
