import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { EnterClosedPurchasedEntity } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export const getInitialState = (
  opportunity: OpportunityEntity
): EnterClosedPurchasedEntity => {
  return {
    id: opportunity?.id,
    opportunity_status_c: oppurtunityStatusList.closed_purchased,
    buyer_contract_name_c: opportunity?.buyer_contract_name_c,
    account_buyer_id: opportunity?.account_buyer_id,
    account_id_1: opportunity?.account_id_1,
    msa_id_1: opportunity?.msa_id_1,
    entera_market_id: opportunity?.entera_market_id,
    entera_customer_id: opportunity?.entera_customer_id,
    market: {
      id: opportunity?.market?.id,
      name: opportunity?.market?.name
    },
    buyer_contract_name_at_diligence_start_id:
      opportunity?.buyer_contract_name_at_diligence_start_id,
    buyer_name_prelim_hud_id: opportunity?.buyer_name_prelim_hud_id,
    buyer_contract_name_at_closing_id:
      opportunity?.buyer_contract_name_at_closing_id,
    option_days_type_c: opportunity?.option_days_type_c,
    close_date_c: opportunity?.close_date_c,
    purchase_price_c: opportunity?.purchase_price_c,
    initial_commission_percentage: opportunity?.initial_commission_percentage,
    initial_commission_amount: opportunity?.initial_commission_amount,
    commision_pending: opportunity?.commision_pending,
    buyer_commission_c: opportunity?.buyer_commission_c,
    special_instructions: opportunity?.special_instructions,
    cda_pay_to_2: opportunity?.cda_pay_to_2,
    cda_pay_to_3: opportunity?.cda_pay_to_3,
    cda_amount_2: opportunity?.cda_amount_2,
    cda_amount_3: opportunity?.cda_amount_3,
    reduction_type: opportunity?.reduction_type,
    lease_type: opportunity?.lease_type,
    rental_amount: opportunity?.rental_amount,
    deposit_amount: opportunity?.deposit_amount,
    final_commission: opportunity?.final_commission,
    final_commission_percentage: opportunity?.final_commission_percentage,
    hud_commission: opportunity?.hud_commission,
    commision_reduction: opportunity?.commision_reduction
  };
};

export const prepareRequestBody = (
  data: EnterClosedPurchasedEntity
): EnterClosedPurchasedEntity => {
  return {
    id: data?.id,
    opportunity_status_c: oppurtunityStatusList.closed_purchased,
    account_buyer_id: data?.account_buyer_id,
    account_id_1: data?.account_id_1,
    msa_id_1: data?.msa_id_1,
    entera_market_id: data?.entera_market_id,
    entera_customer_id: data?.entera_customer_id,
    market: {
      id: data?.market?.id,
      name: data?.market?.name
    },
    buyer_contract_name_c: data?.buyer_contract_name_c,
    buyer_contract_name_at_diligence_start_id:
      data?.buyer_contract_name_at_diligence_start_id,
    buyer_name_prelim_hud_id: data?.buyer_name_prelim_hud_id,
    buyer_contract_name_at_closing_id: data?.buyer_contract_name_at_closing_id,
    option_days_type_c: data?.option_days_type_c,
    close_date_c: data?.close_date_c,
    purchase_price_c: data?.purchase_price_c,
    initial_commission_percentage: data?.initial_commission_percentage,
    initial_commission_amount: data?.initial_commission_amount,
    commision_pending: data?.commision_pending,
    buyer_commission_c: data?.buyer_commission_c,
    special_instructions: data?.special_instructions,
    cda_pay_to_2: data?.cda_pay_to_2,
    cda_pay_to_3: data?.cda_pay_to_3,
    cda_amount_2: data?.cda_amount_2,
    cda_amount_3: data?.cda_amount_3,
    reduction_type: data?.reduction_type,
    lease_type: data?.lease_type,
    rental_amount: data?.rental_amount,
    deposit_amount: data?.deposit_amount,
    final_commission: data?.final_commission,
    final_commission_percentage: data?.final_commission_percentage,
    hud_commission: data?.hud_commission,
    commision_reduction: data?.commision_reduction
  };
};

export const getValidationFields = (): string[] => {
  return [
    'initial_commission_amount',
    'commision_pending',
    'account_buyer_id',
    'buyer_commission_c',
    'purchase_price_c',
    'reduction_type',
    'lease_type'
  ];
};
