import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.accountBuyerName);

    if (routeName === 'account-buyer-names') {
      return modelMenu[appPermission.list];
    }

    if (routeName === 'account-buyer-names-view') {
      return modelMenu[appPermission.view];
    }

    if (routeName === 'account-buyer-names-create') {
      return modelMenu[appPermission.create];
    }

    if (routeName === 'account-buyer-names-edit') {
      return modelMenu[appPermission.edit];
    }

    return [];
  }
};

export default sideManuHelper;
