import { IconButton, MenuItem } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import documentSubType from 'assets/constants/document-sub-type';
import documentType from 'assets/constants/document-type';
import fieldLabel from 'assets/constants/fieldLabel';
import state from 'assets/constants/state';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import DropdownMenu from 'components/dropdown-menu';
import UnitSwitch from 'components/form/unit-switch';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import GenerateContractConfirmationModal from 'features/pdf-template/generate-contract-confirmation';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import {
  associateMpPDFTemplate,
  removeAssociationMpPDFTemplate,
  deleteContract
} from 'helpers/pdf-template/pdf-template-action-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OptionType } from 'types/option-type';
import { PdfTemplateEntity } from 'types/pdf-template-types';
import { v4 as uuid } from 'uuid';
import { toastError, toastSuccess } from 'event/toast-event';
import documentTags from 'assets/constants/document-tags';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import contractRevisionService from 'services/contract-revision-service';
import fileDownloadHelper from 'helpers/file-download-helper';
import { ObjectType } from 'types';
import eventBus from 'helpers/event-bus-helper';

const Action = ({
  pdfTemplate
}: {
  pdfTemplate: PdfTemplateEntity;
}): JSX.Element => {
  const { market_preference_id, opportunity_id } = useParams<{
    market_preference_id: string;
    opportunity_id: string;
  }>();
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );
  const navigate = useNavigate();
  const { routeName } = useRouteName();

  const ddMenuItems: JSX.Element[] = [];

  if (market_preference_id) {
    if (routeName === 'market-preferences-contracts') {
      ddMenuItems.push(
        <>
          <MenuItem key={uuid()}>
            <RouteLinkBlack
              name={fieldLabel.removePdfTemplate}
              url={`/market-preferences/${market_preference_id}/contracts`}
              handleClick={() =>
                setConfirmModal({
                  open: true,
                  title: fieldLabel.removePdfTemplate,
                  text: MessageDeleteRecordConfirmation(
                    pdfTemplate.name,
                    'Pdf Template'
                  ),

                  proceed: async () => {
                    const response = await removeAssociationMpPDFTemplate(
                      market_preference_id!,
                      pdfTemplate.id!
                    );

                    if (response.isError) {
                      toastError(response.errorMessage.message);
                      return;
                    }

                    eventBus.dispatch(`pdf_template_refresh`, {});
                  },
                  cancel: () => {
                    setConfirmModal(initialConfirmModalState);
                  }
                })
              }
            />
          </MenuItem>
          {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
        </>
      );
    }

    if (routeName === 'market-preferences-contracts-selection') {
      ddMenuItems.push(
        <MenuItem key={uuid()}>
          <RouteLinkBlack
            name={fieldLabel.addPdfTemplate}
            url="#"
            handleClick={async () => {
              const response = await associateMpPDFTemplate(
                market_preference_id!,
                pdfTemplate.id!
              );

              if (response.isError) {
                toastError(response.errorMessage.message);
                return;
              }

              navigate(`/market-preferences/${market_preference_id}/contracts`);
            }}
          />
        </MenuItem>
      );
    }
  } else {
    ddMenuItems.push(
      <>
        <MenuItem
          key={uuid()}
          onClick={() => {
            setConfirmModal({
              open: true,
              title: fieldLabel.deleteContract,
              text: MessageDeleteRecordConfirmation(
                pdfTemplate.name,
                'Contract',
                true
              ),
              proceed: async () => {
                const result = await deleteContract(pdfTemplate.id!);
                setConfirmModal(initialConfirmModalState);
                if (result.isError) {
                  toastError(result.errorMessage.message);
                  return;
                }

                if (result.isSuccess) {
                  toastSuccess(fieldLabel.contractDeleteSuccess);
                }
              },
              cancel: () => {
                setConfirmModal(initialConfirmModalState);
              }
            });
          }}
        >
          <RouteLinkBlack name={fieldLabel.deleteContract} url={`#`} />
        </MenuItem>
        {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
      </>
    );
  }

  if (opportunity_id) {
    const { oppurtunity } = useContext(OpportunityContext);
    return (
      <GenerateContractConfirmationModal
        opportunity={oppurtunity}
        pdfTemplate={pdfTemplate}
      />
    );
  }
  return (
    <>
      {ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}

      {routeName !== 'market-preferences-contracts-selection' && (
        <RouteEditIconLink
          url={
            market_preference_id
              ? `/market-preferences/${market_preference_id}/contracts/${pdfTemplate.id}/edit`
              : `/pdf-templates/${pdfTemplate.id}/edit`
          }
        />
      )}
      {!market_preference_id && (
        <RouteOpenNewIconLink
          url={
            market_preference_id
              ? `/market-preferences/${market_preference_id}/contracts/${pdfTemplate.id}/view`
              : `/pdf-templates/${pdfTemplate.id}/view`
          }
        />
      )}
    </>
  );
};

const pdfTemplateColumn: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { opportunity_id } = useParams<ObjectType>();
      const revision = row?.contract_revision;

      const handleDownloadClick = async () => {
        const result = await contractRevisionService.getPresignUrl(
          revision?.id
        );

        if (result.isError) {
          toastError(result.errorMessage.message);
          return;
        }

        if (result.isSuccess) {
          fileDownloadHelper(result.data);
        }
      };

      return (
        <>
          <Action pdfTemplate={row} />
          {isEmpty(opportunity_id) && !isEmpty(revision) && (
            <>
              <RouteLink
                url={`/pdf-templates/${row.id}/contract_revisions/${revision?.id}/viewer`}
                name={
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                }
                target={true}
              />
              <IconButton onClick={handleDownloadClick}>
                <FileDownloadIcon />
              </IconButton>
            </>
          )}
        </>
      );
    },
    flex: 0.5,
    minWidth: 210
  },
  {
    headerName: fieldLabel.templateName,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <RouteLink url={`/pdf-templates/${row.id}/view`} name={row.name} />
      );
    },
    minWidth: 520
  },
  {
    headerName: fieldLabel.state,
    field: 'state_c',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return getObjectKeyValue(state, row.state_c);
    }
  },
  {
    headerName: fieldLabel.documentType,
    field: 'category_id',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return getObjectKeyValue(documentType, row.category_id);
    }
  },
  {
    headerName: fieldLabel.documentSubType,
    field: 'document_subtype',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      const rowDocumentType =
        !isEmpty(documentSubType[row.category_id]) &&
        documentSubType[row.category_id]
          .map((x: OptionType) =>
            x.value === row.document_subtype ? x.label : ''
          )
          .filter(function (el: string) {
            return el != '';
          });
      if (rowDocumentType.length > 0) {
        return rowDocumentType;
      } else {
        return row.document_subtype;
      }
    }
  },
  {
    headerName: fieldLabel.documentTag,
    field: 'document_tag',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return getObjectKeyValue(documentTags, row.document_tag);
    }
  },
  {
    headerName: fieldLabel.expire,
    field: 'expire_version',
    sortable: false,
    flex: 1,
    renderCell: (param: GridRenderCellParams) => (
      <UnitSwitch
        value={param.row.expire_version ?? ''}
        onChange={() => {
          return '';
        }}
        name="expire_version"
        label=""
      />
    )
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default pdfTemplateColumn;
