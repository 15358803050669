import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import emailTemplateService from 'services/email-template-service';
import marketPreferenceService from 'services/market-preference-service';
import initialEmailTemplate from 'state/email-template/initial-email-template';
import { EmailTemplateEntity } from 'types/email-template-types';
import RecordView from '../record-view';
import { moduleLabels } from 'assets/list/tracker/constant';
import trackerService from 'services/tracker-service';
import { ObjectType } from 'types';
import CircularLoader from 'components/dog-loader/dog-lodar';
import { GetResponse } from 'types/api-response-types';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';

const EmailTemplateView = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { email_template_id, market_preference_id } = useParams<{
    email_template_id: string;
    market_preference_id: string;
  }>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [emailTemplate, setEmailTemplate] =
    useState<EmailTemplateEntity>(initialEmailTemplate);

  const { setRouteName } = useRouteName();
  const [mustache, setMustache] = useState<ObjectType>({
    html: ''
  });

  const loadEmailTemplate = async (
    email_template_id: string
  ): Promise<void> => {
    setIsLoading(true);
    let eMailTemplate: GetResponse<EmailTemplateEntity>;
    if (market_preference_id) {
      eMailTemplate = await marketPreferenceService.getAssociatedEmailTemplate(
        market_preference_id,
        email_template_id
      );
    } else {
      eMailTemplate = await emailTemplateService.get(email_template_id);
    }
    setIsLoading(false);

    if (eMailTemplate.isError) {
      setErrorMessage(eMailTemplate.errorMessage.message);
      return;
    }

    setEmailTemplate(eMailTemplate.data);

    setMustache((prevMustache) => ({
      ...prevMustache,
      html: eMailTemplate.data.mustache_body_html
    }));

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.EmailTemplates.label,
      item_id: email_template_id,
      item_summary: eMailTemplate.data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (email_template_id) loadEmailTemplate(email_template_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  if (isLoading) return <CircularLoader />;

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView
            emailTemplate={emailTemplate}
            readOnly={true}
            theme="bubble"
            mustache={mustache}
            setMustache={setMustache}
          />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default EmailTemplateView;
