export enum RolePermissionLabels {
  call = 'Call',
  document = 'Document',
  email = 'Email',
  note = 'Note',
  opportunity = 'Opportunity',
  service = 'Service',
  task = 'Task',
  brokerage_transaction_role = 'Brokerage Transaction Role',
  federal_holiday_calendar = 'Federal Holiday Calendar',
  account_buyer_name = 'Account Buyer Name',
  account = 'Account',
  contract = 'Contract',
  default_value = 'Default Value',
  email_template = 'Email Template',
  feature_flag = 'Feature Flag',
  knowledge_base = 'Knowledge Base',
  market = 'Market',
  market_preference = 'Market Preference',
  party = 'Party',
  role = 'Role',
  user = 'User'
}

const create_all = 'Create All';
const delete_all = 'Delete All';
const read_all = 'Read All';
const update_all = 'Update All';
const read_opportunity_group = 'Read By Group';
const restore_opportunity_all = 'Restore All';
const update_opportunity_group = 'Update By Group';

export type PermissionKey =
  | 'create_account_all'
  | 'read_account_all'
  | 'update_account_all'
  | 'create_account_buyer_name_all'
  | 'delete_account_buyer_name_all'
  | 'read_account_buyer_name_all'
  | 'update_account_buyer_name_all'
  | 'create_brokerage_transaction_role_all'
  | 'delete_brokerage_transaction_role_all'
  | 'read_brokerage_transaction_role_all'
  | 'update_brokerage_transaction_role_all'
  | 'create_contract_all'
  | 'delete_contract_all'
  | 'read_contract_all'
  | 'update_contract_all'
  | 'create_default_value_all'
  | 'delete_default_value_all'
  | 'read_default_value_all'
  | 'update_default_value_all'
  | 'create_email_template_all'
  | 'delete_email_template_all'
  | 'read_email_template_all'
  | 'update_email_template_all'
  | 'delete_feature_flag_all'
  | 'create_feature_flag_all'
  | 'read_feature_flag_all'
  | 'create_federal_holiday_calendar_all'
  | 'delete_federal_holiday_calendar_all'
  | 'read_federal_holiday_calendar_all'
  | 'update_federal_holiday_calendar_all'
  | 'create_knowledge_base_all'
  | 'delete_knowledge_base_all'
  | 'read_knowledge_base_all'
  | 'update_knowledge_base_all'
  | 'create_market_all'
  | 'delete_market_all'
  | 'read_market_all'
  | 'update_market_all'
  | 'create_market_preference_all'
  | 'read_market_preference_all'
  | 'update_market_preference_all'
  | 'read_opportunity_all'
  | 'read_opportunity_group'
  | 'restore_opportunity_all'
  | 'update_opportunity_all'
  | 'update_opportunity_group'
  | 'create_party_all'
  | 'read_party_all'
  | 'update_party_all'
  | 'create_role_all'
  | 'delete_role_all'
  | 'read_role_all'
  | 'update_role_all'
  | 'create_user_all'
  | 'read_user_all'
  | 'update_user_all';

export const PermissionLabels: Record<PermissionKey, string> = {
  create_account_all: create_all,
  read_account_all: read_all,
  update_account_all: update_all,
  create_account_buyer_name_all: create_all,
  delete_account_buyer_name_all: delete_all,
  read_account_buyer_name_all: read_all,
  update_account_buyer_name_all: update_all,
  create_brokerage_transaction_role_all: create_all,
  delete_brokerage_transaction_role_all: delete_all,
  read_brokerage_transaction_role_all: read_all,
  update_brokerage_transaction_role_all: update_all,
  create_contract_all: create_all,
  delete_contract_all: delete_all,
  read_contract_all: read_all,
  update_contract_all: update_all,
  create_default_value_all: create_all,
  delete_default_value_all: delete_all,
  read_default_value_all: read_all,
  update_default_value_all: update_all,
  create_email_template_all: create_all,
  delete_email_template_all: delete_all,
  read_email_template_all: read_all,
  update_email_template_all: update_all,
  create_feature_flag_all: create_all,
  delete_feature_flag_all: delete_all,
  read_feature_flag_all: read_all,
  create_federal_holiday_calendar_all: create_all,
  delete_federal_holiday_calendar_all: delete_all,
  read_federal_holiday_calendar_all: read_all,
  update_federal_holiday_calendar_all: update_all,
  create_knowledge_base_all: create_all,
  delete_knowledge_base_all: delete_all,
  read_knowledge_base_all: read_all,
  update_knowledge_base_all: update_all,
  create_market_all: create_all,
  delete_market_all: delete_all,
  read_market_all: read_all,
  update_market_all: update_all,
  create_market_preference_all: create_all,
  read_market_preference_all: read_all,
  update_market_preference_all: update_all,
  read_opportunity_all: read_all,
  read_opportunity_group: read_opportunity_group,
  restore_opportunity_all: restore_opportunity_all,
  update_opportunity_all: update_all,
  update_opportunity_group: update_opportunity_group,
  create_party_all: create_all,
  read_party_all: read_all,
  update_party_all: update_all,
  create_role_all: create_all,
  delete_role_all: delete_all,
  read_role_all: read_all,
  update_role_all: update_all,
  create_user_all: create_all,
  read_user_all: read_all,
  update_user_all: update_all
};
