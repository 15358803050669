import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteLink from 'components/link/route-link';
import { useParams } from 'react-router-dom';
import { ObjectType } from 'types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DateUtility from 'helpers/date-helper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import contractRevisionService from 'services/contract-revision-service';
import fileDownloadHelper from 'helpers/file-download-helper';
import { toastError } from 'event/toast-event';

const pdfTemplateRevisionColumns: GridColDef[] = [
  {
    headerName: fieldLabel.fileName,
    field: 'filename',
    sortable: false,
    flex: 1,
    minWidth: 1000,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { pdf_template_id } = useParams<ObjectType>();

      const handleDownloadClick = async () => {
        const result = await contractRevisionService.getPresignUrl(row.id);

        if (result.isError) {
          toastError(result.errorMessage.message);
          return;
        }

        if (result.isSuccess) {
          fileDownloadHelper(result.data);
        }
      };

      return (
        <>
          <RouteLink
            url={`/pdf-templates/${pdf_template_id}/contract_revisions/${row?.id}/viewer`}
            name={
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            }
            target={true}
          />
          <IconButton onClick={handleDownloadClick}>
            <FileDownloadIcon />
          </IconButton>
          <RouteLink url={`#`} name={row.filename} />
        </>
      );
    }
  },
  {
    headerName: fieldLabel.revision,
    field: 'revision',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  }
];

export default pdfTemplateRevisionColumns;
