import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RecordView from '../record-view';
import { ErrorValidation } from 'types/error-types';
import { ChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';
import accountBuyerNameService from 'services/account-buyer-name-service';
import {
  AccountBuyerNameEntity,
  AccountBuyerNamePayloadEntity
} from 'types/account-buyer-name-types';
import initialAccountBuyerName from 'state/account-buyer-name/initial-account-buyer-name';
import { validateAccountBuyerName } from 'helpers/validation/account-buyer-name-helper';
import { ObjectType } from 'types';
import { toastError } from 'event/toast-event';

const AccountBuyerNameCreate = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { account_buyer_name_id } = useParams<{
    account_buyer_name_id: string;
  }>();
  const navigate = useNavigate();
  const [accountBuyerName, setAccountBuyerName] =
    useState<AccountBuyerNameEntity>(initialAccountBuyerName);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setAccountBuyerName(
        Object.assign({}, accountBuyerName, {
          [e.target.name]: e.target.value
        })
      );
    } else {
      setAccountBuyerName(Object.assign({}, accountBuyerName, e));
    }
  };

  const loadAccountBuyerName = async (account_buyer_name_id: string) => {
    setIsLoading(true);
    const response = await accountBuyerNameService.get(account_buyer_name_id);

    setIsLoading(false);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const data = {
      ...response.data,
      market_name: !isEmpty(response.data.market)
        ? response.data.market.name
        : '',
      account_name: !isEmpty(response.data.account)
        ? response.data.account.name
        : ''
    };

    setAccountBuyerName(data);
  };

  const handleSubmit = async () => {
    const reqBody: AccountBuyerNamePayloadEntity = {
      name: accountBuyerName.name,
      entera_customer_buyer_name_id:
        accountBuyerName.entera_customer_buyer_name_id,
      market_id: accountBuyerName.market_id,
      account_id: accountBuyerName.account_id,
      entera_status: accountBuyerName.entera_status
    };

    const { status, errors } = validateAccountBuyerName(reqBody);

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    let response;
    if (!account_buyer_name_id) {
      response = await accountBuyerNameService.create(reqBody);
    } else {
      response = await accountBuyerNameService.update(
        account_buyer_name_id,
        reqBody
      );
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      navigate(`/account-buyer-names/${response.data.id}/view`);
    }
  };

  useEffect(() => {
    if (account_buyer_name_id !== undefined)
      loadAccountBuyerName(account_buyer_name_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              accountBuyerName={accountBuyerName}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default AccountBuyerNameCreate;
