import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';

export const inclusion = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: any = [];

    if (isEmpty(oppurtunity?.inclusion)) {
      errors.push(missingField('Inclusion'));
    }

    return errors;
  }
};
