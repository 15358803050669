import {
  BrokerageUser,
  MarketPreferenceReplaceBrokerageUserPayloadEntity,
  MarketPreferenceBrokerageUserPayloadEntity,
  MarketPreferenceBrokerageUserListEntity
} from 'types/market-preference-brokerage-user-types';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { Box } from '@mui/material';
import { ObjectType } from 'types';
import { useState } from 'react';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import fieldLabel from 'assets/constants/fieldLabel';
import marketPreferenceBrokerageUserService from 'services/market-preference-brokerage-users-service';
import MenuItem from '@mui/material/MenuItem';
import userFullName from 'helpers/user-name-helper';

const StatusAlertBox = ({
  data,
  handleClose
}: {
  data: MarketPreferenceBrokerageUserListEntity;
  handleClose: () => void;
}): JSX.Element => {
  const {
    id,
    status,
    brokerage_user,
    brokerage_transaction_role,
    brokerage_transaction_role_id,
    brokerage_user_id,
    market_preference_id
  }: MarketPreferenceBrokerageUserListEntity = data;

  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const handleUpdate = async (
    id: string,
    reqBody:
      | MarketPreferenceBrokerageUserPayloadEntity
      | MarketPreferenceReplaceBrokerageUserPayloadEntity
  ): Promise<void> => {
    setConfirmModal(initialConfirmModalState);
    const result = await marketPreferenceBrokerageUserService.update(
      id,
      reqBody
    );

    eventBus.dispatch(`market_preference_brokerage_user_refresh`, {});

    if (result.isValidationError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: result.validationMessage['brokerage_user_id'][0],
        isError: true
      });
      return;
    }

    if (result.isError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: result.errorMessage.message,
        isError: true
      });
      return;
    }

    if (result.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: 'Market Preference Brokerage User updated.',
        isError: false
      });
    }
  };

  const generateConfirmationMessage = (
    e: ObjectType,
    brokerage_user: BrokerageUser
  ): string => {
    const fullName = userFullName(brokerage_user);
    const brokerageTransactionRoleName: string =
      brokerage_transaction_role.name;
    const isAlreadyActive = `${fullName} is already active as ${brokerageTransactionRoleName}. Do you want to run a background job to activate this user in all opportunities belonging to this market preference?`;

    const isAlreadyInactive = `${fullName} is already inactive. Do you want to run a background job to inactivate this user in all opportunities belonging to this market preference?`;

    const setActive = `Do you want to activate ${fullName} as ${brokerageTransactionRoleName}.?`;

    const setInactive = `Do you want to inactivate ${fullName} as ${brokerageTransactionRoleName}.?`;

    return e.target.id === 'active-btn' && status === 'active'
      ? isAlreadyActive
      : e.target.id === 'inactive-btn' && status === 'inactive'
      ? isAlreadyInactive
      : status !== 'inactive'
      ? setInactive
      : setActive;
  };

  const handleClick = (e: ObjectType): void => {
    setConfirmModal({
      open: true,
      title: 'ALERT',
      text: generateConfirmationMessage(e, brokerage_user),
      proceed: () => {
        const reqBody:
          | MarketPreferenceBrokerageUserPayloadEntity
          | MarketPreferenceReplaceBrokerageUserPayloadEntity = {
          brokerage_transaction_role_id: brokerage_transaction_role_id,
          brokerage_user_id: brokerage_user_id,
          market_preference_id: market_preference_id,
          status: e.target.outerText === 'Active' ? 'active' : 'inactive'
        };
        handleUpdate(id, reqBody);
        handleClose();
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  return (
    <>
      <MenuItem>
        <Box
          component={'span'}
          style={{
            textDecoration: 'none',
            color: '#141b2d',
            width: '100%',
            fontWeight: 600
          }}
          id="active-btn"
          onClick={(e: ObjectType) => handleClick(e)}
        >
          {fieldLabel.active}
        </Box>
      </MenuItem>

      {brokerage_transaction_role?.name !== 'negotiator' && (
        <MenuItem>
          <Box
            component={'span'}
            style={{
              textDecoration: 'none',
              color: '#141b2d',
              width: '100%',
              fontWeight: 600
            }}
            id="inactive-btn"
            onClick={(e: ObjectType) => handleClick(e)}
          >
            {fieldLabel.inactive}
          </Box>
        </MenuItem>
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};
export default StatusAlertBox;
