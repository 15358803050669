import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';
import fieldLabel from 'assets/constants/fieldLabel';
import appPermission from 'assets/constants/app-permissions';
import DeleteIcon from '@mui/icons-material/Delete';
/** @TODO add Types */

const federalHolidayCalendarList = {
  label: fieldLabel.list,
  url: () => `/federal-holiday-calendars`,
  labelIcon: ListAltIcon,
  model: appModel.federalHolidayCalendar,
  nodeId: uuid()
};

const createFederalHolidayCalendar = {
  label: fieldLabel.create,
  url: () => `/federal-holiday-calendars/create`,
  labelIcon: CreateIcon,
  model: appModel.federalHolidayCalendar,
  nodeId: uuid()
};

const editFederalHolidayCalendar = {
  label: fieldLabel.edit,
  url: (id: string) => `/federal-holiday-calendars/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.federalHolidayCalendar,
  nodeId: uuid()
};

const viewFederalHolidayCalendar = {
  label: fieldLabel.view,
  url: (id: string) => `/federal-holiday-calendars/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.federalHolidayCalendar,
  nodeId: uuid()
};

const deleteFederalHolidayCalendar = {
  label: fieldLabel.delete,
  url: (id: string) => `/federal-holiday-calendars/${id}/delete`,
  labelIcon: DeleteIcon,
  model: appModel.federalHolidayCalendar,
  nodeId: uuid()
};
const federalHolidayCalendarMenuItems = {
  list: [federalHolidayCalendarList, createFederalHolidayCalendar],
  edit: [
    federalHolidayCalendarList,
    viewFederalHolidayCalendar,
    deleteFederalHolidayCalendar
  ],
  create: [federalHolidayCalendarList, createFederalHolidayCalendar],
  view: [
    federalHolidayCalendarList,
    editFederalHolidayCalendar,
    deleteFederalHolidayCalendar
  ]
};

export default federalHolidayCalendarMenuItems;
