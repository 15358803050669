import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  CardContent,
  Card,
  CardHeader,
  ListSubheader,
  Skeleton,
  List
} from '@mui/material';
import { Permission, Permissions, RoleEntity } from 'types/role-types';
import { toastError, toastSuccess } from 'event/toast-event';
import rolePermissionService from 'services/role-permission-service';
import {
  PermissionLabels,
  RolePermissionLabels
} from 'assets/constants/role-permission';
import Masonry from '@mui/lab/Masonry';
import Tooltip from 'components/tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

interface Props {
  role: RoleEntity;
}

const PermissionList: React.FC<Props> = ({ role }) => {
  const [permissions, setPermissions] = useState<Permissions>({});
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = role.id === '1';
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getAssociatedPermissions = async () => {
    if (!role.id) return;
    setIsLoading(true);

    const result = await rolePermissionService.getAssociatedPermissionList(
      role.id
    );
    setIsLoading(false);

    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }
    if (result.isSuccess) {
      setErrorMessage('');
      setPermissions(result.data);
    }
  };

  const getRoleName = (module: string): string => {
    return (
      RolePermissionLabels[module as keyof typeof RolePermissionLabels] ??
      module
    );
  };

  const getPermissionName = (name: string): string => {
    return PermissionLabels[name as keyof typeof PermissionLabels] ?? name;
  };

  const handleTogglePermission = async (
    permissionId: string,
    module: string,
    action: string
  ) => {
    const permission = permissions[module].find(
      (perm: Permission) => perm.id === permissionId
    );

    const newHasRole = !permission?.has_role;

    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [module]: prevPermissions[module].map((perm) =>
        perm.id === permission?.id ? { ...perm, has_role: newHasRole } : perm
      )
    }));

    try {
      const response = await rolePermissionService.updatePermissionAssociation(
        role.id,
        permissionId
      );

      if (response.isError) {
        toastError(response.errorMessage.message);

        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [module]: prevPermissions[module].map((perm) =>
            perm.id === permission?.id
              ? { ...perm, has_role: !newHasRole }
              : perm
          )
        }));
      }

      if (response.isSuccess) {
        const roleName =
          RolePermissionLabels[module as keyof typeof RolePermissionLabels] ??
          module;

        const actionMsg = newHasRole ? 'been added' : 'been removed';

        const message = `${roleName} ${action} permission has ${actionMsg} successfully.`;

        toastSuccess(message);
      }
    } catch (error) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [module]: prevPermissions[module].map((perm) =>
          perm.id === permission?.id ? { ...perm, has_role: newHasRole } : perm
        )
      }));
    }
  };

  useEffect(() => {
    if (role.id) {
      getAssociatedPermissions();
    }
  }, [role.id]);

  return (
    <>
      {role.id &&
        (!isEmpty(errorMessage) ? (
          <ErrorComponent message={errorMessage} />
        ) : (
          <>
            <List
              dense
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                wordWrap: 'break-word',
                maxHeight: '84vh',
                overflow: 'auto',
                paddingLeft: 2,
                paddingRight: 0
              }}
              subheader={
                <ListSubheader
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    color: 'black'
                  }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    Permissions for Role: {role.name}
                  </Typography>
                </ListSubheader>
              }
            >
              {isLoading ? (
                <>
                  <Masonry
                    columns={{ xs: 1, sm: 2, md: 1, lg: 2, xl: 3 }}
                    spacing={2}
                  >
                    {Array.from(new Array(3)).map((_, index) => (
                      <Card key={index}>
                        <CardHeader
                          title={
                            <Skeleton variant="text" width={150} height={24} />
                          }
                          sx={{ backgroundColor: '#f5f5f5', padding: 2 }}
                        />
                        <CardContent>
                          <Table>
                            <TableBody>
                              {Array.from(new Array(4)).map((_, rowIndex) => (
                                <TableRow key={rowIndex}>
                                  <TableCell align="right">
                                    <Skeleton
                                      variant="circular"
                                      width={24}
                                      height={24}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton
                                      variant="text"
                                      width={100}
                                      height={20}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    ))}
                  </Masonry>
                </>
              ) : (
                <>
                  <Masonry
                    columns={{ xs: 1, sm: 2, md: 1, lg: 2, xl: 3 }}
                    spacing={2}
                  >
                    {Object.entries(permissions).map(
                      ([module, permissionsList]) => (
                        <Card
                          key={module}
                          sx={{
                            borderRadius: 0,
                            maxHeight: '78vh'
                          }}
                        >
                          <CardHeader
                            title={
                              <Typography variant="body1" fontWeight={700}>
                                {getRoleName(module)}
                                {module == 'opportunity' && (
                                  <>
                                    {' '}
                                    + Related Modules
                                    <Tooltip
                                      title={
                                        'Actions, Email, Documents,Services, Notes/Chat, Opportunity Brokerage Users,Generate Contract, Call, Task, SQS, Transaction History'
                                      }
                                    >
                                      <HelpIcon />
                                    </Tooltip>
                                  </>
                                )}
                              </Typography>
                            }
                            sx={{
                              backgroundColor: '#f5f5f5',
                              padding: 2
                            }}
                          />
                          <CardContent>
                            <Table>
                              <TableBody>
                                {Object.entries(permissionsList).map(
                                  ([permissionKey, permissionDetails]) => (
                                    <TableRow
                                      key={permissionKey}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          borderBottom: 'none'
                                        }
                                      }}
                                    >
                                      <TableCell>
                                        <Checkbox
                                          checked={permissionDetails.has_role}
                                          onChange={() =>
                                            handleTogglePermission(
                                              permissionDetails.id,
                                              module,
                                              getPermissionName(
                                                permissionDetails.name
                                              )
                                            )
                                          }
                                          disabled={isAdmin}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        {getPermissionName(
                                          permissionDetails.name
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      )
                    )}
                  </Masonry>
                </>
              )}
            </List>
          </>
        ))}
    </>
  );
};

export default PermissionList;
