type FieldOptionPropertiesType = {
  name: string;
  title: string;
  checked: boolean;
};

type FieldOptionType = {
  forecasted_dd_end_date: FieldOptionPropertiesType;
  forecasted_close_date: FieldOptionPropertiesType;
  both: FieldOptionPropertiesType;
};

export const fieldOptions: FieldOptionType = {
  forecasted_dd_end_date: {
    name: 'forecasted_dd_end_date',
    title: 'DD End Date',
    checked: false
  },
  forecasted_close_date: {
    name: 'forecasted_close_date',
    title: 'Close Date',
    checked: false
  },
  both: {
    name: 'both',
    title: 'Both',
    checked: false
  }
};
