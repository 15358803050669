import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RecordView from '../record-view';
import { ErrorValidation } from 'types/error-types';
import initialFederalHolidayCalendar from 'state/federal-holiday-calendar/initial-federal-holiday-calendar';
import {
  FederalHolidayCalendarEntity,
  FederalHolidayCalendarPayloadEntity
} from 'types/federal-holiday-calendar';
import { validateFederalHolidayCalendar } from 'helpers/validation/federal-holiday-calendar-helper';
import federalHolidayCalendarService from 'services/federal-holiday-calendar-service';
import { InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';
import { toastError } from 'event/toast-event';

const CreateFederalHolidayCalendars = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { federal_holiday_calendar_id } = useParams<{
    federal_holiday_calendar_id: string;
  }>();
  const navigate = useNavigate();
  const [federalHolidayCalendar, setFederalHolidayCalendar] =
    useState<FederalHolidayCalendarEntity>(initialFederalHolidayCalendar);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: InputChangeEvent): void => {
    setFederalHolidayCalendar(
      Object.assign({}, federalHolidayCalendar, {
        [e.target.name]: e.target.value
      })
    );
  };

  const loadFederalHolidayCalendar = async (
    federal_holiday_calendar_id: string
  ) => {
    setIsLoading(true);
    const response = await federalHolidayCalendarService.get(
      federal_holiday_calendar_id
    );

    setIsLoading(false);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setFederalHolidayCalendar(response.data);
  };

  const handleSubmit = async () => {
    const reqBody: FederalHolidayCalendarPayloadEntity = {
      name: federalHolidayCalendar.name,
      description: federalHolidayCalendar.description,
      holiday_date: federalHolidayCalendar.holiday_date
    };

    const { status, errors } = validateFederalHolidayCalendar(reqBody);

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    let response;
    if (!federal_holiday_calendar_id) {
      response = await federalHolidayCalendarService.create(reqBody);
    } else {
      response = await federalHolidayCalendarService.update(
        federal_holiday_calendar_id,
        reqBody
      );
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      navigate(`/federal-holiday-calendars/${response.data.id}/view`);
    }
  };

  useEffect(() => {
    if (federal_holiday_calendar_id !== undefined)
      loadFederalHolidayCalendar(federal_holiday_calendar_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              federalHolidayCalendar={federalHolidayCalendar}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default CreateFederalHolidayCalendars;
