import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';
import fieldLabel from 'assets/constants/fieldLabel';
import HistoryIcon from '@mui/icons-material/History';
/** @TODO add Types */

const brokerageTransactionRoleList = {
  label: fieldLabel.list,
  url: () => `/brokerage-transaction-roles`,
  labelIcon: ListAltIcon,
  model: appModel.brokerageTransactionRole,
  nodeId: uuid()
};

const createBrokerageTransactionRole = {
  label: fieldLabel.create,
  url: () => `/brokerage-transaction-roles/create`,
  labelIcon: CreateIcon,
  model: appModel.brokerageTransactionRole,
  nodeId: uuid()
};

const editBrokerageTransactionRole = {
  label: fieldLabel.edit,
  url: (id: string) => `/brokerage-transaction-roles/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.brokerageTransactionRole,
  nodeId: uuid()
};

const viewBrokerageTransactionRole = {
  label: fieldLabel.view,
  url: (id: string) => `/brokerage-transaction-roles/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.brokerageTransactionRole,
  nodeId: uuid()
};

const viewChangeLogBrokerageTransactionRole = {
  label: 'View Changelog',
  url: (id: string) => `/brokerage-transaction-roles/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.brokerageTransactionRole,
  nodeId: uuid()
};

const brokerageTransactionRoleMenuItems = {
  list: [brokerageTransactionRoleList, createBrokerageTransactionRole],
  edit: [
    brokerageTransactionRoleList,
    viewBrokerageTransactionRole,
    viewChangeLogBrokerageTransactionRole
  ],
  create: [brokerageTransactionRoleList, createBrokerageTransactionRole],
  view: [
    brokerageTransactionRoleList,
    editBrokerageTransactionRole,
    viewChangeLogBrokerageTransactionRole
  ]
};

export default brokerageTransactionRoleMenuItems;
