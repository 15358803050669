import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';

export const earnest_money_status_c = {
  validate: function (oppurtunity: any, status: string) {
    let errors: any = [];

    if (
      (!isEmpty(oppurtunity?.emd_requested_confirmed) ||
        !isEmpty(oppurtunity?.em_delivery_date_c)) &&
      isEmpty(oppurtunity?.earnest_money_status_c)
    ) {
      errors.push(missingField('Earnest Status'));
    }

    return errors;
  }
};
