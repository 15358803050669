import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SaveButton from 'components/form/button-save';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitEmpty from 'components/form/unit-empty';
import UnitSelect from 'components/form/unit-select';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import yesNo from 'assets/constants/yes-no';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import { getInitialTerminationApproval } from 'state/brokerage-actions/termination-approval';
import {
  ActionRecordViewPropTypes,
  TerminationApprovalEntity,
  TerminationApprovalPaylodEntity
} from 'types/brokerage-action-types';
import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import brokerageActionService from 'services/brokerage-action-service';
import { brokerageActionEvent } from 'event/brokerage-action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import emptyFunction from 'helpers/empty-function-helper';
import { toastError } from 'event/toast-event';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<TerminationApprovalEntity>(
    getInitialTerminationApproval(opportunity)
  );

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const { isValid, errors } = validateBrokerageAction(data, [
      'earnest_recovery_amount',
      'earnest_paid_by_entera_amount'
    ]);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const reqBody: TerminationApprovalPaylodEntity = {
      earnest_recovered_by_buyer: data.earnest_recovered_by_buyer,
      earnest_recovery_amount: data.earnest_recovery_amount,
      earnest_paid_by_entera: data.earnest_paid_by_entera,
      earnest_paid_by_entera_amount: data.earnest_paid_by_entera_amount
    };

    setIsLoading(true);

    const result =
      await brokerageActionService.update<TerminationApprovalPaylodEntity>(
        opportunity.id,
        reqBody,
        'termination_complete'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.termination_approval();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity();
      }
    }
  };

  useEffect(() => {
    setData((prevOppurtunity) => ({
      ...prevOppurtunity,
      ...getInitialTerminationApproval(opportunity),
      opportunity_status_c: 'termination_complete'
    }));
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitPriceFormatter
              label={fieldLabel.earnestAmount}
              name={'earnest_amount_c'}
              value={data.earnest_amount_c ?? ''}
              onChange={handleChange}
              disabled
            />

            <UnitEmpty />

            <UnitSelect
              name="earnest_recovered_by_buyer"
              label={fieldLabel.emRecoveredByBuyer}
              records={getObjectEntriesAsArray(yesNo)}
              value={data.earnest_recovered_by_buyer ?? ''}
              onChange={handleChange}
            />

            <UnitPriceFormatter
              label={fieldLabel.emRecoveryAmount}
              name={'earnest_recovery_amount'}
              value={data.earnest_recovery_amount ?? ''}
              onChange={handleChange}
              error={validation['earnest_recovery_amount'] ?? ''}
            />

            <UnitSelect
              name="earnest_paid_by_entera"
              label={fieldLabel.emPaidByEntera}
              records={getObjectEntriesAsArray(yesNo)}
              value={data.earnest_paid_by_entera ?? ''}
              onChange={handleChange}
            />

            <UnitPriceFormatter
              label={fieldLabel.emPaidByEnteraAmount}
              name={'earnest_paid_by_entera_amount'}
              value={data.earnest_paid_by_entera_amount ?? ''}
              onChange={handleChange}
              error={validation['earnest_paid_by_entera_amount'] ?? ''}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
