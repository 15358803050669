import eventBus from 'helpers/event-bus-helper';
import { getItem } from 'helpers/local-storage-helper';
import { isEmpty } from 'helpers/misc-helper';
import userFullName from 'helpers/user-name-helper';
import vendorService from 'services/vendor-service';
import { ObjectType } from 'types';
import { VendorServiceEntity } from 'types/vendor-service-types';

const deleteService = async (service_id: string) => {
  const result = await vendorService.delete(service_id);

  eventBus.dispatch(`service_refresh`, {});
  return result;
};

const serviceFields: {
  [key: string]: (service: VendorServiceEntity) => ObjectType;
} = {
  requested_by: (service: VendorServiceEntity) => {
    if (isEmpty(service.requested_at)) {
      return {
        requested_by: '',
        requested_by_name: ''
      };
    } else {
      let user = JSON.parse(getItem('user') || 'false');

      return {
        requested_by: user?.user?.id,
        requested_by_name: `${userFullName(user?.user)}`
      };
    }
  },
  confirmation_by: (service: VendorServiceEntity) => {
    if (isEmpty(service.confirmation_at)) {
      return {
        confirmation_by: '',
        confirmation_by_name: ''
      };
    } else {
      let user = JSON.parse(getItem('user') || 'false');
      return {
        confirmation_by: user?.user?.id,
        confirmation_by_name: `${userFullName(user?.user)}`
      };
    }
  }
};

export { deleteService, serviceFields };
