import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import RowCard from './row-item';
import UnitItem from 'components/form/unit-item';
import AddButton from 'components/form/button-add';
import Title from 'components/typography/title';
import { FeaturedFlag } from 'types/admin-config';
import featuresService from 'services/feature-service';
import { toastError, toastSuccess } from 'event/toast-event';
import initialFeaturedFlag from 'state/intial-feature-flag';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import fieldLabel from 'assets/constants/fieldLabel';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import CircularLoader from 'components/dog-loader/dog-lodar';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';

const FeaturedFlagList: React.FC = () => {
  const [flags, setFlags] = useState<FeaturedFlag[]>([]);

  const [showNew, setShowNew] = useState<boolean>(false);

  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSave = (index: number, obj: FeaturedFlag) => {
    const updatedData = [...flags];
    updatedData[index] = obj;
    setFlags(updatedData);
  };

  const handleCancel = () => {
    if (showNew) {
      setShowNew(false);
    }
  };

  const loadFlags = async () => {
    setLoading(true);
    const result = await featuresService.getList('');
    setLoading(false);
    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }
    setFlags(result.data.data);
  };

  const handleDelete = (flag: FeaturedFlag) => {
    setConfirmModal({
      open: true,
      title: `Remove ${fieldLabel.featuredFlag}`,
      text: MessageDeleteRecordConfirmation(flag.name, 'Flag'),

      proceed: async () => {
        const result = await featuresService.delete(
          `?name=${flag.name}&target_model=${flag.target_model}`
        );

        if (result.isError) {
          toastError(result.errorMessage.message);
        }

        if (result.isSuccess) {
          toastSuccess(fieldLabel.savedSuccessfully);
        }

        setConfirmModal(initialConfirmModal);
        loadFlags();
      },
      cancel: () => {
        setConfirmModal(initialConfirmModal);
      }
    });
  };

  const isDuplicate = (flag: FeaturedFlag) => {
    const isExists = flags.findIndex(
      (x) => x.name === flag.name && x.target_model === flag.target_model
    );

    return isExists;
  };

  useEffect(() => {
    loadFlags();
  }, []);

  return (
    <>
      <UnitItem>
        <AddButton onClick={() => setShowNew(true)} sx={{ float: 'right' }} />
      </UnitItem>
      {loading ? (
        <CircularLoader />
      ) : (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: 2,
              flexWrap: 'wrap',
              borderRadius: 2,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              border: '1px solid #ddd',
              backgroundColor: '#e0e0e0',
              marginBottom: 2,
              marginX: 1
            }}
          >
            <Title value="Flag" sx={{ width: '25%' }}></Title>
            <Title value="Category" sx={{ width: '25%' }}></Title>
            <Title value="Targets" sx={{ width: '25%' }}></Title>
          </Box>
          {isEmpty(errorMessage) ? (
            <>
              {showNew && (
                <RowCard
                  data={initialFeaturedFlag}
                  onSave={(obj) => {
                    loadFlags();
                    setShowNew(false);
                  }}
                  onCancel={handleCancel}
                  checkDuplicate={(val: FeaturedFlag) => {
                    return isDuplicate(val) > -1 ? true : false;
                  }}
                />
              )}

              <Box
                sx={{
                  maxHeight: '800px',
                  overflowY: 'auto'
                }}
              >
                {flags.map((item, index) => (
                  <RowCard
                    key={item.id}
                    data={item}
                    onSave={(obj) => {
                      onSave(index, obj);
                    }}
                    onCancel={handleCancel}
                    handleDelete={handleDelete}
                  />
                ))}
              </Box>
            </>
          ) : (
            <ErrorComponent message={errorMessage} />
          )}
        </Box>
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};

export default FeaturedFlagList;
