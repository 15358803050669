import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { EnterSalePendingEntity } from 'types/brokerage-action-types';

const initialEnterSalePending: EnterSalePendingEntity = {
  opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
  contract_price_c: '',
  purchase_price_c: 0,
  initial_commission_percentage: 0,
  initial_commission_amount: 0,
  commision_pending: 0,
  buyer_commission_c: '',
  special_instructions: '',
  has_leasing_restrictions: '',
  entera_contribution_amount: '',
  entera_contribution: '',
  cda_pay_to_2: '',
  cda_pay_to_3: '',
  cda_amount_2: '',
  cda_amount_3: '',
  reduction_type: '',
  commission_reduction_type2: '',
  commision_reduction: '',
  commision_reduction2: '',
  restrictions_notes: '',
  buyer_bonus_c: '',
  total_commission_reductions: 0,
  final_commission: 0,
  final_commission_percentage: 0,
  hud_commission: ''
};

export default initialEnterSalePending;
