import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import {
  EnterSalePendingEntity,
  EnterSalePendingPayloadEntity
} from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export const getInitialState = (
  opportunity: OpportunityEntity
): EnterSalePendingEntity => {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_sale_pending,
    contract_price_c: opportunity?.contract_price_c,
    purchase_price_c: opportunity?.purchase_price_c,
    initial_commission_percentage: opportunity?.initial_commission_percentage,
    initial_commission_amount: opportunity?.initial_commission_amount,
    commision_pending: opportunity?.commision_pending,
    buyer_commission_c: opportunity?.buyer_commission_c,
    special_instructions: opportunity?.special_instructions,
    has_leasing_restrictions: opportunity?.has_leasing_restrictions,
    entera_contribution_amount: opportunity?.entera_contribution_amount,
    entera_contribution: opportunity?.entera_contribution,
    cda_pay_to_2: opportunity?.cda_pay_to_2,
    cda_pay_to_3: opportunity?.cda_pay_to_3,
    cda_amount_2: opportunity?.cda_amount_2,
    cda_amount_3: opportunity?.cda_amount_3,
    reduction_type: opportunity?.reduction_type,
    commission_reduction_type2: opportunity?.commission_reduction_type2,
    commision_reduction: opportunity?.commision_reduction,
    commision_reduction2: opportunity?.commision_reduction2,
    restrictions_notes: opportunity?.restrictions_notes,
    buyer_bonus_c: opportunity?.buyer_bonus_c,
    total_commission_reductions: opportunity?.total_commission_reductions,
    final_commission: opportunity?.final_commission,
    final_commission_percentage: opportunity?.final_commission_percentage,
    hud_commission: opportunity?.hud_commission
  };
};

export const prepareRequestBody = (
  data: EnterSalePendingEntity
): EnterSalePendingPayloadEntity => {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_sale_pending,
    contract_price_c: data.contract_price_c,
    purchase_price_c: data.purchase_price_c,
    initial_commission_percentage: data.initial_commission_percentage,
    initial_commission_amount: data.initial_commission_amount,
    commision_pending: data.commision_pending,
    buyer_commission_c: data.buyer_commission_c,
    special_instructions: data.special_instructions,
    has_leasing_restrictions: data.has_leasing_restrictions,
    entera_contribution_amount: data.entera_contribution_amount,
    cda_pay_to_2: data.cda_pay_to_2,
    cda_pay_to_3: data.cda_pay_to_3,
    cda_amount_2: data.cda_amount_2,
    cda_amount_3: data.cda_amount_3,
    reduction_type: data.reduction_type,
    commission_reduction_type2: data.commission_reduction_type2,
    commision_reduction: data.commision_reduction,
    commision_reduction2: data.commision_reduction2,
    restrictions_notes: data.restrictions_notes,
    buyer_bonus_c: data.buyer_bonus_c,
    total_commission_reductions: data.total_commission_reductions,
    final_commission: data.final_commission,
    final_commission_percentage: data.final_commission_percentage,
    hud_commission: data.hud_commission
  };
};

export const getValidationFields = (): string[] => {
  return [
    'initial_commission_amount',
    'commision_pending',
    'buyer_commission_c',
    'reduction_type',
    'commission_reduction_type2',
    'has_leasing_restrictions',
    'restrictions_notes'
  ];
};
