import { toastError, toastSuccess } from 'event/toast-event';
import React, { useContext, useEffect, useState } from 'react';
import rolePermissionService from 'services/role-permission-service';
import initialRolesList from 'state/role/initial-roles-list';
import { RoleEntity } from 'types/role-types';

import { Add, Check, Edit, Delete } from '@mui/icons-material';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
  Skeleton,
  IconButton
} from '@mui/material';

import AddRole from '../add-role';
import PermissionList from '../permissions-list';
import UserList from '../users-list';
import useRouteName from 'pages/route-outlet-context';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import fieldLabel from 'assets/constants/fieldLabel';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

export default function RolesAndUsersHorizontal() {
  const [openRole, setOpenRole] = useState<string | null>(null);
  const [roles, setRoles] = useState<RoleEntity[]>(initialRolesList);
  const [isLoading, setIsLoading] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<RoleEntity | undefined>();
  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);

  const { setRouteName } = useRouteName();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const selectedRole = roles.find((role) => role.id === openRole) as RoleEntity;

  const getRoles = async () => {
    setIsLoading(true);
    const result = await rolePermissionService.getList();
    setIsLoading(false);

    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }
    if (result.isSuccess) {
      setRoles(result.data.data);
    }
  };

  const handleRoleClick = (roleId: string) => {
    setOpenRole(roleId);
  };

  const handleEditRole = (role: RoleEntity, event: React.MouseEvent) => {
    event.stopPropagation();
    setRoleToEdit(role);
    setRoleModal(true);
  };

  const handleDeleteRole = async (
    role: RoleEntity,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setConfirmModal({
      open: true,
      title: fieldLabel.removeRole,
      text: MessageDeleteRecordConfirmation(role.name, fieldLabel.role),

      proceed: async () => {
        const response = await rolePermissionService.delete(role.id);

        if (response.isError) {
          toastError(response.errorMessage.message);
          return;
        }

        if (response.isSuccess) {
          toastSuccess(`${role.name} role deleted successfully.`);
          setOpenRole(null);
          setConfirmModal(initialConfirmModal);
          setRoles((prevRoles) => prevRoles.filter((r) => r.id !== role.id));
        }
      },
      cancel: () => {
        setConfirmModal(initialConfirmModal);
      }
    });
  };

  useEffect(() => {
    getRoles();
    setRouteName('roles');
  }, []);

  return !isEmpty(errorMessage) ? (
    <ErrorComponent message={errorMessage} />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3}>
        <List
          dense
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            wordWrap: 'break-word',
            maxHeight: '84vh',
            overflow: 'auto'
          }}
          subheader={
            <ListSubheader
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                color: 'black'
              }}
            >
              <Typography variant="body1" fontWeight={700}>
                Roles
              </Typography>
              <Add
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setRoleToEdit(undefined);
                  setRoleModal(true);
                }}
              />
            </ListSubheader>
          }
        >
          {isLoading ? (
            <>
              {Array.from(new Array(20)).map((_, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton disabled>
                    <Skeleton variant="text" width="100%" />
                  </ListItemButton>
                </ListItem>
              ))}
            </>
          ) : (
            roles.map((role: RoleEntity) => (
              <ListItem key={role.id} disablePadding>
                <ListItemButton
                  onClick={() => handleRoleClick(role.id)}
                  selected={openRole === role.id}
                >
                  <ListItemText
                    primary={role.name}
                    secondary={role.description || ''}
                  />
                  {openRole === role.id && <Check />}
                  {role.id != '1' && (
                    <>
                      <IconButton
                        onClick={(event) => handleEditRole(role, event)}
                        size="small"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={(event) => handleDeleteRole(role, event)}
                        size="small"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        {openRole && <UserList role={selectedRole} />}
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        {openRole && <PermissionList role={selectedRole} />}
      </Grid>
      {roleModal && (
        <AddRole
          title={roleToEdit ? 'Edit Role' : 'Add Role'}
          showModal={roleModal}
          setShowModal={setRoleModal}
          setRoles={setRoles}
          roleToEdit={roleToEdit}
        />
      )}
      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </Grid>
  );
}
