import opportunityStatus from 'assets/constants/opportunity-status';
import CircularLoader from 'components/dog-loader/dog-lodar';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateMarketPreference } from 'helpers/validation/market-preference-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import marketPreferenceervice from 'services/market-preference-service';
import initialMarketPreference from 'state/market-preference';
import { ObjectType } from 'types';
import { MarketPreferenceEntity } from 'types/market-preference-types';
import RecordView from '../record-view';
import setRequest from './set-request';
import { ErrorValidation } from 'types/error-types';
import { ChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { toastError } from 'event/toast-event';
import ErrorComponent from 'components/errors/error-component';

const MarketPreferenceCreate = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();

  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [marketPreference, setMarketPreference] =
    useState<MarketPreferenceEntity>(initialMarketPreference);

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setMarketPreference(
        Object.assign({}, marketPreference, {
          [e.target.name]: e.target.value
        })
      );
    } else {
      setMarketPreference(Object.assign({}, marketPreference, e));
    }
  };

  const loadMarketPreference = async (
    market_preference_id: string
  ): Promise<void> => {
    setloading(true);
    const response = await marketPreferenceervice.get(market_preference_id);
    setloading(false);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const sqs: string | string[] =
      !isEmpty(response.data.sqs_status_trigger) &&
      typeof response.data.sqs_status_trigger === 'string'
        ? response.data.sqs_status_trigger.replace(/\^/g, '').trim()
        : [];

    const sqs_status_trigger = getObjectEntriesAsArray(
      opportunityStatus
    ).filter((x: ObjectType) => sqs.includes(x.value));
    const data = {
      ...response.data,
      sqs_status_trigger: sqs_status_trigger
        .map((x: ObjectType) => x.label)
        .join(', '),
      sqs_status_trigger_text_display: sqs_status_trigger,
      msa_name: !isEmpty(response.data.get_msa)
        ? response.data.get_msa.name
        : '',
      account_name: !isEmpty(response.data.get_account)
        ? response.data.get_account.name
        : ''
    };
    setMarketPreference(data);
  };

  const handleSubmit = async (): Promise<void> => {
    const { hasValidationError, ...errors } =
      validateMarketPreference(marketPreference);

    setValidation(errors);

    if (hasValidationError) return;

    let response;

    let requestBody = setRequest(marketPreference);
    requestBody = {
      ...requestBody,
      sqs_status_trigger:
        typeof requestBody.sqs_status_trigger === 'string'
          ? requestBody.sqs_status_trigger.split(',')
          : []
    };

    setIsLoading(true);
    if (market_preference_id) {
      response = await marketPreferenceervice.update(
        market_preference_id,
        requestBody
      );
    } else {
      response = await marketPreferenceervice.create(requestBody);
    }

    setIsLoading(false);

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isSuccess) {
      navigate(`/market-preferences/${response.data.id}/view`);
    }
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  useEffect(() => {
    if (market_preference_id !== undefined)
      loadMarketPreference(market_preference_id);
  }, []);

  if (loading) return <CircularLoader />;

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              marketPreference={marketPreference}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default MarketPreferenceCreate;
