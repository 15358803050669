import { ObjectType } from 'types';

export const complianceAuditStatus: ObjectType = {
  not_required: 'Not Required',
  audit_required: 'Audit Required',
  audit_in_progress: 'Audit In Progress',
  provisional_fail: 'Provisional Fail',
  preliminary_pass: 'Preliminary Pass',
  pass: 'Pass',
  fail: 'Fail',
  pass_remediated: 'Pass - Remediated'
};

export const complianceAuditStatusTooltip: { [key: string]: string } = {
  not_required:
    'No audit is needed based on the sampling methodology or criteria.',
  audit_required: 'An audit is mandated but has not been initiated yet.',
  audit_in_progress: 'The necessary audit is currently underway.',
  provisional_fail:
    'A discrepancy has been detected, and further action is required by the Agent or Closing Services.',
  preliminary_pass:
    'The initial review indicates a pass, but a manager must review and confirm the final status.',
  pass: 'Audit has been completed successfully, with no issues or corrective actions required.',
  fail: 'Audit has been completed, and the result is a failure.',
  pass_remediated:
    'Audit was successful, but corrective actions were necessary to address identified issues.'
};
