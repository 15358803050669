import { toastError } from 'event/toast-event';
import eventBus from 'helpers/event-bus-helper';
import marketPreferenceService from 'services/market-preference-service';
import opportunityService from 'services/oppurtunity-service';

const removeAssociatedDocument = async (
  market_preference_id: string,
  document_id: string
) => {
  const response = await marketPreferenceService.deleteAssocationDocument(
    market_preference_id,
    document_id
  );
  return response;
};

const removeAssociatedOpportunityDocument = async (
  opportunity_id: string,
  document_id: string
) => {
  const result = await opportunityService.deleteAssociatedDocument(
    opportunity_id,
    document_id
  );

  if (result.isError) {
    toastError(result.errorMessage.message);
  }

  eventBus.dispatch(`document_refresh`, {});
};

export { removeAssociatedDocument, removeAssociatedOpportunityDocument };
