import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.AOSPdfTemplates);

    if (routeName === 'pdf-templates') return modelMenu[appPermission.list];

    if (
      routeName === 'pdf-templates-view' ||
      routeName === 'contracts-revisions'
    )
      return modelMenu[appPermission.view];

    if (
      [
        'pdf-templates-edit',
        'pdf-templates-pdf-view',
        'pdf-templates-readable-pdf-view'
      ].includes(routeName)
    )
      return modelMenu[appPermission.edit];

    if (routeName === 'pdf-templates-create')
      return modelMenu[appPermission.create];

    if (routeName === 'pdf-templates-delete')
      return modelMenu[appPermission.delete];

    return [];
  }
};

export default sideManuHelper;
