import FormContainer from 'components/form/container';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import {
  HtmlContentType,
  EmailTemplateEntity,
  MustacheFieldsEntity
} from 'types/email-template-types';
import fieldLabel from 'assets/constants/fieldLabel';
import { useParams } from 'react-router-dom';
import UnitHtmlEditor from 'components/form/unit-html-editor';
import emptyFunction from 'helpers/empty-function-helper';
import emailInspection from 'assets/constants/email-inspection';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import relatedEvent from 'assets/constants/related-event';
import opportunityService from 'services/oppurtunity-service';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import InsertButton from 'components/form/button-insert';
import StackRow from 'components/stack/stack-row';
import { ErrorValidation } from 'types/error-types';
import {
  ChangeEvent,
  DispatchSetState,
  InputChangeEvent
} from 'types/common-types';
import { OptionType } from 'types/option-type';
import { ObjectType } from 'types';
import emailTemplateService from 'services/email-template-service';
import emailService from 'services/email-service';
import { isEmpty, isEmptyObject } from 'helpers/misc-helper';
import emailTemplateTypes from 'assets/constants/email-template-types';
import UnitEmpty from 'components/form/unit-empty';
import TabArea from 'components/tabs/tab-area';
import UnitItem from 'components/form/unit-item';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import UnitOpportunitySelect from 'components/form/unit-opportunity-select';
import DateUtility from 'helpers/date-helper';
import userFullName from 'helpers/user-name-helper';
import { toastError } from 'event/toast-event';

interface recordViewType {
  emailTemplate: EmailTemplateEntity;
  validation?: ErrorValidation;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
  theme?: string;
  content?: ObjectType;
  setContent?: DispatchSetState<Partial<HtmlContentType>>;
  isCreate?: boolean;
  mustache?: ObjectType;
  setMustache?: DispatchSetState<Partial<HtmlContentType>>;
  setValidation?: DispatchSetState<ErrorValidation>;
  setEmailTemplate?: DispatchSetState<EmailTemplateEntity>;
}

const RecordView = ({
  emailTemplate,
  validation,
  onChange = emptyFunction,
  readOnly = false,
  theme = 'bubble',
  content = {},
  setContent = emptyFunction,
  isCreate = false,
  mustache = {},
  setMustache = emptyFunction,
  setValidation = emptyFunction,
  setEmailTemplate = emptyFunction
}: recordViewType): JSX.Element => {
  const tabId = 'email-crm-templates-tab';
  const tabId2 = 'email-mustache-templates-tab';

  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();

  const [insertVatiableOptions, setInsertVatiableOptions] =
    useState<OptionType[]>();
  const [mustacheFields, setMustacheFields] = useState<MustacheFieldsEntity>(
    {}
  );
  const [mustacheFieldOptions, setMustacheOptions] = useState<OptionType[]>([]);

  const htmlEditorRef = useRef<any>();
  let mustacheEditorRef = useRef<any>();

  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const [activeTabIndex2, setActiveTabIndex2] = React.useState<number>(0);

  const updateHTMLEditorRef = (value: MutableRefObject<any>) => {
    htmlEditorRef.current = value;
  };
  const updateMustacheEditorRef = (value: any) => {
    mustacheEditorRef.current = value;
  };

  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  const loadMustacheOption = (module = 'opportunity') => {
    if (isEmpty(module) || isEmptyObject(mustacheFields)) {
      setMustacheOptions([]);
      return;
    }

    const fields = mustacheFields[module].fields;
    setMustacheOptions(
      Object.values(fields)
        .map((option) => {
          return {
            label: option.label,
            value: option.map
          };
        })
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        )
    );
  };

  const loadMustacheFields = async () => {
    const result = await emailTemplateService.getMustachFields();
    if (result.isSuccess) {
      setMustacheFields(result.data);
    }
  };

  const loadMustacheTemplate = async () => {
    const result = await emailTemplateService.convertToMustach(
      emailTemplate.id
    );

    if (result.isError) {
      toastError(result.errorMessage.message);
      setEmailTemplate((prevEmailTemplate: EmailTemplateEntity) => ({
        ...prevEmailTemplate,
        template_type: 'crm'
      }));
      return;
    }

    if (result.isSuccess) {
      setEmailTemplate((prevEmailTemplate: EmailTemplateEntity) => ({
        ...prevEmailTemplate,
        mustache_subject: result.data.subject
      }));

      setMustache((prevMustache) => ({
        ...prevMustache,
        html: result.data.body
      }));
    }
  };

  const loadCRMPreview = async () => {
    const result = await emailService.getEmailPreview({
      opportunity_id: content.opportunity.id,
      body_html: content.html
    });

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      setContent((prevContent) => ({
        ...prevContent,
        preview: result.data.preview
      }));
    }
  };

  const loadMustachePreview = async () => {
    const result = await emailTemplateService.getMustachePreView({
      opportunity_id: mustache.opportunity.id,
      mustache_body_html: mustache.html
    });

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      setMustache((prevMustache) => ({
        ...prevMustache,
        preview: result.data.preview
      }));
    }
  };

  const getInsertVariableOptions = async () => {
    const result = await opportunityService.getFields();

    setInsertVatiableOptions(
      Object.values(result.data.data).map((option: any) => {
        return { label: option.label, value: option.name };
      })
    );
  };

  const handleInsert = (): void => {
    if (isEmpty(emailTemplate.variable_name)) return;

    const editor = htmlEditorRef.current.editor;
    editor.insertContent(`$opportunity_${emailTemplate.variable_name} `);
  };

  const handleInsert2 = () => {
    if (isEmpty(emailTemplate.mustache_variable_name)) return;

    const editor = mustacheEditorRef.current.editor;
    editor.insertContent(`{{ ${emailTemplate.mustache_variable_name} }}`);
  };

  useEffect(() => {
    if (emailTemplate.template_type === 'mustache') {
      loadMustacheOption(emailTemplate.mustache_variable_module);
    }
  }, [emailTemplate.mustache_variable_module]);

  useEffect(() => {
    if (isEmpty(mustache.opportunity?.id)) return;
    loadMustachePreview();
  }, [mustache?.opportunity?.id]);

  useEffect(() => {
    if (isEmpty(content.opportunity?.id)) return;
    loadCRMPreview();
  }, [content?.opportunity?.id]);

  useEffect(() => {
    getInsertVariableOptions();
    loadMustacheFields();
  }, []);

  return (
    <FormContainer>
      {!market_preference_id && (
        <>
          <UnitText
            label={fieldLabel.name}
            name="name"
            value={emailTemplate.name ?? ''}
            onChange={handleChange}
            error={valMessages['name'] ?? ''}
            required
            readOnly={readOnly}
          />

          <UnitSelect
            name="related_event"
            label={fieldLabel.relatedEvent}
            records={getObjectEntriesAsArray(relatedEvent)}
            value={emailTemplate.related_event ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.description}
            name="description"
            value={emailTemplate.description ?? ''}
            onChange={handleChange}
            error={valMessages['description'] ?? ''}
            readOnly={readOnly}
          />

          <UnitText
            label={fieldLabel.subject}
            name="subject"
            value={
              emailTemplate.template_type === 'mustache'
                ? emailTemplate.mustache_subject
                : emailTemplate.subject ?? ''
            }
            onChange={(e: ChangeEvent) => {
              if (emailTemplate.template_type === 'mustache') {
                setEmailTemplate((prevEmailTemplate) => ({
                  ...prevEmailTemplate,
                  mustache_subject: e.target.value
                }));
              } else {
                setEmailTemplate((prevEmailTemplate) => ({
                  ...prevEmailTemplate,
                  subject: e.target.value
                }));
              }
            }}
            error={valMessages['subject'] ?? ''}
            readOnly={readOnly}
          />

          {readOnly && (
            <>
              <UnitText
                label={fieldLabel.dateModified}
                name="date_modified"
                value={
                  `${DateUtility.getDateTimeString(
                    emailTemplate.date_modified
                  )} by ${userFullName(emailTemplate.modified_user)}` ?? ''
                }
                onChange={emptyFunction}
                readOnly={readOnly}
              />

              <UnitText
                label={fieldLabel.dateCreated}
                name="date_entered"
                value={
                  `${DateUtility.getDateTimeString(
                    emailTemplate.date_entered
                  )} by ${userFullName(emailTemplate.created_by_user)}` ?? ''
                }
                onChange={emptyFunction}
                readOnly={readOnly}
              />
            </>
          )}

          {!isEmpty(emailTemplate.id) && (
            <UnitSelect
              name="template_type"
              label={fieldLabel.templateType}
              records={getObjectEntriesAsArray(emailTemplateTypes)}
              value={emailTemplate.template_type ?? ''}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === 'mustache') {
                  loadMustacheTemplate();
                }
              }}
              readOnly={readOnly}
              grid={{ xs: 4, sm: 5 }}
            />
          )}

          <UnitEmpty />

          <UnitItem
            grid={
              emailTemplate.template_type === 'mustache'
                ? { xs: 12, sm: 6 }
                : { xs: 12, sm: 12 }
            }
            p={1}
          >
            <FormContainer>
              {isCreate && (
                <>
                  <UnitSelect
                    name="variable_module"
                    label={fieldLabel.insertVariable}
                    records={getObjectEntriesAsArray({ closing: 'Closing' })}
                    value={'closing'}
                    onChange={handleChange}
                    readOnly={readOnly}
                    disabled={true}
                    grid={{ xs: 4, sm: 3 }}
                  />

                  <UnitSelect
                    name="variable_name"
                    label=""
                    records={insertVatiableOptions}
                    value={emailTemplate.variable_name ?? ''}
                    onChange={handleChange}
                    readOnly={readOnly}
                    grid={{ xs: 4, sm: 3 }}
                    sx={{ pt: 3 }}
                  />
                  <StackRow sx={{ pt: 5, pb: 0 }}>
                    <InsertButton
                      onClick={() => {
                        handleInsert();
                      }}
                    />
                  </StackRow>
                </>
              )}

              {!isEmpty(emailTemplate.id) && isCreate && (
                <UnitItem
                  grid={{
                    xs: 12,
                    sm: 12
                  }}
                  p={0}
                >
                  <HorizontalTabs
                    value={activeTabIndex}
                    handleChange={(e: any, newValue: number) => {
                      setActiveTabIndex(newValue);
                    }}
                    tabItems={{
                      crmTemplate: 'CRM Template',
                      preview: 'Preview'
                    }}
                    tabId={tabId}
                  />
                </UnitItem>
              )}

              <UnitItem
                grid={{
                  xs: 12,
                  sm: 12
                }}
                sx={{ pl: 2, pt: 0 }}
              >
                <TabArea
                  index={0}
                  border={false}
                  tabId={tabId}
                  value={activeTabIndex}
                >
                  <UnitHtmlEditor
                    key="1"
                    grid={{ xs: 12, sm: 12 }}
                    value={readOnly ? emailTemplate.body_html : content.html}
                    readOnly={readOnly}
                    theme={theme}
                    onChange={(emailTemplate: ObjectType) => {
                      if (setContent !== undefined) {
                        setContent({
                          html: emailTemplate.html
                        });
                      }
                    }}
                    label="CRM Template"
                    updateHTMLEditorRef={updateHTMLEditorRef}
                    pt={0}
                  />
                </TabArea>
                <TabArea
                  index={1}
                  border={false}
                  tabId={tabId}
                  value={activeTabIndex}
                >
                  <FormContainer>
                    <UnitOpportunitySelect
                      value={{
                        label: content.opportunity?.name ?? '',
                        value: content.opportunity?.id ?? ''
                      }}
                      label="Opportunity"
                      multiple={false}
                      onChange={(val: OptionType) => {
                        setContent((prevContent) => ({
                          ...prevContent,
                          opportunity: {
                            id: val?.value ?? '',
                            name: val?.label ?? ''
                          }
                        }));
                        setValidation((preValidation) => ({
                          ...preValidation,
                          crm_opportunity: isEmpty(val?.value)
                            ? ['Please select opportunity']
                            : []
                        }));
                      }}
                      error={valMessages['crm_opportunity'] ?? ''}
                    />
                    {!isEmpty(content.opportunity?.id) && (
                      <UnitHtmlEditor
                        grid={{ xs: 12, sm: 12 }}
                        value={content.preview}
                        theme={'material'}
                        onChange={emptyFunction}
                        readOnly={true}
                        label=""
                        height="100vh"
                        pt={1}
                        pb={1}
                      />
                    )}
                  </FormContainer>
                </TabArea>
              </UnitItem>
            </FormContainer>
          </UnitItem>

          {emailTemplate.template_type === 'mustache' && (
            <UnitItem>
              <FormContainer>
                {isCreate && (
                  <>
                    <UnitSelect
                      name="mustache_variable_module"
                      label={fieldLabel.insertVariable}
                      records={Object.values(mustacheFields).map((x) => ({
                        label: x.label,
                        value: x.key
                      }))}
                      value={emailTemplate.mustache_variable_module}
                      onChange={handleChange}
                      readOnly={readOnly}
                      disabled={false}
                      grid={{ xs: 4, sm: 3 }}
                    />

                    <UnitSelect
                      name="mustache_variable_name"
                      label=""
                      records={mustacheFieldOptions}
                      value={emailTemplate.mustache_variable_name ?? ''}
                      onChange={handleChange}
                      readOnly={readOnly}
                      grid={{ xs: 4, sm: 3 }}
                      sx={{ pt: 3 }}
                      hasBlankOption={false}
                    />

                    {emailTemplate.template_type === 'mustache' && (
                      <StackRow sx={{ pt: 5, pb: 0 }}>
                        <InsertButton
                          onClick={() => {
                            handleInsert2();
                          }}
                        />
                      </StackRow>
                    )}
                  </>
                )}

                {!isEmpty(emailTemplate.id) && isCreate && (
                  <UnitItem
                    grid={{
                      xs: 12,
                      sm: 12
                    }}
                    p={0}
                  >
                    <HorizontalTabs
                      value={activeTabIndex2}
                      handleChange={(e: any, newValue: number) => {
                        setActiveTabIndex2(newValue);
                      }}
                      tabItems={{
                        mustacheTemplate: 'Mustache Template',
                        preview: 'Preview'
                      }}
                      tabId={tabId2}
                    />
                  </UnitItem>
                )}

                <UnitItem
                  grid={{
                    xs: 12,
                    sm: 12
                  }}
                  sx={{ pl: 2, pt: 0 }}
                >
                  <TabArea
                    index={0}
                    border={false}
                    tabId={tabId2}
                    value={activeTabIndex2}
                  >
                    {readOnly ? (
                      <UnitHtmlEditor
                        key={'2'}
                        grid={{ xs: 12, sm: 12 }}
                        value={emailTemplate.mustache_body_html}
                        readOnly={readOnly}
                        theme={theme}
                        onChange={emptyFunction}
                        label="Mustache Template"
                        pt={0}
                      />
                    ) : (
                      <UnitHtmlEditor
                        key={'3'}
                        grid={{ xs: 12, sm: 12 }}
                        value={mustache.html}
                        readOnly={readOnly}
                        theme={theme}
                        onChange={(mustacheContent: ObjectType) => {
                          if (setMustache !== undefined)
                            setMustache(mustacheContent);
                        }}
                        label="Mustache Template"
                        updateHTMLEditorRef={updateMustacheEditorRef}
                        pt={0}
                      />
                    )}
                  </TabArea>
                  <TabArea
                    index={1}
                    border={false}
                    tabId={tabId2}
                    value={activeTabIndex2}
                  >
                    <FormContainer>
                      <UnitOpportunitySelect
                        value={{
                          label: mustache.opportunity?.name ?? '',
                          value: mustache.opportunity?.id ?? ''
                        }}
                        label="Opportunity"
                        multiple={false}
                        onChange={(val: OptionType) => {
                          setMustache((prevMustache) => ({
                            ...prevMustache,
                            opportunity: {
                              id: val?.value ?? '',
                              name: val?.label ?? ''
                            }
                          }));
                          setValidation((preValidation) => ({
                            ...preValidation,
                            mustache_opportunity: isEmpty(val?.value)
                              ? ['Please select opportunity']
                              : []
                          }));
                        }}
                        error={valMessages['mustache_opportunity'] ?? ''}
                      />

                      {!isEmpty(mustache.opportunity?.id) && (
                        <UnitHtmlEditor
                          grid={{ xs: 12, sm: 12 }}
                          value={mustache.preview}
                          theme={'material'}
                          onChange={emptyFunction}
                          readOnly={true}
                          label=""
                          height="100vh"
                          pt={1}
                          pb={1}
                        />
                      )}
                    </FormContainer>
                  </TabArea>
                </UnitItem>
              </FormContainer>
            </UnitItem>
          )}
        </>
      )}
      {market_preference_id && (
        <>
          <UnitSelect
            name="email_inspection"
            label={fieldLabel.emailInspection}
            records={getObjectEntriesAsArray(emailInspection)}
            value={emailTemplate.email_inspection ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
          />
        </>
      )}
    </FormContainer>
  );
};

export default RecordView;
