import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.market);

    if (['msa', 'msa-create'].includes(routeName)) {
      return modelMenu[appPermission.list];
    }

    if (
      [
        'msa-view',
        'msa-accounts',
        'msa-account-selection',
        'msa-change-log',
        'msa-accounts-view'
      ].includes(routeName)
    ) {
      return modelMenu[appPermission.view];
    }

    if (routeName === 'msa-edit') {
      return modelMenu[appPermission.edit];
    }

    return [];
  }
};

export default sideManuHelper;
