import fieldLabel from 'assets/constants/fieldLabel';
import specialInstructions from 'assets/constants/special-instructions';
import reductionType from 'assets/reduction-type';
import CopyToClipboard from 'components/copy-to-clipboard';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import RequiredFieldAsterisk from 'components/form/required-field-asterisk';
import UnitDate from 'components/form/unit-date';
import UnitItem from 'components/form/unit-item';
import UnitPercentageFormatter from 'components/form/unit-percentage-formatter';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import TooltipQuestionGuide from 'components/tooltip/tooltip-question-guide';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { refreshOpportunity } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';
import FeeAdvance from 'features/opportunity/partial-edit/fee-advance';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import opportunityHelper from 'helpers/opportunity-helper';
import { getFilterString } from 'helpers/query-string-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import accountBuyerNameService from 'services/account-buyer-name-service';
import accountBuyerService from 'services/account-buyer-service';
import brokerageActionService from 'services/brokerage-action-service';
import opportunityServiceProvidersService from 'services/opportunity-service-providers';
import { validation as validationService } from 'services/validation-service';
import initialEnterClosedPurchased from 'state/brokerage-actions/enter-closed-purchased';
import { ObjectType } from 'types';
import {
  ActionRecordViewPropTypes,
  EnterClosedPurchasedEntity
} from 'types/brokerage-action-types';
import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { OpportunityServiceProviderEntity } from 'types/opportunity-service-provider-types';
import { OptionType } from 'types/option-type';

import {
  getInitialState,
  getValidationFields,
  prepareRequestBody
} from './helper';
import leaseType from 'assets/constants/lease-type';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const { updateOpportunity } = useContext(OpportunityContext);

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [feeAdvances, setFeeAdvances] = useState<
    OpportunityServiceProviderEntity[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buyerContract, setBuyerContract] = useState<OptionType[]>([]);
  const [data, setData] = useState<EnterClosedPurchasedEntity>(
    initialEnterClosedPurchased
  );

  const [field, setField] = useState<string>('');

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
    } else {
      setData(Object.assign({}, data, e));
    }
  };

  const isLeaseTypeEmpty = isEmpty(data.lease_type);

  const validateData = () => {
    const fieldToValidate = getValidationFields();
    if (!isLeaseTypeEmpty) {
      fieldToValidate.push('deposit_amount', 'rental_amount');
    }

    const { isValid, errors } = validateBrokerageAction(
      data,
      fieldToValidate,
      opportunity
    );

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody = prepareRequestBody(data);

    setIsLoading(true);

    const result =
      await brokerageActionService.update<EnterClosedPurchasedEntity>(
        opportunity.id,
        requestBody,
        'enter_closed_purchased'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.enter_sale_pending();
      } else {
        refreshOpportunity();
        navigate(`/opportunities/${opportunity.id}/view`);
      }
    }
  };

  const performAfterAffect = async () => {
    if (validationService[field]?.handleChange) {
      const result = await validationService[field]?.handleChange(data);

      setData((prevData) => ({
        ...prevData,
        ...result
      }));
    }

    setField('none');
  };

  const loadAccountBuyerName = async () => {
    let query = getFilterString({
      account_buyer_id: data.account_buyer_id,
      account_id: data.account_id_1,
      entera_customer_id: data.entera_customer_id,
      market_id: data.msa_id_1,
      entera_market_id: data.entera_market_id
    });

    const result =
      await accountBuyerNameService.getAccountBuyerNameByOpportunity(query);
    if (result.isSuccess) {
      const accountBuyerNameList = result.data.data;
      const buyerContractOptions: OptionType[] = [];
      for (const accountBuyerName of accountBuyerNameList) {
        buyerContractOptions.push({
          label: accountBuyerName.name,
          value: accountBuyerName.id
        });
      }

      if (!isEmpty(data?.account_buyer_id)) {
        const currentAccountBuyer = buyerContractOptions.find(
          (x: OptionType) => x.value === data.account_buyer_id
        );

        handleChange({
          account_buyer_name: currentAccountBuyer?.label || ''
        });
      }
      setBuyerContract(buyerContractOptions);
    }
  };

  const loadAdditionalData = async () => {
    await loadAccountBuyerName();
    const accountBuyers = await accountBuyerService.getOpportunityAccountBuyers(
      opportunity.id
    );
    if (accountBuyers && accountBuyers.length > 0) {
      const buyers = accountBuyerService.getBuyers(accountBuyers);
      setData((prevData) => ({ ...prevData, ...buyers }));
    }

    const opportunityServiceProviders =
      await opportunityServiceProvidersService.getList(
        `?sort[field]=date_entered&sort[direction]=asc&filter[opportunity_id]=${opportunity.id}`
      );

    const feeAdvances = opportunityServiceProvidersService.getFeeAdvances(
      opportunityServiceProviders.data.data
    );
    setFeeAdvances(feeAdvances);
  };

  const getCopyToClipboardValueForSelect = (field: string): string => {
    const value = buyerContract.find((x: ObjectType) => x.value === field);
    return (value && value.label) ?? '';
  };

  const getValidDate = (date: string, field: string, label: string) => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        label,
        field === 'close_date_c'
          ? true
          : opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
              data?.option_days_type_c
            )
      );

    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}_federal_holiday_validation_message`]: message
    }));

    return DateUtility.getFormattedDateString(validDate);
  };

  useEffect(() => {
    if (field !== '') {
      performAfterAffect();
    }
  }, [field]);

  useEffect(() => {
    if (!loadingOpportunity) {
      setData(getInitialState(opportunity));
    }
  }, [loadingOpportunity]);

  useEffect(() => {
    if (!isEmpty(data.id)) {
      loadAdditionalData();
    }
  }, [data.id]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 48vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitSelect
              label={fieldLabel.buyerContractName}
              name="account_buyer_id"
              value={data?.account_buyer_id ?? ''}
              onChange={(e) => {
                const accountName = buyerContract.find(
                  (x: ObjectType) => x.value === e.target.value
                );
                handleChange({
                  account_buyer_id: e.target.value,
                  account_buyer_name: accountName?.label || ''
                });
              }}
              records={buyerContract}
              required
              error={validation['account_buyer_id'] ?? []}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    value={getCopyToClipboardValueForSelect(
                      data.account_buyer_id ?? ''
                    )}
                  />
                )
              }}
            />

            <UnitText
              label={fieldLabel.buyerContractNameAtDiligenceStart}
              name="buyer_contract_name_at_diligence_start_name"
              value={data?.buyer_contract_name_at_diligence_start_name ?? ''}
              onChange={handleChange}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    value={
                      data.buyer_contract_name_at_diligence_start_name ?? ''
                    }
                  />
                )
              }}
            />

            <UnitSelect
              label={fieldLabel.buyerNameHud}
              name="buyer_name_prelim_hud_id"
              value={data?.buyer_name_prelim_hud_id ?? ''}
              onChange={handleChange}
              records={buyerContract}
              // readOnly={true}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    value={getCopyToClipboardValueForSelect(
                      data.buyer_name_prelim_hud_id ?? ''
                    )}
                  />
                )
              }}
            />

            <UnitSelect
              label={fieldLabel.buyerContractNameAtClosing}
              name="buyer_contract_name_at_closing_id"
              value={data?.buyer_contract_name_at_closing_id ?? ''}
              onChange={handleChange}
              records={buyerContract}
              readOnly={false}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    value={getCopyToClipboardValueForSelect(
                      data?.buyer_contract_name_at_closing_id ?? ''
                    )}
                  />
                )
              }}
            />

            <UnitDate
              label={fieldLabel.closeDate}
              name="close_date_c"
              value={data.close_date_c ?? ''}
              onChange={(value: string) => {
                setData((prevData) => ({
                  ...prevData,
                  close_date_c: getValidDate(
                    value,
                    'close_date_c',
                    fieldLabel.closeDate
                  )
                }));
              }}
              error={validation['close_date_c'] ?? ''}
            />

            <UnitPriceFormatter
              label={`${fieldLabel.purchasePrice}`}
              name="purchase_price_c"
              value={data.purchase_price_c}
              onChange={handleChange}
              onBlur={() => {
                setField('purchase_price_c');
              }}
              error={validation['purchase_price_c'] ?? ''}
              required
            />

            <UnitPercentageFormatter
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.initialCommissionPercentage}
                    <TooltipQuestionGuide
                      data={fieldLabel.initialcommissionAmountPercentageFormula}
                      width="30vw"
                      height="7vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              name="initial_commission_percentage"
              value={data.initial_commission_percentage}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  initial_commission_percentage: e.target.value,
                  initial_commission_percentage_changed: true
                }));
              }}
              onBlur={() => {
                setField('initial_commission_percentage');
              }}
              readOnly
            />

            <UnitPriceFormatter
              name="initial_commission_amount"
              value={data.initial_commission_amount}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  initial_commission_amount: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  initial_commission_amount_changed: true
                }));
              }}
              onBlur={() => {
                setField('initial_commission_amount');
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.initialCommissionDollar}
                    <RequiredFieldAsterisk />
                    <TooltipQuestionGuide
                      data={fieldLabel.initialcommissionAmountFormula}
                      width="30vw"
                      height="7vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              error={validation['initial_commission_amount']}
              readOnly
            />

            <UnitPriceFormatter
              name="final_commission"
              value={data?.final_commission}
              readOnly
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  final_commission: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  final_commission_changed: true
                }));
              }}
              onBlur={() => {
                setField('final_commission');
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.actualCommission} ${' '}
                    <TooltipQuestionGuide
                      data={fieldLabel.finalCommissionFormula}
                      width="30vw"
                      height="4vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
              error={validation['final_commission']}
            />

            <UnitPriceFormatter
              name="hud_commission"
              value={data?.hud_commission}
              readOnly
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  hud_commission: e.target.value,
                  hud_commission_changed: true
                }));
              }}
              label={
                <FormContainer spacing={0}>
                  <UnitItem sx={{ display: 'flex' }} grid={{ xs: 12, sm: 12 }}>
                    {fieldLabel.hudCommission} ${' '}
                    <TooltipQuestionGuide
                      data={fieldLabel.hudCommissionFormula}
                      width="30vw"
                      height="13vh"
                    />
                  </UnitItem>
                </FormContainer>
              }
            />

            <UnitPercentageFormatter
              name="final_commission_percentage"
              value={data?.final_commission_percentage}
              readOnly
              label={`${fieldLabel.actualCommissionPercentage} %`}
              onChange={(e: any) => {
                setData((prev) => ({
                  ...prev,
                  final_commission_percentage: !isEmpty(e.target.value)
                    ? e.target.value
                    : null,
                  final_commission_percentage_changed: true
                }));
              }}
            />

            <UnitPriceFormatter
              label={fieldLabel.commissionPending}
              name="commision_pending"
              value={data.commision_pending}
              onChange={handleChange}
              required
              error={validation['commision_pending']}
              onBlur={() => {
                setField('commision_pending');
              }}
            />

            <UnitText
              label={`${fieldLabel.pendingCommission} %`}
              name="buyer_commission_c"
              value={data.buyer_commission_c}
              onChange={handleChange}
              required
              error={validation['buyer_commission_c'] ?? ''}
            />
            <UnitSelect
              name="special_instructions"
              label={fieldLabel.specialInstructions}
              records={getObjectEntriesAsArray(specialInstructions)}
              value={data.special_instructions ?? ''}
              onChange={handleChange}
            />

            <UnitSelect
              name="reduction_type"
              label={fieldLabel.reductionType}
              records={getObjectEntriesAsArray(reductionType)}
              value={data.reduction_type ?? ''}
              onChange={(e) => {
                handleChange(e);
                setField('reduction_type');
              }}
              required={!isEmpty(data.commision_reduction)}
              error={validation['reduction_type']}
            />

            <UnitPriceFormatter
              name="cda_amount_2"
              value={data.cda_amount_2}
              onChange={handleChange}
              label={fieldLabel.CdaAmount2}
            />

            <UnitPriceFormatter
              name="cda_amount_3"
              value={data.cda_amount_3}
              onChange={handleChange}
              label={fieldLabel.CsaAmount3}
            />

            <UnitText
              label={fieldLabel.CdaPayTo2}
              name="cda_pay_to_2"
              value={data.cda_pay_to_2}
              onChange={handleChange}
            />

            <UnitText
              label={fieldLabel.CdaPayTo3}
              name="cda_pay_to_3"
              value={data.cda_pay_to_3}
              onChange={handleChange}
            />

            <UnitSelect
              name="lease_type"
              label={fieldLabel.leaseType}
              records={getObjectEntriesAsArray(leaseType)}
              value={data.lease_type ?? ''}
              onChange={handleChange}
              error={validation['lease_type'] ?? ''}
            />
            <UnitPriceFormatter
              label={fieldLabel.depositAmount}
              name="deposit_amount"
              value={data.deposit_amount ?? ''}
              onChange={handleChange}
              required={!isLeaseTypeEmpty}
              error={validation['deposit_amount'] ?? ''}
            />

            <UnitPriceFormatter
              name="rental_amount"
              value={data?.rental_amount ?? ''}
              onChange={handleChange}
              label={fieldLabel.rentalAmount}
              required={!isLeaseTypeEmpty}
              error={validation['rental_amount'] ?? ''}
            />
            <UnitItem>
              <FeeAdvance
                opportunity={{ ...opportunity, feeAdvances }}
                updateOpportunity={updateOpportunity}
                isBrokerageAction
              />
            </UnitItem>
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <UnitItem grid={{ xs: 12, sm: 2 }}>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
            </UnitItem>
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
