import Box from '@mui/material/Box';
import CircularLoader from 'components/dog-loader/dog-lodar';
import ErrorComponent from 'components/errors/error-component';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import envConfig from 'config/env';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import marketPreferenceService from 'services/market-preference-service';
import pdfTemplateService from 'services/pdf-template-service';
import initialPdfTemplate from 'state/contracts/initial-pdf-template';
import { PdfTemplateEntity } from 'types/pdf-template-types';

const PdfTemplateViewAsPDF = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { pdf_template_id, market_preference_id } = useParams<{
    pdf_template_id: string;
    market_preference_id: string;
  }>();
  const { setRouteName } = useRouteName();
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfTemplate, setPdfTemplate] =
    useState<PdfTemplateEntity>(initialPdfTemplate);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadPdfTemplate = async (pdf_template_id: string): Promise<void> => {
    setLoading(true);
    let response;
    if (market_preference_id) {
      response = await marketPreferenceService.getAssociatedPDFTemplate(
        market_preference_id!,
        pdf_template_id
      );
    } else {
      response = await pdfTemplateService.get(pdf_template_id);
    }

    if (response.isError) {
      setLoading(false);
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setPdfTemplate(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (pdf_template_id) loadPdfTemplate(pdf_template_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      {!loading ? (
        <PaperBox>
          <link
            rel="stylesheet"
            href={`${envConfig.REACT_APP_CRM_URL}/custom/modules/AOS_PDF_Templates/css/preview-doc.css`}
          />
          <PaperBoxContent>
            {isEmpty(errorMessage) ? (
              <>
                <Box
                  p={1}
                  dangerouslySetInnerHTML={{
                    __html: pdfTemplate.description
                  }}
                ></Box>
              </>
            ) : (
              <ErrorComponent message={errorMessage} />
            )}
          </PaperBoxContent>
        </PaperBox>
      ) : (
        <CircularLoader />
      )}
    </>
  );
};

export default PdfTemplateViewAsPDF;
