// @ts-nocheck
import accountMenuItems from 'assets/menus/side-menu/account';
import callMenuItems from 'assets/menus/side-menu/call';
import dashboardMenuItems from 'assets/menus/side-menu/dashboard';
import documentMenuItems from 'assets/menus/side-menu/document';
import emailMenuItems from 'assets/menus/side-menu/email';
import emailTemplateMenuItems from 'assets/menus/side-menu/email-template';
import kbCategoriesMenuItems from 'assets/menus/side-menu/kb-categories';
import knowledgeBaseMenuItems from 'assets/menus/side-menu/knowledge-base';
import marketPreferenceBrokerageUserMenuItems from 'assets/menus/side-menu/market-preference-brokerage-user';
import marketPreferenceMenuItems from 'assets/menus/side-menu/market-preference';
import msaMenuItems from 'assets/menus/side-menu/msa';
import opportunityMenuItems from 'assets/menus/side-menu/opportunity';
import opportunityBrokerageUserMenuItems from 'assets/menus/side-menu/opportunity-brokerage-user';
import partyMenuItems from 'assets/menus/side-menu/party';
import pdfTemplateMenuItems from 'assets/menus/side-menu/pdf-template';
import serviceMenuItems from 'assets/menus/side-menu/service';
import sqsMenuItems from 'assets/menus/side-menu/sqs';
import taskMenuItems from 'assets/menus/side-menu/task';
import transactionHistoryMenuItems from 'assets/menus/side-menu/transaction-history';
import brokerageTransactionRoleMenuItems from 'assets/menus/side-menu/brokerage-transaction-roles';
import { ObjectType } from 'types';
import federalHolidayCalendarMenuItems from 'assets/menus/side-menu/federal-holiday-calendar';
import accountBuyersNameMenuItems from 'assets/menus/side-menu/account-buyer-name';

const menuHelper = {
  getItems: async (model, page: any) => {
    let modelMenu = menuHelper.getModelMenuItems(model);

    return modelMenu[page];
  },

  createMenusItems: (menus, params) => {
    let menusToshow = menus.map(function (item) {
      let subMenuItemsWithURL;

      if (item.subMenuItems && item.subMenuItems.length > 0) {
        subMenuItemsWithURL = item.subMenuItems.map(function (
          subMenuItem: ObjectType
        ) {
          return {
            ...subMenuItem,
            url:
              typeof subMenuItem.url === 'function'
                ? subMenuItem.url(params)
                : ''
          };
        });
      }

      return {
        ...item,
        subMenuItems: subMenuItemsWithURL,
        url: typeof item.url === 'function' ? item.url(params) : ''
      };
    });
    return menusToshow;
  },

  getModelMenuItems(model: string): ObjectType {
    switch (model) {
      case 'Accounts':
        return accountMenuItems;
      case 'simba_MSA':
        return msaMenuItems;
      case 'simba_Property':
        return marketPreferenceMenuItems;
      case 'Market_Preferences_Brokerage_User':
        return marketPreferenceBrokerageUserMenuItems;
      case 'AOS_PDF_Templates':
        return pdfTemplateMenuItems;
      case 'dashboard':
        return dashboardMenuItems;
      case 'Opportunities':
        return opportunityMenuItems;
      case 'Documents':
        return documentMenuItems;
      case 'Email_Template':
        return emailTemplateMenuItems;
      case 'parties':
        return partyMenuItems;
      case 'SQS':
        return sqsMenuItems;
      case 'OPPORTUNITY_BROKERAGE_USER':
        return opportunityBrokerageUserMenuItems;
      case 'Tasks':
        return taskMenuItems;
      case 'Calls':
        return callMenuItems;
      case 'SERVICE':
        return serviceMenuItems;
      case 'TRANSACTION_HISTORY':
        return transactionHistoryMenuItems;
      case 'Emails':
        return emailMenuItems;
      case 'Knowledge_Base':
        return knowledgeBaseMenuItems;
      case 'Knowledge_Base_Category':
        return kbCategoriesMenuItems;
      case 'Brokerage_Transaction_Roles':
        return brokerageTransactionRoleMenuItems;
      case 'Federal_Holiday_Calendars':
        return federalHolidayCalendarMenuItems;
      case 'Account_Buyer_Name':
        return accountBuyersNameMenuItems;
    }

    return {};
  }
};

export default menuHelper;
