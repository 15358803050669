import {
  ActionRecordViewPropTypes,
  EnterDueDiligenceEntity,
  EnterDueDiligencePayloadEntity
} from 'types/brokerage-action-types';
import {
  getInitialState,
  prepareRequestBody,
  getValidationFields
} from './helper';
import { balance_to_close_c } from 'services/validation/balance_to_close_c';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { ChangeEvent, InputChangeEvent } from 'types/common-types';
import { due_diligence_end_c } from 'services/validation/due_diligence_end_c';
import { ErrorValidation } from 'types/error-types';
import { isEmpty } from 'helpers/misc-helper';
import { refreshOpportunity } from 'event/opportunity-event';
import { useNavigate } from 'react-router-dom';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import { validation as validationService } from 'services/validation-service';
import accessType from 'assets/constants/access-type';
import brokerageActionService from 'services/brokerage-action-service';
import DateUtility from 'helpers/date-helper';
import dueDiligenceDaysConfirm from 'assets/constants/due-diligence-days-confirm';
import emptyFunction from 'helpers/empty-function-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import hasPostOccupancy from 'assets/constants/has-post-occupancy';
import hoaAddendumReceived from 'assets/constants/hoa-addendum-received';
import InfoMessage from 'components/errors/info-component';
import initialEnterDueDiligence from 'state/brokerage-actions/enter-due-diligence';
import leaseType from 'assets/constants/lease-type';
import moment from 'moment-timezone';
import opportunityHelper from 'helpers/opportunity-helper';
import optionDaysType from 'assets/constants/option-days-type';
import optionFeeVerified from 'assets/constants/option-fee-verified';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import partyService from 'services/party-service';
import React, { useEffect, useState } from 'react';
import RouteCreateIconLink from 'components/link/route-create-icon-link';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import SaveButton from 'components/form/button-save';
import StackRow from 'components/stack/stack-row';
import UnitDate from 'components/form/unit-date';
import UnitDateTimePicker from 'components/form/unit-date-time';
import UnitEmpty from 'components/form/unit-empty';
import UnitHeading from 'components/form/unit-heading';
import UnitItem from 'components/form/unit-item';
import UnitParty from 'components/form/unit-party';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch, { isChecked } from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import variableConfig from 'config/variable';
import { PartyListEntity } from 'types/party-types';
import { OptionType } from 'types/option-type';
import { toastError } from 'event/toast-event';
import occupancyStatus from 'assets/constants/occupancy-status';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnterDueDiligenceEntity>(
    initialEnterDueDiligence
  );
  const [field, setField] = useState<string>('');

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const changeHOA = (e: ChangeEvent) => {
    setData((prevData) => ({
      ...prevData,
      hoa_management_company: {
        ...prevData.hoa_management_company,
        [e.target.name]: e.target.value
      }
    }));
  };

  const changeUtilityProvider = (e: ChangeEvent) => {
    setData((prevData) => ({
      ...prevData,
      property_utility_provider: {
        ...prevData.property_utility_provider,
        [e.target.name]: e.target.value
      }
    }));
  };

  const validateData = () => {
    const fieldToValidate = getValidationFields();

    const { isValid, errors } = validateBrokerageAction(data, fieldToValidate);

    if (!isChecked(data.agree_switch)) {
      errors['agree_switch'] = ['Required Field'];
    }

    setValidation(errors);

    return isValid && isChecked(data.agree_switch);
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody = prepareRequestBody(data);

    setIsLoading(true);

    const result =
      await brokerageActionService.update<EnterDueDiligencePayloadEntity>(
        opportunity.id,
        requestBody,
        'enter_due_diligence'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.due_diligence_opportunity();
      } else {
        refreshOpportunity();
        navigate(`/opportunities/${opportunity.id}/view`);
      }
    }
  };

  const getValidDate = (date: string, field: string, label: string): string => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        label,
        field === 'close_date_c'
          ? true
          : opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
              data.option_days_type_c
            )
      );

    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}_federal_holiday_validation_message`]: message
    }));

    return DateUtility.getFormattedDateString(validDate);
  };

  const updateDueDiligenceEndDate = () => {
    let ddEndDate = due_diligence_end_c.calculate(data, {});
    ddEndDate = getValidDate(
      ddEndDate,
      'due_diligence_end_c',
      fieldLabel.dueDiligenceEndDate
    );

    ddEndDate = DateUtility.addHourInDate(
      ddEndDate,
      variableConfig.DUE_DILIGENCE_END_START_TIME
    );

    setData((prevData) => ({ ...prevData, due_diligence_end_c: ddEndDate }));
  };

  const loadParties = async () => {
    const partyList = await partyService.getList(
      `?page=1&filter[opportunity_id]=${opportunity.id}`
    );

    setData(getInitialState(opportunity, partyList.data.data));
  };

  useEffect(() => {
    updateDueDiligenceEndDate();
  }, [
    data.contract_execution_date_c,
    data.option_days_type_c,
    data.option_period_days_c
  ]);
  const updateCloseBalance = () => {
    const closeBalance = balance_to_close_c.calculate(data, {});
    setData((prevData) => ({
      ...prevData,
      balance_to_close_c: closeBalance
    }));
  };

  const performAfterAffect = async () => {
    if (validationService[field]?.handleChange) {
      const result = await validationService[field]?.handleChange(data);

      setData((prevData) => ({
        ...prevData,
        ...result
      }));
    }

    setField('none');
  };

  useEffect(() => {
    updateCloseBalance();
  }, [data.earnest_amount_c, data.offer_price_c]);

  useEffect(() => {
    if (field !== '') {
      performAfterAffect();
    }
  }, [field]);

  useEffect(() => {
    if (!loadingOpportunity) {
      loadParties();
    }
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 48vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitHeading title="Contract" />

            <UnitDate
              label={fieldLabel.contractExecutionDate}
              name="contract_execution_date_c"
              value={data.contract_execution_date_c ?? ''}
              onChange={(e: string) => {
                handleChange({
                  target: { name: 'contract_execution_date_c', value: e }
                });
                setField('contract_execution_date_c');
              }}
              error={validation['contract_execution_date_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={`${fieldLabel.contractPrice}`}
              name="contract_price_c"
              value={data.contract_price_c ?? ''}
              onChange={handleChange}
              error={validation['contract_price_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={`${fieldLabel.offerPrice}`}
              name="offer_price_c"
              value={data.offer_price_c ?? ''}
              onChange={handleChange}
              error={validation['offer_price_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.closeBalance}
              name="balance_to_close_c"
              value={data.balance_to_close_c ?? ''}
              onChange={handleChange}
              error={validation['balance_to_close_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={fieldLabel.escalationClauseAmount}
              name="escalation_clause_amount"
              value={data.escalation_clause_amount ?? ''}
              onChange={handleChange}
              error={validation['escalation_clause_amount'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="occupancy_status_c"
              label={fieldLabel.occupancyStatus}
              records={getObjectEntriesAsArray(occupancyStatus)}
              value={data.occupancy_status_c ?? ''}
              onChange={handleChange}
              error={validation['occupancy_status_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.inclusions}
              name="inclusion"
              value={data.inclusion ?? ''}
              onChange={handleChange}
              error={validation['inclusion'] ?? ''}
              required
              multiline
              rows={4}
            />

            <UnitText
              label={fieldLabel.exclusions}
              name="exclusion"
              value={data.exclusion ?? ''}
              onChange={handleChange}
              error={validation['exclusion'] ?? ''}
              required
              multiline
              rows={4}
            />

            <UnitHeading title="Due Diligence" />

            <UnitSelect
              name="due_diligence_days_confirm"
              label={fieldLabel.dueDiligenceDaysConfirm}
              records={getObjectEntriesAsArray(dueDiligenceDaysConfirm)}
              value={data.due_diligence_days_confirm ?? ''}
              onChange={handleChange}
              required
              error={validation['due_diligence_days_confirm'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitDateTimePicker
              label={fieldLabel.dueDiligenceStartDate}
              name="due_diligence_start_c"
              value={data.due_diligence_start_c || null}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'due_diligence_start_c', value: e }
                })
              }
              required
              error={validation['due_diligence_start_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitItem grid={{ xs: 12, sm: 4 }}>
              <UnitDateTimePicker
                label={fieldLabel.dueDiligenceEndDate}
                name="due_diligence_end_c"
                value={data.due_diligence_end_c ?? ''}
                onChange={(value: string) => {
                  const validDate = value
                    ? getValidDate(
                        value,
                        'due_diligence_end_c',
                        fieldLabel.dueDiligenceEndDate
                      )
                    : '';

                  setData((prevData) => ({
                    ...prevData,
                    due_diligence_end_c: !isEmpty(validDate)
                      ? DateUtility.addHours(
                          moment(validDate),
                          variableConfig.DUE_DILIGENCE_END_START_TIME
                        )
                      : ''
                  }));
                  setField('due_diligence_end_c');
                }}
                required
                error={validation['due_diligence_end_c'] ?? ''}
                grid={{ xs: 12, sm: 12 }}
              />
              {!isEmpty(
                validation?.due_diligence_end_c_federal_holiday_validation_message
              ) && (
                <InfoMessage
                  message={
                    validation.due_diligence_end_c_federal_holiday_validation_message ??
                    ''
                  }
                />
              )}
            </UnitItem>

            <UnitItem grid={{ xs: 12, sm: 4 }}>
              <UnitDate
                label={fieldLabel.closeDate}
                name="close_date_c"
                value={data.close_date_c ?? ''}
                onChange={(value: string) => {
                  setData((prevData) => ({
                    ...prevData,
                    close_date_c:
                      value ??
                      getValidDate(value, 'close_date_c', fieldLabel.closeDate)
                  }));
                }}
                required
                error={validation['close_date_c'] ?? ''}
                grid={{ xs: 12, sm: 12 }}
              />
              {!isEmpty(
                validation?.close_date_c_federal_holiday_validation_message
              ) && (
                <InfoMessage
                  message={
                    validation.close_date_c_federal_holiday_validation_message ??
                    ''
                  }
                />
              )}
            </UnitItem>

            <UnitText
              label={fieldLabel.optionPeriod}
              name="option_period_days_c"
              value={data.option_period_days_c ?? ''}
              onChange={(e: ChangeEvent) => {
                handleChange(e);
                setField('option_period_days_c');
              }}
              error={validation['option_period_days_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitSelect
              name="option_days_type_c"
              label={fieldLabel.optionDaysType}
              records={getObjectEntriesAsArray(optionDaysType)}
              value={data.option_days_type_c ?? ''}
              onChange={(e: ChangeEvent) => {
                handleChange(e);
                setField('option_days_type_c');
              }}
              error={validation['option_days_type_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitHeading title="Earnest Amount" />

            <UnitDate
              label={fieldLabel.earnestMoneyDueDate}
              name="earnest_money_due_date_c"
              value={data.earnest_money_due_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'earnest_money_due_date_c', value: e }
                })
              }
              error={validation['earnest_money_due_date_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitSelect
              name="earnest_amount_verified"
              label={fieldLabel.earnestAmountVerified}
              records={getObjectEntriesAsArray(optionFeeVerified)}
              value={data.earnest_amount_verified ?? ''}
              onChange={handleChange}
              required
              error={validation['earnest_amount_verified'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="option_fee_verified"
              label={fieldLabel.optionFeeVerified}
              records={getObjectEntriesAsArray(optionFeeVerified)}
              value={data.option_fee_verified ?? ''}
              onChange={handleChange}
              required
              error={validation['option_fee_verified'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={fieldLabel.earnestAmount}
              name="earnest_amount_c"
              value={data.earnest_amount_c ?? ''}
              onChange={handleChange}
              required
              error={validation['earnest_amount_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="hoa_addendum_received"
              label={fieldLabel.hoaAddendumReceived}
              records={getObjectEntriesAsArray(hoaAddendumReceived)}
              value={data.hoa_addendum_received ?? ''}
              onChange={handleChange}
              error={validation['hoa_addendum_received'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitHeading title="Listing Agent Details" />

            <UnitText
              label={fieldLabel.listingAgentCompany}
              name="list_agent_broker_name_c"
              value={data.list_agent.company ?? ''}
              onChange={handleChange}
              error={validation['list_agent_broker_name_c'] ?? ''}
              readOnly={true}
              disabled={true}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.listingAgentName}
              name="list_agent_name_c"
              value={data.list_agent.name ?? ''}
              onChange={handleChange}
              error={validation['list_agent_name_c'] ?? ''}
              readOnly={true}
              disabled={true}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.listingAgentEmail}
              name="list_agent_email_c"
              value={data.list_agent.email ?? ''}
              onChange={handleChange}
              error={validation['list_agent_email_c'] ?? ''}
              readOnly={true}
              disabled={true}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.listingAgentPhone}
              name="list_agent_phone_c"
              value={data.list_agent.mobile ?? ''}
              onChange={handleChange}
              error={validation['list_agent_phone_c'] ?? ''}
              readOnly={true}
              disabled={true}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.listingAgentAddress}
              name="list_broker_address_c"
              value={data.list_agent.address ?? ''}
              multiline
              rows={2}
              onChange={handleChange}
              error={validation['list_broker_address_c'] ?? ''}
              readOnly={true}
              disabled={true}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitItem>
              <FormContainer>
                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <UnitHeading title="Escrow Details" />
                </UnitItem>
                <UnitItem grid={{ xs: 12, sm: 2 }}>
                  {!isEmpty(data?.parties_escrow_company_id) ? (
                    <RouteEditIconLink
                      url={`/opportunities/${opportunity.id}/parties/${data.parties_escrow_company_id}/edit`}
                      target={true}
                    />
                  ) : (
                    <RouteCreateIconLink
                      url={`/opportunities/${opportunity.id}/parties/create`}
                      target={true}
                    />
                  )}
                </UnitItem>
              </FormContainer>
            </UnitItem>

            <UnitEmpty />

            <UnitParty<PartyListEntity & OptionType>
              label={fieldLabel.escrowContact}
              value={{
                label: data.escrow_company?.name || '',
                value: data.parties_escrow_company_id || ''
              }}
              multiple={false}
              type="escrow"
              subType=""
              onChange={(party: PartyListEntity & OptionType) => {
                setData((prevData) => ({
                  ...prevData,

                  parties_escrow_company_id: party?.id || '',
                  escrow_company: party
                }));
              }}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.escrowCompany}
              name="escrow_company_name_c"
              value={data.escrow_company?.company ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_name_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              readOnly={true}
            />

            <UnitText
              label={fieldLabel.escrowEmail}
              name="escrow_company_email_c"
              value={data.escrow_company?.email ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_email_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              readOnly={true}
            />

            <UnitText
              label={fieldLabel.escrowPhone}
              name="escrow_company_phone_c"
              value={data.escrow_company?.mobile ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_phone_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              readOnly={true}
            />

            <UnitText
              label={fieldLabel.escrowAddress}
              name="escrow_company_address_c"
              value={data.escrow_company?.address ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_address_c'] ?? ''}
              multiline
              rows={2}
              grid={{ xs: 12, sm: 4 }}
              readOnly={true}
            />

            <UnitHeading title="Utility Providers" />

            <UnitText
              label={fieldLabel.electricProvider}
              name="electric_provider"
              value={data.property_utility_provider.electric_provider ?? ''}
              onChange={changeUtilityProvider}
              error={validation['electric_provider'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.waterProvider}
              name="water_well_provider"
              value={data.property_utility_provider.water_well_provider ?? ''}
              onChange={changeUtilityProvider}
              error={validation['water_well_provider'] ?? ''}
              grid={{ xs: 8, sm: 2.5 }}
            />

            <UnitSwitch
              value={data.property_utility_provider.is_well ?? 0}
              onChange={changeUtilityProvider}
              name="is_well"
              label={fieldLabel.isWell}
              grid={{ xs: 4, sm: 1.5 }}
            />

            <UnitText
              label={fieldLabel.sewerProvider}
              name="sewer_provider"
              value={data.property_utility_provider.sewer_provider ?? ''}
              onChange={changeUtilityProvider}
              error={validation['sewer_provider'] ?? ''}
              grid={{ xs: 8, sm: 2.5 }}
            />

            <UnitSwitch
              value={data.property_utility_provider.is_septic ?? 0}
              onChange={changeUtilityProvider}
              name="is_septic"
              label={fieldLabel.isSeptic}
              grid={{ xs: 4, sm: 1.5 }}
            />

            <UnitText
              label={fieldLabel.gasProvider}
              name="gas_provider"
              value={data.property_utility_provider.gas_provider ?? ''}
              onChange={changeUtilityProvider}
              error={validation['gas_provider'] ?? ''}
              grid={{ xs: 8, sm: 2.5 }}
            />

            <UnitSwitch
              value={data.property_utility_provider.is_electric ?? 0}
              onChange={changeUtilityProvider}
              name="is_electric"
              label={fieldLabel.isElectric}
              grid={{ xs: 4, sm: 1.5 }}
            />

            <UnitText
              label={fieldLabel.trashProvider}
              name="trash_provider"
              value={data.property_utility_provider.trash_provider ?? ''}
              onChange={changeUtilityProvider}
              error={validation['trash_provider'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.additionalAccessInformation}
              name="additional_access_information_c"
              value={data.additional_access_information_c ?? ''}
              onChange={handleChange}
              error={validation['additional_access_information_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitHeading title="HOA MANAGEMENT COMPANY" />

            <UnitText
              label={fieldLabel.hoaManagementCompanyName}
              name="name"
              value={data.hoa_management_company.name ?? ''}
              onChange={changeHOA}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.hoaManagementCompanyPhone}
              name="company_phone"
              value={data.hoa_management_company.company_phone ?? ''}
              onChange={changeHOA}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.hoaManagementContactName}
              name="contact_name"
              value={data.hoa_management_company.contact_name ?? ''}
              onChange={changeHOA}
              grid={{ xs: 8, sm: 2.5 }}
            />

            <UnitSwitch
              value={data.hoa_management_company.is_self_managed ?? 0}
              onChange={changeHOA}
              name="is_self_managed"
              label={fieldLabel.isSelfManaged}
              grid={{ xs: 4, sm: 1.5 }}
            />

            <UnitText
              label={fieldLabel.hoaManagementContactEmail}
              name="contact_email"
              value={data.hoa_management_company.contact_email ?? ''}
              onChange={changeHOA}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.hoaManagementContactPhone}
              name="contact_phone"
              value={data.hoa_management_company.contact_phone ?? ''}
              onChange={changeHOA}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.hoaManagementContactAddress}
              name="contact_address"
              value={data.hoa_management_company.contact_address ?? ''}
              onChange={changeHOA}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.hoaUrl}
              name="hoa_url"
              value={data.hoa_url ?? ''}
              onChange={handleChange}
              error={validation['hoa_url'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="has_post_occupancy"
              label={fieldLabel.hasPostOccupancy}
              records={getObjectEntriesAsArray(hasPostOccupancy)}
              value={data.has_post_occupancy ?? ''}
              onChange={handleChange}
              required
              error={validation['has_post_occupancy'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="lease_type"
              label={fieldLabel.leaseType}
              records={getObjectEntriesAsArray(leaseType)}
              value={data.lease_type ?? ''}
              onChange={handleChange}
              required
              error={validation['lease_type'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitDate
              label={fieldLabel.leaseEndDate}
              name="lease_end_date"
              value={data.lease_end_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'lease_end_date', value: e }
                })
              }
              error={validation['lease_end_date'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitPriceFormatter
              label={fieldLabel.depositAmount}
              name="deposit_amount"
              value={data.deposit_amount ?? ''}
              onChange={handleChange}
              required
              error={validation['deposit_amount'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={fieldLabel.rentalAmount}
              name="rental_amount"
              value={data.rental_amount ?? ''}
              onChange={handleChange}
              required
              error={validation['rental_amount'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitEmpty grid={{ xs: 12, sm: 4 }} />

            <UnitText
              label={fieldLabel.hvacAge}
              name="hvac_age"
              value={data.hvac_age ?? ''}
              onChange={handleChange}
              error={validation['hvac_age'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.lockboxCode}
              name="lockbox_code"
              value={data.lockbox_code ?? ''}
              onChange={handleChange}
              error={validation['lockbox_code'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="access_type"
              label={fieldLabel.accessType}
              records={getObjectEntriesAsArray(accessType)}
              value={data.access_type ?? ''}
              onChange={handleChange}
              error={validation['access_type'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.sellerName}
              name="seller_name_c"
              value={data.seller_name_c ?? ''}
              onChange={handleChange}
              error={validation['seller_name_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitSwitch
              value={data.mo_photos_required ?? 0}
              onChange={handleChange}
              name="mo_photos_required"
              label={fieldLabel.moPhotosRequired}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSwitch
              value={data.sellers_disclosure_received ?? 0}
              onChange={handleChange}
              name="sellers_disclosure_received"
              label={fieldLabel.sellerDisclosureReceived}
              grid={{ xs: 12, sm: 4 }}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <UnitItem grid={{ xs: 12, sm: 2 }}>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
            </UnitItem>
            <UnitItem grid={{ xs: 12, sm: 10 }}>
              <UnitSwitch
                value={data.agree_switch ?? 0}
                onChange={handleChange}
                name="agree_switch"
                label="By agreeing to proceed you have verified Purchase Amounts, Parties (HOA, Escrow, Agent) and all Commissions information is accurate"
                grid={{ xs: 12, sm: 10 }}
                error={validation['agree_switch'] ?? ''}
              />
            </UnitItem>
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
