import { validation } from 'services/validation-service';
import { ErrorValidation } from 'types/error-types';

export const validateBrokerageAction = <T>(
  data: T,
  fields: string[],
  oldOpportunity?: T
): {
  isValid: boolean;
  errors: ErrorValidation;
} => {
  let errors: ErrorValidation = {};
  for (const field of fields) {
    const error = validation[field].validate(data, 'action', oldOpportunity);
    if (error && error.length > 0) {
      errors = {
        ...errors,
        [field]: error
      };
    }
  }

  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };
};
