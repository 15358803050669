import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import fieldLabel from 'assets/constants/fieldLabel';
import { ObjectType } from 'types';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';

const accountList = {
  label: fieldLabel.list,
  url: () => `/accounts`,
  labelIcon: ListAltIcon,
  model: appModel.account,
  nodeId: uuid()
};

const createAccount = {
  label: fieldLabel.createAccount,
  url: () => `/accounts/create`,
  labelIcon: CreateIcon,
  model: appModel.account,
  nodeId: uuid()
};

const editAccount = {
  label: fieldLabel.edit,
  url: (id: string) => `/accounts/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.account,
  nodeId: uuid()
};

const viewChangeLogAccount = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/accounts/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.account,
  nodeId: uuid()
};

const msaSelect = {
  label: fieldLabel.selectMSA,
  url: (id: string) => `/accounts/${id}/msa/selection-list`,
  labelIcon: AddTaskIcon,
  model: appModel.market,
  nodeId: uuid()
};

const msaList = {
  label: fieldLabel.list,
  url: (id: string) => `/accounts/${id}/msa`,
  labelIcon: ListAltIcon,
  model: appModel.market,
  nodeId: uuid()
};

const msa = {
  label: fieldLabel.msa,
  labelIcon: AddBusinessIcon,
  subMenuItems: [msaSelect, msaList],
  nodeId: uuid()
};

const viewAccount = {
  label: fieldLabel.view,
  url: (id: string) => `/accounts/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.account,
  nodeId: uuid()
};

const accountMenuItems: ObjectType = {
  list: [accountList, createAccount],
  edit: [accountList, viewAccount, msa, viewChangeLogAccount],
  create: [accountList],
  view: [accountList, editAccount, msa, viewChangeLogAccount]
};

export default accountMenuItems;
