import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';

export const due_diligence_days_confirm = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: any = [];

    if (isEmpty(oppurtunity?.due_diligence_days_confirm)) {
      errors.push(missingField('Due Diligence Days Confirmed?'));
    }

    return errors;
  }
};
