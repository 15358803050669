import fieldLabel from 'assets/constants/fieldLabel';
import terminationReason from 'assets/constants/termination-reason';
import terminationSubReason from 'assets/constants/termination-sub-reason';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitEmpty from 'components/form/unit-empty';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import { actionPerform } from 'event/action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';
import emptyFunction from 'helpers/empty-function-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { getInitialTerminationRequest } from 'state/brokerage-actions/termination-request';
import {
  ActionRecordViewPropTypes,
  TerminationRequestEntity,
  TerminationRequestPaylodEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { OptionType } from 'types/option-type';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [terminationSubReasonOptions, setTerminationSubReasonOptions] =
    useState<OptionType[]>([]);

  const [data, setData] = useState<TerminationRequestEntity>(
    getInitialTerminationRequest(opportunity)
  );

  const handleChange = (e: InputChangeEvent): void => {
    if (e.target) {
      setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
    } else {
      setData(Object.assign({}, data, e));
    }
  };

  const validateData = (): boolean => {
    const { isValid, errors } = validateBrokerageAction(data, [
      'contract_termination_reasons',
      'internal_termination_feedback',
      'termination_reason_subcategory'
    ]);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    const reqBody: TerminationRequestPaylodEntity = {
      contract_termination_reasons: data.contract_termination_reasons,
      termination_reason_subcategory: data.termination_reason_subcategory,
      contract_terminated_date: data.contract_terminated_date,
      internal_termination_feedback: data.internal_termination_feedback
    };

    setIsLoading(true);

    const result =
      await brokerageActionService.update<TerminationRequestPaylodEntity>(
        opportunity.id,
        reqBody,
        'termination_request'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        actionPerform.termination_request();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity({});
      }
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      ...getInitialTerminationRequest(opportunity)
    }));
  }, [loadingOpportunity]);

  useEffect(() => {
    setTerminationSubReasonOptions(
      terminationSubReason[data.contract_termination_reasons!]
    );
  }, [data.contract_termination_reasons]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitText
              label={fieldLabel.requestingNameBuyer}
              name={'buyer_contract_name_c'}
              value={data.buyer_contract_name_c ?? ''}
              onChange={handleChange}
              error={validation['buyer_contract_name_c'] ?? ''}
              disabled
            />

            <UnitPriceFormatter
              label={fieldLabel.contractPrice}
              name={'contract_price_c'}
              value={data.contract_price_c ?? ''}
              onChange={handleChange}
              error={validation['contract_price_c'] ?? ''}
              disabled
            />

            <UnitDateTime
              label={fieldLabel.ddEndDate}
              name="due_diligence_end_c"
              value={data.due_diligence_end_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'due_diligence_end_c', value: e }
                })
              }
              error={validation['due_diligence_end_c'] ?? ''}
              required
              disabled
            />

            <UnitDate
              label={fieldLabel.contractTerminatedDate}
              name="contract_terminated_date"
              value={data.contract_terminated_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'contract_terminated_date', value: e }
                })
              }
              error={validation['contract_terminated_date'] ?? ''}
            />

            <UnitSelect
              name="contract_termination_reasons"
              label={fieldLabel.contractTerminatedReasons}
              records={getObjectEntriesAsArray(terminationReason)}
              value={data.contract_termination_reasons ?? ''}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  contract_termination_reasons: e.target.value,
                  termination_reason_subcategory: ''
                }));
              }}
              error={validation['contract_termination_reasons'] ?? ''}
              required
            />

            <UnitPriceFormatter
              label={fieldLabel.earnestAmount}
              name={'earnest_amount_c'}
              value={data.earnest_amount_c ?? ''}
              onChange={handleChange}
              error={validation['earnest_amount_c'] ?? ''}
              disabled
            />

            <UnitSelect
              label={fieldLabel.terminationReasonSubcategory}
              name="termination_reason_subcategory"
              records={terminationSubReasonOptions}
              value={data.termination_reason_subcategory ?? ''}
              onChange={handleChange}
              error={validation['termination_reason_subcategory'] ?? ''}
              required
            />

            <UnitEmpty />

            <UnitText
              label={fieldLabel.internalTerminationFeedback}
              name={'internal_termination_feedback'}
              value={data.internal_termination_feedback ?? ''}
              onChange={handleChange}
              error={validation['internal_termination_feedback'] ?? ''}
              multiline
              rows={3}
              required
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
