import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';

const sideManuHelper = {
  getMenuItems: async (routeName: string) => {
    let modelMenu = menuHelper.getModelMenuItems(appModel.emailTemplate);

    if (routeName === 'email-templates') {
      return modelMenu[appPermission.list];
    }

    if (
      ['email-templates-view', 'email-templates-market-preferences'].includes(
        routeName
      )
    ) {
      return modelMenu[appPermission.view];
    }

    if (routeName === 'email-templates-edit') {
      return modelMenu[appPermission.edit];
    }

    if (
      ['email-templates-create', 'email-templates-duplicate'].includes(
        routeName
      )
    ) {
      return modelMenu[appPermission.create];
    }

    if (routeName === 'email-templates-delete') {
      return modelMenu[appPermission.delete];
    }

    return [];
  }
};

export default sideManuHelper;
