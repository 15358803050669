import ListAltIcon from '@mui/icons-material/ListAlt';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuid } from 'uuid';
import HistoryIcon from '@mui/icons-material/History';
import appModel from 'assets/constants/app-models';
import fieldLabel from 'assets/constants/fieldLabel';

const partyList = {
  label: fieldLabel.list,
  url: () => '/parties',
  labelIcon: ListAltIcon,
  model: appModel.party,
  nodeId: uuid()
};

const createParty = {
  label: fieldLabel.create,
  url: () => '/parties/create',
  labelIcon: CreateIcon,
  model: appModel.party,
  nodeId: uuid()
};

const editParty = {
  label: fieldLabel.edit,
  url: (id: string) => `/parties/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.party,
  nodeId: uuid()
};

const viewParty = {
  label: fieldLabel.view,
  url: (id: string) => `/parties/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.party,
  nodeId: uuid()
};

const viewChangeLogParty = {
  label: fieldLabel.viewChangelogs,
  url: (id: string) => `/parties/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.party,
  nodeId: uuid()
};

const partyMenuItems = {
  list: [partyList, createParty],
  edit: [partyList, viewParty, viewChangeLogParty],
  create: [partyList, createParty],
  view: [partyList, editParty, viewChangeLogParty]
};

export default partyMenuItems;
