import opportunityStatus from 'assets/constants/opportunity-status';
import CircularLoader from 'components/dog-loader/dog-lodar';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import marketPreferenceService from 'services/market-preference-service';
import initialMarketPreference from 'state/market-preference';
import { ObjectType } from 'types';
import { MarketPreferenceEntity } from 'types/market-preference-types';
import RecordView from '../record-view';
import { moduleLabels } from 'assets/list/tracker/constant';
import trackerService from 'services/tracker-service';
import { OptionType } from 'types/option-type';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const MarketPreferenceView = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();

  const [loading, setloading] = useState<boolean>(false);

  const { setRouteName } = useRouteName();

  const [marketPreference, setMarketPreference] =
    useState<MarketPreferenceEntity>(initialMarketPreference);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadMarketPreference = async (
    market_preference_id: string
  ): Promise<void> => {
    setloading(true);
    const response = await marketPreferenceService.get(market_preference_id);
    setloading(false);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const sqs: string | string[] =
      !isEmpty(response.data.sqs_status_trigger) &&
      typeof response.data.sqs_status_trigger === 'string'
        ? response.data.sqs_status_trigger.replace(/\^/g, '').trim()
        : [];

    const sqs_status_trigger = getObjectEntriesAsArray(
      opportunityStatus
    ).filter((x: OptionType) => sqs.includes(x.value));

    const data = {
      ...response.data,
      sqs_status_trigger: sqs_status_trigger
        .map((x: ObjectType) => x.label)
        .join(', '),
      sqs_status_trigger_text_display: sqs_status_trigger,
      msa_name: !isEmpty(response.data.get_msa)
        ? response.data.get_msa.name
        : '',
      account_name: !isEmpty(response.data.get_account)
        ? response.data.get_account.name
        : ''
    };

    setMarketPreference(data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.MarketPreferences.label,
      item_id: market_preference_id,
      item_summary: data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (market_preference_id) loadMarketPreference(market_preference_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  if (loading) return <CircularLoader />;

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView marketPreference={marketPreference} readOnly={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default MarketPreferenceView;
