import { ObjectType } from 'types';

export const financeAuditStatus: ObjectType = {
  audit_required: 'Audit Required',
  audit_in_progress: 'Audit In Progress',
  provisional_fail: 'Provisional Fail',
  pass: 'Pass',
  pass_remediated: 'Pass - Remediated'
};

export const financeAuditStatusTooltip: { [key: string]: string } = {
  audit_required: 'An audit is mandated but has not been initiated yet.',
  audit_in_progress: 'The necessary audit is currently underway.',
  provisional_fail:
    'A discrepancy has been detected, and further action is required by the Agent or Closing Services.',
  pass: 'Audit has been completed successfully, with no issues or corrective actions required.',
  pass_remediated:
    'Audit was successful, but corrective actions were necessary to address identified issues.'
};
