import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import expenseTypes from 'assets/constants/expense-types';
import fieldLabel from 'assets/constants/fieldLabel';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import ModalComponent from 'components/modal';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import serviceProviderService from 'services/service-provider-service';
import AddVendor from './add-vendor';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import { ServiceProviderListEntity } from 'types/service-provider-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { OptionType } from 'types/option-type';
import {
  ActionRecordViewPropTypes,
  DueDiligenceFeeEntity,
  DueDiligenceFeePaylodEntity
} from 'types/brokerage-action-types';
import brokerageActionService from 'services/brokerage-action-service';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { refreshOpportunity } from 'event/opportunity-event';
import emptyFunction from 'helpers/empty-function-helper';
import { getInitialDueDiligenceFees } from 'state/brokerage-actions/due-diligence-fees';
import { toastError } from 'event/toast-event';

const RecordView = ({
  opportunity,
  onClose = emptyFunction,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vendorForm, showVendorForm] = useState<boolean>(false);
  const [vendors, setVendors] = useState<OptionType[]>([]);
  const [data, setData] = useState<DueDiligenceFeeEntity>(
    getInitialDueDiligenceFees(opportunity)
  );

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const loadServiceProvider = async () => {
    const queryString = listQueryString({
      pagination: {
        page: 0,
        pageSize: 500
      },
      sort: prepareSort([{ field: 'name', sort: 'asc' }], {}),
      filter: {
        type: 'expense'
      }
    });

    const result = await serviceProviderService.getList(queryString);

    if (result.isSuccess) {
      const vendorsOptions = result.data.data.map(
        (x: ServiceProviderListEntity) => ({
          value: x.id,
          label: x.name
        })
      );

      setVendors(vendorsOptions);
    }
  };

  const handleSubmit = async () => {
    const reqBody: DueDiligenceFeePaylodEntity = {
      service_provider_id: data.service_provider_id,
      service_type: data.service_type,
      option_amount_c: data.service_amount,
      service_date: data.service_date,
      dd_fee_payment_tracking_number: data.dd_fee_payment_tracking_number
    };

    setIsLoading(true);

    const result =
      await brokerageActionService.update<DueDiligenceFeePaylodEntity>(
        opportunity.id,
        reqBody,
        'due_diligence_fees'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.due_diligence_fees();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity();
      }
    }
  };

  useEffect(() => {
    loadServiceProvider();
  }, []);

  useEffect(() => {
    setData((prevOppurtunity) => ({
      ...prevOppurtunity,
      ...getInitialDueDiligenceFees(opportunity)
    }));
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitSelect
              name="service_provider_id"
              label={fieldLabel.vendor}
              records={vendors}
              value={data.service_provider_id ?? ''}
              onChange={handleChange}
              error={validation['service_provider_id'] ?? ''}
              grid={{ xs: 12, sm: 5 }}
            />

            <UnitItem grid={{ xs: 12, sm: 1 }} p={1}>
              <IconButton size="large" color="info">
                <AddIcon onClick={() => showVendorForm(true)} />
              </IconButton>
            </UnitItem>

            <UnitSelect
              name="service_type"
              label={fieldLabel.type}
              records={getObjectEntriesAsArray(expenseTypes, data.service_type)}
              value={data.service_type ?? ''}
              onChange={handleChange}
              error={validation['service_type'] ?? ''}
            />

            <UnitPriceFormatter
              name="service_amount"
              value={data?.service_amount}
              onChange={handleChange}
              label={fieldLabel.amount}
              error={validation['service_amount'] ?? ''}
            />

            <UnitDate
              label={fieldLabel.date}
              name="service_date"
              value={data.service_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'service_date', value: e }
                })
              }
              readOnly={false}
              error={validation['service_date'] ?? ''}
            />

            <UnitText
              label={fieldLabel.ddFeePaymentTrackingNumber}
              name="dd_fee_payment_tracking_number"
              value={data.dd_fee_payment_tracking_number ?? ''}
              onChange={handleChange}
              error={validation['dd_fee_payment_tracking_number'] ?? ''}
            />
          </FormContainer>
          {vendorForm && (
            <ModalComponent
              title={fieldLabel.createNewServiceProvider}
              Component={AddVendor}
              data={{
                type: 'expense',
                reloadVendor: (vendor_id: string) => {
                  loadServiceProvider();
                  showVendorForm(false);
                  handleChange({
                    target: {
                      name: 'service_provider_id',
                      value: vendor_id
                    }
                  });
                }
              }}
              onClose={() => {
                showVendorForm(false);
              }}
              isServiceCreateBox={true}
            ></ModalComponent>
          )}
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ p: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
