import AddIcon from '@mui/icons-material/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CreateIcon from '@mui/icons-material/Create';
import HistoryIcon from '@mui/icons-material/History';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 as uuid } from 'uuid';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import fieldLabel from 'assets/constants/fieldLabel';

const listMarketPreferences = {
  label: fieldLabel.list,
  url: () => `/market-preferences`,
  labelIcon: ListAltIcon,
  model: 'simba_Property',
  nodeId: uuid()
};

const createMarketPreferences = {
  label: fieldLabel.createMarketPreferences,
  url: () => `/market-preferences/create`,
  labelIcon: CreateIcon,
  model: 'simba_Property',
  nodeId: uuid()
};

const viewMarketPreferences = {
  label: fieldLabel.view,
  url: (id: string) => `/market-preferences/${id}/view`,
  labelIcon: VisibilityIcon,
  model: 'simba_Property',
  nodeId: uuid()
};

const viewChangeLogMarketPreferences = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/market-preferences/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: 'simba_Property',
  nodeId: uuid()
};

const editMarketPreferences = {
  label: fieldLabel.edit,
  url: (id: string) => `/market-preferences/${id}/edit`,
  labelIcon: CreateIcon,
  model: 'simba_Property',
  nodeId: uuid()
};

const brokerageUserCreate = {
  label: fieldLabel.create,
  url: (id: string) =>
    `/market-preferences/${id}/market-preferences-brokerage-users/create`,
  labelIcon: CreateIcon,
  model: 'Market_Preferences_Brokerage_User',
  nodeId: uuid()
};

const brokerageUserList = {
  label: fieldLabel.list,
  url: (id: string) =>
    `/market-preferences/${id}/market-preferences-brokerage-users`,
  labelIcon: ListAltIcon,
  model: 'Market_Preferences_Brokerage_User',
  nodeId: uuid()
};

const brokerageUser = {
  label: fieldLabel.marketPreferencesBrokerageUser,
  labelIcon: PersonIcon,
  nodeId: uuid(),
  subMenuItems: [brokerageUserCreate, brokerageUserList]
};

const contractSelect = {
  label: fieldLabel.selectContracts,
  url: (id: string) => `/market-preferences/${id}/contracts/selection-list`,
  labelIcon: AddTaskIcon,
  model: 'AOS_PDF_Templates',
  nodeId: uuid()
};

const contractList = {
  label: fieldLabel.contracts,
  url: (id: string) => `/market-preferences/${id}/contracts`,
  labelIcon: ListAltIcon,
  model: 'AOS_PDF_Templates',
  nodeId: uuid()
};

const contract = {
  label: fieldLabel.contracts,
  labelIcon: PictureAsPdfIcon,
  subMenuItems: [contractSelect, contractList],
  nodeId: uuid()
};

const emailTemplateSelect = {
  label: fieldLabel.selectEmailTemplates,
  url: (id: string) =>
    `/market-preferences/${id}/email-templates/selection-list`,
  labelIcon: AddTaskIcon,
  model: 'Email_Template',
  nodeId: uuid()
};

const emailTemplateList = {
  label: fieldLabel.emailTemplates,
  url: (id: string) => `/market-preferences/${id}/email-templates`,
  labelIcon: ListAltIcon,
  model: 'Email_Template',
  nodeId: uuid()
};

const emailTemplate = {
  label: fieldLabel.emailTemplate,
  labelIcon: AttachEmailIcon,
  subMenuItems: [emailTemplateSelect, emailTemplateList],
  nodeId: uuid()
};

const documentCreate = {
  label: fieldLabel.createDocument,
  url: (id: string) => `/market-preferences/${id}/documents/create`,
  labelIcon: AddIcon,
  model: 'Documents',
  nodeId: uuid()
};

const documentList = {
  label: fieldLabel.documents,
  url: (id: string) => `/market-preferences/${id}/documents`,
  labelIcon: ListAltIcon,
  model: 'Documents',
  nodeId: uuid()
};

const document = {
  label: fieldLabel.document,
  labelIcon: InsertDriveFileIcon,
  subMenuItems: [documentCreate, documentList],
  nodeId: uuid()
};

const closings = {
  label: fieldLabel.closings,
  url: (id: string) => `/market-preferences/${id}/closings`,
  labelIcon: LightbulbIcon,
  model: 'Opportunities',
  nodeId: uuid()
};

const marketPreferenceMenuItems = {
  list: [listMarketPreferences, createMarketPreferences],
  edit: [
    listMarketPreferences,
    viewMarketPreferences,
    editMarketPreferences,
    brokerageUser,
    contract,
    document,
    emailTemplate,
    closings,
    viewChangeLogMarketPreferences
  ],
  create: [listMarketPreferences, createMarketPreferences],
  view: [
    listMarketPreferences,
    viewMarketPreferences,
    editMarketPreferences,
    brokerageUser,
    contract,
    document,
    emailTemplate,
    closings,
    viewChangeLogMarketPreferences
  ]
};

export default marketPreferenceMenuItems;
