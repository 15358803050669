import {
  KBCategoryEntity,
  KBCategoryPayloadEntity
} from 'types/kb-category-types';
import { ObjectType } from 'types';
import { useParams } from 'react-router-dom';
import { validateKBCategories } from 'helpers/validation/kb-categories-helper';
import CancelButton from 'components/form/button-cancel';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import initialKBCategories from 'state/kb-categories/initial-kb-categories';
import kbCategoryService from 'services/kb-category-service';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import React, { ChangeEvent, useEffect, useState } from 'react';
import RecordView from '../record-view';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import { toastError } from 'event/toast-event';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const KBCategoryCreate = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { kbCategory_id } = useParams<{ kbCategory_id: string }>();
  const [validation, setValidation] = useState<{ [key: string]: string[] }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kbCategory, setKbCategory] =
    useState<KBCategoryEntity>(initialKBCategories);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent | ObjectType): void => {
    setKbCategory(
      Object.assign({}, kbCategory, { [e.target.name]: e.target.value })
    );
  };

  const loadKBCategory = async (kbCategory_id: string) => {
    setIsLoading(true);
    const response = await kbCategoryService.get(kbCategory_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setIsLoading(false);

    setKbCategory(response.data);
  };

  const handleSubmit = async () => {
    const { status, errors } = validateKBCategories(kbCategory);

    setValidation(errors);

    if (!status) return;
    setIsLoading(true);

    const reqBody: KBCategoryPayloadEntity = {
      name: kbCategory.name,
      parent_id: kbCategory.parent_knowledge_base_category?.id,
      description: kbCategory.description
    };

    let response;

    if (kbCategory_id) {
      response = await kbCategoryService.update(kbCategory_id, reqBody);
    } else {
      response = await kbCategoryService.create(reqBody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: false,
        message: 'Saved Successfully'
      });
      window.history.back();
    }
  };

  useEffect(() => {
    setRouteName(routeTag);
  });

  useEffect(() => {
    if (kbCategory_id !== undefined) loadKBCategory(kbCategory_id);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              kbCategories={kbCategory}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default KBCategoryCreate;
