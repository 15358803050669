import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import EVENTS from 'assets/constants/events';
import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import eventBus from 'helpers/event-bus-helper';
import { useState } from 'react';
import { ObjectType } from 'types';
import opportunityBrokerageUsersService from 'services/opportunity-brokerage-user-service';
import {
  OpportunityBrokerageUserListEntity,
  OpportunityBrokerageUserPayloadEntity
} from 'types/opportunity-brokerage-user-types';
import { toastError } from 'event/toast-event';

const StatusAlertBox = ({
  data,
  handleClose
}: {
  data: OpportunityBrokerageUserListEntity;
  handleClose: () => void;
}): JSX.Element => {
  const {
    id,
    status,
    brokerage_user,
    brokerage_transaction_role,
    brokerage_transaction_role_id,
    brokerage_user_id,
    opportunity_id,
    market_preference_id
  } = data;
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const handleUpdate = async (
    id: string,
    reqBody: OpportunityBrokerageUserPayloadEntity
  ) => {
    setConfirmModal(initialConfirmModalState);
    const result = await opportunityBrokerageUsersService.update(id, reqBody);

    eventBus.dispatch(`opportunity_brokerage_user_refresh`, {});
    if (result.isValidationError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: result.validationMessage['brokerage_user_id'][0],
        isError: true
      });
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: 'Opportunity Brokerage User updated.',
        isError: false
      });
    }
  };

  const statusMessage = (statusMsg: string): string => {
    return `Do you want to ${statusMsg} ${brokerage_user.first_name} ${brokerage_user.last_name} as ${brokerage_transaction_role.name}.?`;
  };

  const handleClick = (e: ObjectType) => {
    const statusMsg = status === 'inactive' ? 'activate' : 'inactivate';
    setConfirmModal({
      open: true,
      title: 'ALERT',
      text:
        e.target.id === 'active-btn' && status === 'active'
          ? statusMessage(statusMsg)
          : statusMessage(statusMsg),
      proceed: () => {
        const reqBody: OpportunityBrokerageUserPayloadEntity = {
          brokerage_transaction_role_id: brokerage_transaction_role_id,
          brokerage_user_id: brokerage_user_id,
          market_preference_id: market_preference_id,
          opportunity_id: opportunity_id,
          status: e.target.outerText === 'Active' ? 'active' : 'inactive'
        };
        handleUpdate(id, reqBody);
        handleClose();
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const isStatusActive = (status: string) => {
    let isStatusActive = false;
    if (data.status === status) {
      isStatusActive = true;
      if (
        data.brokerage_transaction_role.name === 'negotiator' &&
        data.primary_user === 1 &&
        data.status !== 'inactive'
      ) {
        isStatusActive = false;
      }
    }
    return isStatusActive;
  };
  return (
    <>
      {isStatusActive('inactive') && (
        <MenuItem>
          <Box
            component={'span'}
            style={{
              textDecoration: 'none',
              color: '#141b2d',
              width: '100%',
              fontWeight: 600
            }}
            id="active-btn"
            onClick={(e: ObjectType) => handleClick(e)}
          >
            {fieldLabel.active}
          </Box>
        </MenuItem>
      )}

      {isStatusActive('active') && (
        <MenuItem>
          <Box
            component={'span'}
            style={{
              textDecoration: 'none',
              color: '#141b2d',
              width: '100%',
              fontWeight: 600
            }}
            id="inactive-btn"
            onClick={(e: ObjectType) => handleClick(e)}
          >
            {fieldLabel.inactive}
          </Box>
        </MenuItem>
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};
export default StatusAlertBox;
