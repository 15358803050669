import { MenuItem } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import serviceType from 'assets/constants/service-type';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import DropdownMenu from 'components/dropdown-menu';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import { toastError, toastSuccess } from 'event/toast-event';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { deleteService } from 'helpers/service/service-action-helper';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectType } from 'types';
import { VendorServiceListEntity } from 'types/vendor-service-types';
import { v4 as uuid } from 'uuid';

const Action: ({
  service
}: {
  service: VendorServiceListEntity;
}) => JSX.Element = ({ service }: { service: VendorServiceListEntity }) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const ddMenuItems: JSX.Element[] = [];
  if (opportunity_id) {
    ddMenuItems.push(
      <>
        <MenuItem
          key={uuid()}
          onClick={() => {
            setConfirmModal({
              open: true,
              title: fieldLabel.deleteService,
              text: MessageDeleteRecordConfirmation(
                service.inspection_type,
                'Service',
                true
              ),
              proceed: async () => {
                const result = await deleteService(service.id);

                if (result.isError) {
                  toastError(result.errorMessage.message);
                }

                if (result.isSuccess) {
                  setConfirmModal(initialConfirmModalState);
                  toastSuccess(fieldLabel.serviceDeleteSuccess);
                }
              },
              cancel: () => {
                setConfirmModal(initialConfirmModalState);
              }
            });
          }}
        >
          <RouteLinkBlack name={fieldLabel.deleteService} url={`#`} />
        </MenuItem>
        {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
      </>
    );
  }

  return (
    <>
      {ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}
      <RouteEditIconLink
        url={
          opportunity_id
            ? `/opportunities/${opportunity_id}/services/${service.id}/edit`
            : `/services/${service.id}/edit`
        }
      />
      <RouteOpenNewIconLink
        url={
          opportunity_id
            ? `/opportunities/${opportunity_id}/services/${service.id}/view`
            : `/services/${service.id}/view`
        }
      />
    </>
  );
};

const serviceColumn: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => (
      <Action service={row} />
    ),
    flex: 0.7,
    minWidth: 120
  },
  {
    headerName: fieldLabel.serviceType,
    field: 'service_type',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      const { opportunity_id } = useParams<ObjectType>();

      return (
        <RouteLink
          url={
            opportunity_id
              ? `/opportunities/${opportunity_id}/services/${row.id}/view`
              : `/services/${row.id}/view`
          }
          name={getObjectKeyValue(serviceType, row.inspection_type)}
        />
      );
    }
  },
  {
    headerName: fieldLabel.inspectionDate,
    field: 'inspection_date',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams<VendorServiceListEntity>) => {
      return DateUtility.getDateTimeString(row.inspection_date);
    }
  },
  {
    headerName: fieldLabel.requestInfo,
    field: 'request_info',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.requestedBy,
    field: 'requested_by',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return !isEmpty(row.requested_user_first_name) ||
        !isEmpty(row.requested_user_last_name)
        ? row.requested_user_first_name + ' ' + row.requested_user_last_name
        : ' ';
    }
  },
  {
    headerName: fieldLabel.requestedDate,
    field: 'request_date',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return DateUtility.getDateTimeString(row.requested_at);
    }
  },
  {
    headerName: fieldLabel.inspectionConfirmationBy,
    field: 'inspection_confirmation_by',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return !isEmpty(row.confirmation_user_first_name) ||
        !isEmpty(row.confirmation_user_last_name)
        ? row.confirmation_user_first_name +
            ' ' +
            row.confirmation_user_last_name
        : ' ';
    }
  },
  {
    headerName: fieldLabel.inspectionConfirmationDate,
    field: 'confirmation_at',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return DateUtility.getDateTimeString(row.confirmation_at);
    }
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    flex: 1,
    sortable: true,
    valueGetter: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    flex: 1,
    sortable: true,
    valueGetter: ({ row }: GridRenderCellParams<VendorServiceListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default serviceColumn;
