import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RecordView from '../record-view';
import { ErrorValidation } from 'types/error-types';
import brokerageTransactionRoleService from 'services/brokerage-transaction-role-service';
import {
  BrokerageTransactionRoleEntity,
  BrokerageTransactionRolePayloadEntity
} from 'types/brokerage-transaction-role-types';
import initialBrokerageTransactionRole from 'state/brokerage-transaction-role/initial-brokerage-transaction-role';
import { validateBrokerageTransactionRole } from 'helpers/validation/brokerage-transaction-role-helper';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';
import { toastError } from 'event/toast-event';

const CreateBrokerageTransactionRoles = ({
  routeTag
}: {
  routeTag: string;
}) => {
  const { setRouteName } = useRouteName();
  const { brokerage_transaction_role_id } = useParams<{
    brokerage_transaction_role_id: string;
  }>();
  const navigate = useNavigate();
  const [brokerageTransactionRole, setBrokerageTransactionRole] =
    useState<BrokerageTransactionRoleEntity>(initialBrokerageTransactionRole);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setBrokerageTransactionRole(
      Object.assign({}, brokerageTransactionRole, {
        [e.target.name]: e.target.value
      })
    );
  };

  const loadBrokerageTransactionRole = async (
    brokerage_transaction_role_id: string
  ) => {
    setIsLoading(true);
    const response = await brokerageTransactionRoleService.get(
      brokerage_transaction_role_id
    );

    setIsLoading(false);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setBrokerageTransactionRole(response.data);
  };

  const handleSubmit = async () => {
    const reqBody: BrokerageTransactionRolePayloadEntity = {
      name: brokerageTransactionRole.name,
      description: brokerageTransactionRole.description,
      status: brokerageTransactionRole.status
    };

    const { status, errors } = validateBrokerageTransactionRole(reqBody);

    setValidation(errors);

    if (!status) return;

    setIsLoading(true);

    let response;
    if (!brokerage_transaction_role_id) {
      response = await brokerageTransactionRoleService.create(reqBody);
    } else {
      response = await brokerageTransactionRoleService.update(
        brokerage_transaction_role_id,
        reqBody
      );
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      navigate(`/brokerage-transaction-roles/${response.data.id}/view`);
    }
  };

  useEffect(() => {
    if (brokerage_transaction_role_id !== undefined)
      loadBrokerageTransactionRole(brokerage_transaction_role_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              brokerageTransactionRole={brokerageTransactionRole}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default CreateBrokerageTransactionRoles;
